.uploadedDoc-bottomStrip {
  margin-top: 100px;
}
.paddingAppBar {
  padding: 5px 10px;
}

.uploadedDocOuterCont {
  display: grid;
  grid-template-columns: 5% 90%;
}

.UploadedDoc-navBar {
  height: 700px;
  width: 90%;
}

.marginLeft {
  margin-left: 70%;
  font-size: 16px;
}

.marginLeft:hover {
  text-decoration: underline;
  cursor: pointer;
}

.validatingUserOuterCont {
  margin: 300px 100px;
}
