.resourceSelect .MuiInputLabel-outlined {
  font-size: 12px;
}

.resourceSelect .MuiSelect-selectMenu {
  height: 10px !important;
  min-width: 5vw;
  margin: 0px 1vw;
  font-size: 11px;
}

.setResourceWiseCostLimit-fixModal {
  max-width: 600px !important;
}
