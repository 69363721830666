.B-outerContainer .MuiCardContent-root {
  padding: 0% !important;
}

.PD-OuterContainer {
  width: 99.5%;
  margin: 15px auto;
  display: grid;
  grid-gap: 4%;
  grid-template-columns: 30% 67%;
}
.PD-Title {
  font-size: 20px;
  font-weight: 300;
}

.PD-PlanNameCont {
  background-color: #f6f6f6;
  width: 100%;
  height: 65%;
  margin-top: 3%;
  padding-top: 2%;
}

.PD-OuterContainer > div {
  width: 100%;
  height: 350px;
}

.PD-Flex {
  display: flex;

  /* justify-content: end; */

  /* margin-top: 12%; */
}

.PD-PlanName {
  font-size: 20px;
  font-weight: 700;
}
.PD-PlanPrice {
  font-size: 18px;
  font-weight: 700;
}
.PD-ExpDate {
  font-size: 16px;
  font-weight: 300;
}
.PD-ExpDateText {
  font-size: 16px;
  font-weight: 400;
}

.PD-ActiveServicesOutCont {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  background-color: #e2fff2;
  border: 1px solid #34a853;
  border-radius: 5px;
  gap: 4%;
  padding: 0px 20px 20px 20px;
}

.PD-FeatureBox {
  /* height: 35px; */
  background-color: white;
  border-radius: 5px;
  display: flex;
  padding: 8px 25px 2px 25px;
  font-weight: 400;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}

.PD-InactiveServicesOutCont {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  background-color: #ffeaec;
  border: 1px solid #dc3545;
  border-radius: 5px;
  gap: 4%;
  padding: 0px 20px 20px 20px;
}

.imgBox {
  width: 200px;
  height: 200px;
}

.cardOuterCont {
  display: flex;
  overflow-x: scroll; /* Enable horizontal scrolling */
  overflow-y: hidden; /* Disable vertical scrolling */
  padding-bottom: 5px;
}

/* width */
.cardOuterCont::-webkit-scrollbar {
  /* width: px; */
}

/* Track */
.cardOuterCont::-webkit-scrollbar-track {
  display: none;
}

/* Handle */
.cardOuterCont::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 2.5px;
}

/* Handle on hover */
.cardOuterCont::-webkit-scrollbar-thumb:hover {
  background: #555;
}

div {
  /* border: 1px solid black; */
}
