.loaderContainer{ position: fixed; width: 100%; height: 100%; top:0; left: 0; background: rgba(0,0,0, 0.2); z-index: 99999;}
.lds-ellipsis { display: inline-block; position: absolute; width: 100px; height: 80px; left:0; right: 0; top:0; bottom: 0; margin: auto;}
.lds-ellipsis div { position: absolute; top: 33px; width: 15px; height: 15px; border-radius: 50%; background: #fff; animation-timing-function: cubic-bezier(0, 1, 1, 0);}
.lds-ellipsis div:nth-child(1) { left: 8px; animation: lds-ellipsis1 0.8s infinite;}
.lds-ellipsis div:nth-child(2) { left: 15px; animation: lds-ellipsis2 0.8s infinite;}
.lds-ellipsis div:nth-child(3) { left: 50px; animation: lds-ellipsis2 0.8s infinite; }
.lds-ellipsis div:nth-child(4) { left: 80px; animation: lds-ellipsis3 0.8s infinite;}
  @keyframes lds-ellipsis1 {
    0% {
      transform: scale(0);
    }
    100% {
      transform: scale(1);
    }
  }
  @keyframes lds-ellipsis3 {
    0% {
      transform: scale(1);
    }
    100% {
      transform: scale(0);
    }
  }
  @keyframes lds-ellipsis2 {
    0% {
      transform: translate(0, 0);
    }
    100% {
      transform: translate(30px, 0);
    }
  }
  