/* .sectionContainer{display: flex; justify-content: space-between;} */
.userProfileContainer {
  padding: 0 2rem;
  max-width: 1000px;
  margin: 0 auto 40px;
}
.editProfileSection {
  box-shadow: 0 1px 2px #ccc;
  border-radius: 2px;
  overflow: hidden;
}
.sectionHeader {
  display: flex;
  background: #fbfbfb;
  border-top: 2px solid #1582dc;
  border-bottom: 1px solid #ddd;
}
.sectionHeader span.icon {
  padding: 1rem 1rem 0.8rem;
  color: #444;
  display: inline-block;
  margin-left: 1rem;
}
.sectionHeader span.text {
  padding: 1rem 0;
  color: #444;
  display: inline-block;
  font-size: 1.6rem;
}
.sectionContainer {
  padding: 1rem 0;
}
.sectionContainer .inputArea {
  width: calc(50% - 1rem);
  font-size: 1.3rem;
}
/* .sectionContainer .inputArea *{ font-size: 1.4rem;} */
.sectionContainer .changePassword ul.conditionList {
  list-style: none;
  margin: 1rem 0 0;
  padding: 0;
}
.sectionContainer .changePassword ul.conditionList li {
  list-style: none;
  padding: 0.2rem 0;
  font-weight: 500;
  display: flex;
}
.sectionContainer .changePassword ul.conditionList li .checkIcon {
  position: relative;
  top: 3px;
  font-size: 1.5rem;
  color: #555;
}
.sectionContainer .bEmail {
  margin-bottom: 1rem !important;
}
.sectionFooter {
  display: flex;
  justify-content: flex-end;
  padding: 0.7rem;
  border-bottom: 1px solid #ccc;
}
.sectionFooter .saveBtn {
  padding: 0.8rem 3rem;
  border-radius: 2px;
}
.sectionFooter .resetBtn {
  padding: 0.8rem 3rem;
  border-radius: 2px;
}
#phone-number-helper-text {
  margin-left: 0px;
  margin-right: 0px;
}

.border-left {
  border-left: 1px solid #ccc;
}
.border-top {
  border-top: 1px solid #ccc;
}
.border-none {
  border: none;
}
.profileName {
  text-align: center;
  margin: 10px 0 20px;
}
.profileName span {
  font-size: 30px;
  font-weight: 300;
  text-transform: capitalize;
  margin-left: 10px;
  display: block;
  color: #0058a0;
}
.profileName span.email {
  text-transform: lowercase;
  font-size: 18px;
  color: #222;
}
.companyDetail {
  margin: 10px auto 0;
  max-width: 200px;
  display: none;
}
.companyDetail span.icon {
  width: 50px;
  text-align: right;
  padding-right: 5px;
}
.companyDetail span.cName {
  font-size: 18px;
  margin-left: 10px;
}

.editProfilePage {
  height: calc(100vh - 130px);
}
.pEditArea {
  padding: 0px 10px;
}
.pEditArea h6 {
  font-size: 28px;
  font-weight: 300;
}
.pEditArea .MuiOutlinedInput-root {
  border-radius: 2px;
}
.pEditArea .bi-eye-slash-fill {
  cursor: pointer;
  color: #666;
}
.pEditArea .bi-eye-fill {
  cursor: pointer;
  color: #1583dc;
}

.profileContainer {
  display: flex;
}
.profileLeft {
  max-width: 300px;
  width: 100%;
  background: #f1f5f9;
  border-left: 1px solid #d8e4f0;
  border-right: 1px solid #d8e4f0;
}
.profileIcon {
  font-size: 80px;
  color: #666;
  text-align: center;
}
.profileDetail {
  font-size: 14px;
  font-weight: 400;
  padding: 10px;
}
.profileDetail i {
  color: #999;
}
.profileDetail .pName {
  font-weight: 300;
  font-size: 30px;
  text-transform: capitalize;
  text-align: center;
}
.profileDetail .pEmail {
  font-weight: 300;
  font-size: 18px;
  text-transform: none;
  display: flex;
}
.profileDetail .pEmail i {
  margin-right: 5px;
}
.profileRight {
  width: calc(100% - 300px);
}
