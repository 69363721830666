.trialDemoBtn {
  margin-top: 30px;
  margin-right: 40px;
}
.rightHeader {
  text-align: left;
}
.loginNavItem {
  padding: 15px;
}
.loginHeader {
  padding: 18px;
}
.loginForm {
  margin: 0;
}
.wrap {
  border: 2px solid #66caea;
  padding: 5%;
  width: 25%;
  min-width: 389px;
  margin: auto;
}
.wrap .MuiTextField-root {
  margin: 10px;
}
.submit {
  width: 25%;
  padding: 3%;
  border: 1px solid #45b3e7;
  font-size: 15px;
  background-color: #45b3e7;
  color: #fff;
}
.formField {
  width: 40ch !important;
}
.fieldIcons {
  float: right;
  position: relative;
  bottom: 40px;
  right: 35px;
}
.loginBottom {
  overflow: hidden;
  position: relative;
  width: 100%;
  font-size: 13px;
}
.checkboxf {
  float: left;
}
.checkboxf span {
  padding: 0 !important;
}
.forgotf {
  float: right;
  padding: 0px;
  color: #1583dc;
  cursor: pointer;
}
.btnDiv {
  display: flex;
  justify-content: center;
  align-items: center;
}
.loginBtn {
  background: #1583dc;
  color: #fff;
  margin-top: 20px;
}
.errField {
  color: red;
  font-size: 12px;
  margin-left: 12px;
  float: left;
}
.formLoginBtn.disable {
  opacity: 0.65;
  pointer-events: none;
}

.passwordInput input {
  width: 498px !important;
}
