/* support pages */
.mobileNav {
  display: none;
}
.drawerList {
  width: 250px;
}
.scrollContainer {
  /* height: 100vh; */
  /* overflow: hidden; */
  position: relative;
  width: 100%;
  height: 1024px;
}
.supportHeader {
  /* border-bottom: 1px solid #eee;  */
  margin-top: 33px;
  position: sticky;
  z-index: 99;
}
.supportHeader > div {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.supportHeader .cf-Logo.blue {
  margin: 15px 20px;
  display: flex;
}
.supportHeader .cf-Logo.blue span.cloud,
.supportHeader .cf-Logo.blue span.forest {
  font-weight: 400 !important;
}
.topMenu {
  color: black;
  /* text-transform: uppercase; */
  /* width: calc(100% - 130px); */
  width: 100%;
  text-align: right;
}
.topMenu li {
  list-style: none;
  display: inline-block;
  font-size: 1rem !important;
  font-weight: 600;
  position: relative;
  cursor: pointer;
}
.topMenu li span {
  display: block;
  padding: 27px 20px;
  font-size: 16px;
}
.topMenu li.about span {
  display: block;
  padding: 0;
  font-size: 16px;
}
.topMenu li.about a {
  text-decoration: none;
  font-weight: 600;
  color: black;
  display: block;
  padding: 27px 20px;
}
.topMenu li.about:hover span::before {
  display: none;
}
.topMenu li.sLogin a {
  text-decoration: none;

  font-weight: 600;
  color: black;
  display: block;
}
.topMenu li.sLogin span {
  display: block;
  padding: 0;
}
.topMenu li.sLogin span a {
  display: block;
  width: auto;
  /* padding: 27px 20px; */
}
.topMenu li.sLogin:hover span::before {
  display: none;
}
.topMenu .topMenuDropDown {
  display: none;
  position: absolute;
  min-width: 150px;
  background: #fff;
  border: 1px solid #eee;
  top: calc(100% + 0px);
  right: 0;
  box-shadow: 0px 2px 0px #0065b9 inset;
  padding-top: 3px;
}
.topMenu .topMenuDropDown li {
  padding: 0;
  display: block;
  text-align: left;
  white-space: nowrap;
  text-transform: capitalize;
}
.topMenu .topMenuDropDown li:hover {
  background: #f8f8f8;
}
.topMenu .topMenuDropDown li a {
  text-decoration: none;
  font-weight: 600;
  color: #222;
  padding: 15px;
  display: block;
  font-size: 16px;
}
.topMenu li:hover span::before {
  content: '';
  display: block;
  position: absolute;
  top: calc(100% - 1px);
  left: 45%;
  z-index: 9;
  width: 0;
  height: 0;
  border-right: 5px solid #0065b9;
  border-bottom: 5px solid #0065b9;
  border-left: transparent solid;
  border-top: transparent solid;
  transform: rotate(45deg);
}
.sLogin span::before {
  display: none !important;
}
.topMenu li:hover > .topMenuDropDown {
  display: block;
}
.topMenu li:hover > .topMenuDropDown li {
  display: block;
  box-shadow: 0px 0px 0px #0065b9 inset;
}
.drawerList .topMenu {
  text-align: left;
  width: 100%;
}
.drawerList .topMenu li {
  width: 100%;
}
.drawerList .topMenu li span {
  padding: 20px;
}
.drawerList * {
  box-shadow: none !important;
}
.drawerList h6 {
  color: #0065b9;
  width: 100%;
}
.drawerList .MuiAccordionSummary-content {
  margin: 0 !important;
}
.drawerList .MuiAccordionSummary-content span {
  padding: 12px 0;
  display: block;
}
.drawerList .MuiAccordionSummary-content.Mui-expanded {
  margin: 0 !important;
}
.drawerList .MuiAccordionSummary-root.Mui-expanded {
  min-height: 48px !important;
}
.drawerList .MuiAccordion-root.Mui-expanded:before {
  opacity: 1 !important;
}
.drawerList .MuiAccordion-root.Mui-expanded {
  margin: 0 !important;
}
.drawerList ul {
  color: #0065b9;
  text-transform: uppercase;
  width: 100%;
  text-align: left;
}
.drawerList ul li {
  list-style: none;
  display: block;
  font-size: 1.4rem;
  font-weight: 600;
  position: relative;
  cursor: pointer;
}
.drawerList ul li span {
  display: block;
  padding: 27px 20px;
}
.drawerList .topMenuDropDown {
  display: block;
  min-width: 150px;
  background: #f8f8f8;
  border: 1px solid #eee;
  top: 100%;
  right: 0;
  padding-top: 3px;
}
.drawerList .topMenuDropDown li {
  padding: 0;
  display: block;
  text-align: left;
  text-transform: capitalize;
  border-bottom: 1px solid #ddd;
}
.drawerList .topMenuDropDown li:active {
  background: #fbfbfb;
}
.drawerList .topMenuDropDown li:active a {
  background: #fbfbfb;
  color: #0065b9;
}
.drawerList .topMenuDropDown li a {
  text-decoration: none;
  font-weight: 400;
  color: #333;
  padding: 15px;
  display: block;
}
.drawerList li:active span::before {
  content: '';
  display: block;
  position: absolute;
  top: calc(100% - -1px);
  left: 45%;
  z-index: 9;
  width: 0;
  height: 0;
  border-right: 5px solid #0065b9;
  border-bottom: 5px solid #0065b9;
  border-left: transparent solid;
  border-top: transparent solid;
  transform: rotate(45deg);
}
.sLogin span::before {
  display: none !important;
}
.drawerList ul li:active > .topMenuDropDown {
  display: block;
}
.drawerList ul li:active > .topMenuDropDown li {
  display: block;
  box-shadow: 0px 0px 0px #0065b9 inset;
}

.eula {
  min-height: 150px !important;
}
.supportBanner {
  padding: 50px 10px;
  background: #0065b9;
  color: #fff;
  text-align: center;
  display: flex;
  min-height: 400px;
}
.supportBanner h1 {
  font-weight: 300;
}
.supportContent {
  padding: 0;
  text-align: justify;
}
.supportContent ol {
  padding: 10px 0 10px 16px;
}
.supportContent ::marker {
  font-weight: 600;
  font-size: 1.5rem;
}
.supportContent ul {
  margin-left: 25px;
}
.supportContent ul li {
  margin-left: 10px;
}

.listStyleDash {
  list-style: none;
  margin-left: 10px !important;
}
.listStyleDash li {
  padding: 10px 10px 0px 15px;
  position: relative;
}
.listStyleDash li:before {
  content: '-';
  font-size: 24px;
  position: absolute;
  top: 0;
  left: 0;
}

@media (max-width: 820px) {
  .topMenu li.about a,
  .topMenu li span {
    padding: 27px 10px;
  }
}
@media (max-width: 768px) {
  .outerContainer .aboutBanner .aboutBannerText {
    width: 100%;
    background: rgba(15, 5, 76, 0.5);
  }
  .outerContainer .aboutBanner .aboutBannerText p {
    width: 100% !important;
  }
  .supportHeader .topMenu {
    display: none;
  }
  .mobileNav {
    display: block;
    position: absolute;
    right: 0;
  }
}

@media (max-width: 600px) {
  .aboutBanner .aboutBImage {
    background-size: 100% !important;
  }
  .topMenu .topMenuDropDown {
    background: #ebf6ff;
    position: static;
  }
  .drawerList .topMenu li span {
    position: relative;
  }
  .topMenu .topMenuDropDown li {
    white-space: normal;
    text-align: left;
  }
}

@media (min-width: 600px) and (max-width: 768px) {
  .topMenu li span {
    padding: 27px 12px;
  }
}
