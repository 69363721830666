.healthMonitoringDashboardContainer .Mui-expanded {
  background: #f3f3f3;
  min-height: 20px !important;
}

.healthMonitoringDashboardContainer .Mui-expanded .MuiAccordionSummary-content {
  margin: 20px 0 8px 0 !important;
}

.healthMonitoringDashboardContainer .MuiAccordion-root {
  margin-top: 1.5rem;
}

.sectionCard {
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 16px 16px 5px 16px;
  /* padding: 16px; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

/* Quick CPU/Mem/Disk section */
/* .quickCPUGridContainer {
  display: -webkit-box !important;
} */
.quickCPUGridContainer .MuiGrid-item:nth-child(1) {
  padding: 0 !important;
}

.quickCPUGridContainer .MuiGrid-item {
  padding: 0 0 0 8px !important;
}

.nonGaugeGridContainer {
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* 3 equal columns */
  grid-gap: 8px; /* Space between cards */
  width: 100%; /* Full width */
  min-width: 35rem;
  max-width: 41rem;
  box-sizing: border-box;
  align-items: stretch; /* Ensures items fill the grid cell height */
  height: 180px;
  margin-left: 8px;
}

.nonGaugeGridItem {
  /* border: 1px solid #e0e0e0; */
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 8px;
  box-sizing: border-box;
  /* max-width: 16rem; */
  /* height: 75px; */
}

.uptimeItem {
  grid-column: span 2; /* Spans all three columns */
  /* max-width: 33rem; */
}

.cardHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 8px;
}

.cardBody {
  text-align: center;
  margin-bottom: 9px;
}

.quickGaugeTitle {
  white-space: nowrap;
  width: 77%;
  overflow: hidden;
  text-overflow: ellipsis;
}

.healthMonitoringTitle {
  font-size: 12px;
  white-space: nowrap;
  width: 74%;
  overflow: hidden;
  text-overflow: ellipsis;
}

.value {
  font-weight: bold;
}

.unit {
  font-size: 12px;
  color: #888;
}

.panelCount {
  font-size: 12px;
  color: #666;
  margin-left: 0.5rem;
}

/* .quickCPUSkeletonContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 190px;
  padding: 0 16px 0 0;
  width: 100%;
} */

.quickCPUSkeletonContainer .MuiGrid-item {
  border-radius: 5px;
  min-width: 195px;
  flex: 1 1 0px;
}

.quickCPUSkeletonContainer .MuiGrid-item:nth-child(6) {
  min-width: 42.5rem;
  max-width: 48.5rem;
  border-radius: 8px;
}

.quickCPUGridContainer .MuiSkeleton-rectangular {
  width: fit-content;
}

.noDataCenterBox {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 300px;
  width: 100%;
  text-align: center;
}

/* Modal CSS */
.addVisualizationContainer .MuiFormControl-root {
  margin-top: 1rem;
}
.addVisualizationContainer .MuiFormControl-root:nth-child(1) {
  margin-top: 0;
}

/* Legend table css */

.systemHealthTableData {
  padding: 8px;
  text-align: right;
  border-bottom: 1px solid #eee;
  white-space: nowrap;
}
