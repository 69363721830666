.superAdmin-SystemHealth .subscriptionDD {
  min-width: 380px !important;
}

.superAdmin-SystemHealth .subscriptionDD .subscriptionLabel {
  width: 50% !important;
}

.greenRow {
  background-color: #d4edda !important;
}
.redRow {
  background-color: #f8d7da !important;
}

.incFontSize
  .css-1h51icj-MuiAutocomplete-root
  .MuiOutlinedInput-root
  .MuiAutocomplete-input {
  font-size: 16px !important;
}
