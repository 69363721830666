.customDrawerWrapper {
  position: relative;
}

.customListItem {
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 8px;
  user-select: none;
}

.customListItem.active {
  background-color: #f0f0f0;
}

.customDrawerContent {
  border-top: none;
  max-height: 200px;

  position: absolute;
  left: 65px;
  background: #0078db;
  padding: 8px 15px;
  z-index: 999;
  white-space: nowrap;
  top: 6px;
  font-size: 14px;
  text-transform: uppercase;
  border-radius: 3px;
  color: #fff;
  border-radius: 5px;
}

.customDrawerContent::before {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-left: 8px solid #0078db;
  border-top: 8px solid #0078db;
  position: absolute;
  left: -4px;
  top: 11%;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

.customDrawerContent a,
.customDrawerContent ul {
  text-decoration: none;
  color: #fff !important;
  list-style-type: none;
}
.customDrawerContent li {
  font-size: 14px;
}
.customDrawerContent li.activeTab,
.customDrawerContent li:hover {
  border-radius: 5px;
}
