.securityHeading {
  color: #0058a0;
  font-weight: 300;
  font-size: 2.6rem;
  line-height: 50px;
}

.lastScanContainer {
  display: flex;
  align-items: center;
  margin-left: 65%;
  width: 25%;
}

.lastScanText {
  font-size: 0.9vw;
  font-weight: 600;
  text-align: end;
}

.serviceText {
  font-size: 1.1vw;
  font-weight: 600;
  margin-top: 0.5%;
}

.tableContent td,
.tableContent span {
  font-family: Open Sans;
  /* font-size: 16px; */
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  /* padding: 15px; */
}

.accContainerSS {
  border: 2px solid #ddd;
  /* border-radius: 0.8%; */
  overflow: hidden;

  margin-top: 0.5%;
  margin-bottom: 1%;
}
.accContainerH {
  /* border-radius: 0.8%; */
  overflow: hidden;

  margin-top: 4%;
  margin-bottom: 1%;
}

.noDataText {
  text-align: center;
  color: rgb(124, 124, 124);
  font-size: 1vw;
}

.accordionContainerHeadSS {
  border: none !important;
}

.accordionContainerSS {
  border: none !important;
}
.accordionPracTitle {
  font-size: 1.4rem;
  width: 20%;
  font-weight: 600;
  cursor: auto;
  border-radius: 1%;
}

.accordionTitleSS {
  font-size: 1.4rem;
  font-weight: 600;
  cursor: auto;
  border-radius: 1%;
}

.mle {
  margin-left: 72%;
}

.fatal {
  color: red;
}
.lightfatal {
  color: rgb(248, 138, 104);
}

.warn {
  color: orange;
}

.fine {
  color: green;
}

pre {
  background-color: white;
  overflow: auto;
  margin: 0 0 1em;
  padding: 0.5em 1em;
}

pre code,
pre .line-number {
  font: normal normal 12px/14px 'Courier New', Courier, Monospace;
  color: black;
  display: block;
}

pre .line-number {
  float: left;
  margin: 0 1em 0 -1em;
  border-right: 1px solid;
  text-align: right;
}

pre .line-number span {
  display: block;
  padding: 0 0.5em 0 1em;
}

pre .cl {
  display: block;
  clear: both;
}

.securityScanText {
  color: rgb(152, 247, 152);
  font-weight: 600;
}

.securityScanHeader {
  display: flex;
  justify-content: space-between;
}
