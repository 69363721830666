.mtdSummary {
  border-radius: 3px;
  border: 1px solid #cfe5ff;
  overflow: hidden;
  display: flex;
  align-items: center;
  height: 35px;
}
.monthToDateHeader {
  font-size: 1.4rem;
  padding: 0.8rem 1.2rem;
  background: #ebf4ff;
}
.monthToDateEarnings {
  font-size: 2rem;
  color: #0058a0;
  background-color: #fff;
  padding: 0.5rem 2rem;
  text-align: center;
}
.subscriptionDD {
  min-width: 280px;
  max-width: 280px;
  border-radius: 3px;
  border: 1px solid #cfe5ff;
  overflow: hidden;
  display: flex;
  align-items: center;
  background: #fff;
  text-transform: capitalize;
  max-height: 35px;
}
.subscriptionDD .MuiInputBase-input {
  padding: 15px 18px;
}
.subscriptionDD .subscriptionLabel {
  background: #ebf4ff;
  padding: 8px 15px;
  font-size: 12px;
  text-transform: uppercase;
  color: #333;
  text-align: center;
  font-weight: 500;
}
.subscriptionDD .subscriptionLabel + div {
  width: 100%;
}
.subscriptionDD .MuiFormControl-root label {
  display: none;
}
.subscriptionDD .MuiOutlinedInput-root {
  margin-top: 14px;
  font-size: 13px;
  min-width: 100px;
  width: 100%;
  margin-top: 0;
}
.subscriptionDD .MuiOutlinedInput-root svg {
  top: calc(50% - 8px);
  right: 2px;
}

.subscriptionDD .MuiAutocomplete-endAdornment {
  top: calc(50% - 10px);
}
.subscriptionDD .MuiOutlinedInput-root * {
  border: none;
}
.subscriptionDD .MuiOutlinedInput-root .MuiSelect-outlined.MuiSelect-outlined {
  padding: 8px 15px 8px 10px;
  text-align: center;
  background-color: #fff !important;
  font-weight: 300;
}
.rightContent .chartsContainer {
  margin-top: 3rem;
}
