.snapBack {
  width: 200px;
}
.snapBack label {
  font-size: 1.2rem;
}
.snapBack input {
  font-size: 1.4rem;
}
.cardSummary {
  background: #fafafa;
}
.shortSummary {
  text-align: center;
  box-shadow: 0px 0px 2px #ccc;
  border-radius: 5px;
}
.shortSummary > div {
  height: 130px;
  border-right: 1px solid #eee;
  border-bottom: 1px solid #eee;
}
.shortSummary > div:hover {
  background: #fafafa;
}
.shortSummary > div:nth-child(3n) {
  border-right: 0px;
}
.shortSummary > div:nth-child(4) {
  border-bottom: 0px;
}
.shortSummary > div:nth-child(5) {
  border-bottom: 0px;
}
.shortSummary > div:nth-child(6) {
  border-bottom: 0px;
}

.accCont {
  margin: 0 !important;
}
.accDetail {
  background: #fafafa;
}
.accRow {
  min-height: 40px !important;
}
.accRow > div {
  margin: 0 !important;
}
.shortSummaryDetails {
  padding: 0 15px;
  background: #fbfbfb;
}
.shortSummaryDetails .accordionTitle > * {
  padding: 10px 0;
  font-size: 1.3rem;
  font-weight: 600;
}
.accordionTitle {
  font-size: 1.4 rem;
}
.accordionTitle span.toggleArrow {
  display: inline-block;
  position: relative;
  top: 2px;
  margin-right: 10px;
}
.accordionTitle span.toggleArrow + span {
  display: inline-block;
}
.instanceTable {
  text-align: center;
  width: 100%;
  border-radius: 3px;
  border: 1px solid #ddd;
  overflow: hidden;
}
.instanceTable .itHead {
  font-weight: 600;
  background: #fbfbfb;
}
.instanceTable .itHead > div > * {
  padding: 10px;
}
.instanceTable .itBody .itRow {
  background: #fff;
}
.instanceTable .itBody .itRow:nth-child(even) {
  background: #e6f4ff;
}
.instanceTable .itBody .itRow > * {
  padding: 10px;
}
.cardLink {
  text-decoration: none;
  display: flex;
  color: #333;
  justify-content: center;
  height: 100%;
  align-items: center;
  cursor: pointer;
}

.content {
  flex: 1 0 auto;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  text-align: center;
}
.cover {
  width: 90px;
}

.ulRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  background: #daeeff;
  border-radius: 3px;
  border: 1px solid #d6ecff;
  width: 100%;
  padding: 0;
}
.uAccCont {
  background: #daeeff;
  margin: 0 0 10px 0 !important;
  box-shadow: initial !important;
  border: none;
}
.uAccCont:before {
  background-color: #fff !important;
}
.ulRow:hover {
  background: #c5e4ff;
  border: 1px solid #abd8ff;
}
.uShortDetail {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.ulRow .acID {
  width: 50px;
  padding: 10px;
  font-weight: 600;
}
.ulRow .uName {
  width: 220px;
  padding: 10px;
  text-transform: capitalize;
  align-items: center;
  display: flex;
  word-break: break-word;
}
.ulRow .uName svg {
  min-width: 16px;
  min-height: 16px;
}
.ulRow .uName span {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.ulRow .uEmail {
  width: 300px;
  padding: 10px;
  align-items: center;
  display: flex;
  word-break: break-word;
}
.ulRow .uEmail svg {
  min-width: 16px;
  min-height: 16px;
}
.ulRow .uCompany {
  width: 240px;
  padding: 10px;
  align-items: center;
  display: flex;
}
.ulRow .uCompany svg {
  min-width: 16px;
  min-height: 16px;
}
.ulRow .uCompany span {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.ulRow .uStatus {
  width: 80px;
  text-align: center;
  align-items: center;
  display: flex;
}
.ulRow .uStatus span {
  padding: 0px 8px;
  font-size: 1.2rem;
  width: 65px;
  display: inline-block;
}
.ulRow .uLastModifiedOn {
  width: 150px;
  padding: 10px;
  align-items: center;
  display: flex;
}
.ulRow .uActions {
  width: 70px;
  display: none;
  justify-content: flex-end;
  align-items: center;
}
.ulRow .uActions span {
  display: inline-flex;
  padding: 0 8px;
  cursor: pointer;
}
.UlDetails {
  width: 100%;
  padding: 10px;
  background: #f8fcff;
  border: 1px solid #e8f4ff;
  display: flex;
  justify-content: space-between;
}
.ulDetailBtn {
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  padding: 4px;
}
.UlDetails .ulDetailForm {
  display: flex;
}
.UlDetails .ulDetailForm span {
  position: relative;
  margin: 5px 10px;
}
.UlDetails .ulDetailForm span label {
  position: absolute;
  z-index: 3;
  padding: 1px 3px;
  top: -8px;
  left: 10px;
  background: #fff;
  font-size: 1.1rem;
}
.UlDetails .ulDetailForm input {
  padding: 8px 8px 8px 12px;
  border-radius: 3px;
  border: 1px solid #aaa;
  color: #333;
  display: inline-flex;
  width: 150px;
  font-family: 'Open Sans', sans-serif !important;
}
.UlDetails .ulDetailForm select {
  padding: 8px;
  border-radius: 3px;
  border: 1px solid #aaa;
  color: #333;
  display: inline-flex;
  width: 150px;
  font-family: 'Open Sans', sans-serif !important;
}
/* #picker[pseudo="-webkit-calendar-picker-indicator"]{padding: 10px; cursor: pointer;} */
.UlDetails .ulDetailForm .ulSelect {
  margin: 10px;
  min-width: 180px;
  font-size: 1.6rem;
}
.UlDetails .ulDetailForm .ulSelect .MuiSelect-icon {
  top: auto;
}
.ulDateCont {
  position: relative;
}
.ulDate {
  border: 1px solid #ccc;
  padding: 10px;
  border-radius: 5px;
  background: #f8fcff;
  font-size: 1.1rem;
  width: 180px;
}
.ulDateCont:before {
  content: 'Plan Start Date';
  display: inline-block;
  position: absolute;
  top: -1px;
  left: 18px;
  padding: 1px 3px;
  background: #f8fcff;
  z-index: 1;
  font-size: 1.3rem;
  color: #777;
}
.react-datepicker__month-container {
  font-size: 1.2rem;
}
.react-datepicker-popper {
  width: 200px;
}
.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header {
  padding: 2px 5px;
  font-size: inherit;
}
.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
  margin: 0.2rem !important;
  padding: 0.3rem;
  width: 2.2rem !important;
}
.uACC {
  margin: 0;
  min-height: auto !important;
  padding: 0 !important;
  background: #bbe0ff !important;
}
.uACC > div {
  margin: 0;
}
.uAccCont .MuiAccordionSummary-content.Mui-expanded {
  margin: 0 !important;
}
.uAccCont .MuiAccordionDetails-root {
  padding: 5px;
}
.border-white {
  border-color: #fff;
}
.searchBar {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0px 0 10px 10px;
  margin-left: 50px;
}
.userListCont {
  margin: 0;
  width: 100%;
}

.noRecordsFound {
  font-size: 15px;
  text-align: center;
  width: 100%;
  padding: 10px;
  color: #999;
  background-color: #fff;
}
.usersListSection {
  position: relative;
}
.topSearchRefBar {
  display: flex;
  align-items: flex-start;
  width: 100%;
  z-index: 1;
  background: #fff;
}
.refreshBtn {
  background: #eee;
  border: 1px solid #ccc;
  border-radius: 3px;
  padding: 8px;
  text-align: center;
  cursor: pointer;
}
.refreshBtn:hover {
  background: #1986e0;
  border: 1px solid #1986e0;
}
.refreshBtn:hover svg {
  fill: #fff;
}
.refreshBtn span {
  width: 16px;
  display: block;
  height: 16px;
}
.notAllowedCursor {
  cursor: not-allowed;
}
.text-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
}
/* .uAccCont .MuiCollapse-hidden{ display: none;} */

.userListOuterCont {
  /* display: grid;
  grid-template-columns: 2% 98%; */
  width: 100% !important;
}

.userListItemsDetail {
  margin-right: 0 !important;
}
.userListItemsDetail span:nth-child(2) {
  width: 100%;
}

.outerCont {
  display: flex;
}

.adminTag {
  box-shadow: none !important;
  background-color: #0078db !important;
}
