.searchedTable {
  max-height: 27.3rem;
  overflow-y: scroll;
}
.searchedResourceHeading {
  font-size: 3.5rem;
}
.searchedInventoryDetailCount {
  color: #fff;
  background: #666666;
  padding: 0px 8px;
  border-radius: 3px;
}

.stickyTableHeading {
  background-color: #eaf5ff !important;
}
