.container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 10px;
}

.chartContainer {
  width: 30%;
}

.percentageContainer {
  width: 26rem;
  display: flex;
  flex-direction: column;
  height: 13rem;
  justify-content: space-between;
}

.percentageRow {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.dotGreen {
  color: green;
  margin-right: 10px;
}

.dotOrange {
  color: orange;
  margin-right: 10px;
}

.dotRed {
  color: red;
  margin-right: 10px;
}

.percentageLabel {
  margin-left: auto;
  font-weight: bold;
}

.loadTimeChartContainer {
  width: 67rem;
}

.chart {
  height: 150px;
}

.loadTimeChart {
  height: 200px;
}

/* LoadTimeDistributionStats */

.distributionStatsContainer {
  display: flex;
  justify-content: space-around;
  padding: 10px 0;
  margin-bottom: 20px;
}

.statItem {
  text-align: center;
}

.dot {
  height: 8px;
  width: 8px;
  border-radius: 50%;
  display: inline-block;
  margin-right: 6px;
}

.satisfiedDot {
  background-color: #00b300;
}

.toleratingDot {
  background-color: #ffaa00;
}

.frustratedDot {
  background-color: #ff4d4d;
}

.statLabel {
  font-size: 14px;
  font-weight: bold;
  display: block;
  color: #333;
}

.statValue {
  font-size: 24px;
  font-weight: bold;
  margin-top: 5px;
  color: #333;
}
