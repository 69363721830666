.inventoryHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}
.inventoryHeading {
  color: #0058a0;
  font-weight: 300;
  font-size: 50px;
  line-height: 50px;
}

.MuiAutocomplete-groupLabel {
  font-size: 15px !important;
  font-weight: bolder !important;
}

.MuiAutocomplete-option {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 15px !important;
  /* border: 1px solid red; */
}

.MuiAutocomplete-option span {
  display: block;
  margin-right: 5px;
}
.inventoryFlexContent {
  flex-wrap: nowrap;
  width: calc(100% - 200px);
  display: flex;
  justify-content: flex-end;
}
.inventoryFlexItem {
  min-width: 100px !important;
}
.searchLabel label {
  font-size: 14px;
  /* transform: translate(14px, 12px) scale(1); */
}
.inventoryFlexItem label {
  font-size: 12px;
  transform: translate(14px, 12px) scale(1);
}
.inventoryFlexItem input {
  padding: 11px 14px;
}
.inventoryBox {
  display: flex;
  align-items: center;
  border: 1px solid #ccc;
  height: 60px;
  border-radius: 3px;
  overflow: hidden;
  filter: grayscale(100%);
}
.inventoryText {
  color: #222222;
}
.inventoryCount {
  margin-left: auto;
  order: 2;
  color: #666666;
  height: 100%;
  min-width: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fafafa;
}
.searchBtn {
  background-color: #0078db !important;
}
.inventoryBox:hover {
  /*color: #0058a0;*/
  cursor: pointer;
  filter: grayscale(0%);
}
/* .inventoryBox:hover .inventoryText, .inventoryBox:hover .inventoryCount{ color: #0058a0; } */
/* .inventoryBox:hover .inventoryImage{filter: invert(22%) sepia(99%) saturate(1568%) hue-rotate(189deg) brightness(70%) contrast(101%);} */
.inventoryDetailCount {
  color: #fff;
  background: #666666;
  padding: 0px 10px;
  border-radius: 3px;
}
.inventoryTableCell {
  padding: 8px 16px !important;
  white-space: nowrap;
  position: relative;
}
.inventoryFlexItem > div {
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}
.inventoryTableCellLink {
  color: #0058a0 !important;
  white-space: nowrap;
}
.inventoryTableCellLink:hover {
  cursor: pointer;
}
/* .inventoryIcon a {
  width: 48px;
} */
.inventoryActionIcons {
  cursor: pointer;
  font-size: 1.8rem !important;
}
.decore {
  text-decoration: none;
}

.tagScrollBar {
  height: 5.2rem;
  overflow-y: auto;
  overflow-x: hidden;
}

.tagList {
  display: flex;
  flex-wrap: wrap;
}

.tagInputs {
  border: 1px solid #c7bfbf;
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
}

.addTagInputs {
  padding: 11px 14px;
  font-size: 12px;
  transform: translate(14px, 12px) scale(1);
}

.tagCell {
  width: 40%;
}

.tagKey {
  background: #dbebff;
  /* font-size: 1.2rem; */
  border-radius: 3px 0px 0px 3px;
}
.tagValue {
  background: #ebf4ff;
  /* font-size: 1.2rem; */
  border-radius: 0px 3px 3px 0px;
}
.tagScrollBar::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #f5f5f5;
}

.tagScrollBar::-webkit-scrollbar {
  width: 5px;
  background-color: #f5f5f5;
}

.tagScrollBar::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #555;
}

.bg-gray {
  color: #000;
  font-family: Open Sans;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  background: #ccc;
}

.inventorySearch .MuiInputLabel-outlined {
  transform: translate(14px, 11px) scale(1);
}

.inventorySearch .MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root'] {
  padding: 2.5px 0px;
}

.inventorySearch .MuiAutocomplete-endAdornment {
  top: calc(50% - 11px);
}

/* .inventoriesCardContainer {
  height: 223px;
  overflow: auto;
} */

@media (max-width: 767px) {
  .inventoryFlexContent {
    flex-wrap: wrap;
    width: 100%;
  }
}
