.UptimeMonitoringModal {
  max-width: 1024px !important;
}

.monitoringOuterBox {
  width: 100%;
  display: grid;
  height: 555px;
  gap: 4%;
  grid-template-columns: 46% 50%;
}

.monitoringInterval {
  display: grid;
  grid-gap: 1%;
  grid-template-columns: 28% 40% 30%;
}

.extraFields-UptimeMonitoringModal {
  width: 30px;
  height: 30px;
  border-radius: 2px;
  color: white;
  text-align: center;
  padding-top: 10%;
  font-size: 12px;
  background-color: #1583dc;
}

.monitoringIntSliderError .css-9cct2k {
  color: red !important;
}

.overFlowScroll {
  max-height: 170px;
  overflow: scroll;
}

.overFlowScroll::-webkit-scrollbar {
  width: 0.2rem;
  height: 0.8rem;
}
.overFlowScroll::-webkit-scrollbar-track {
  background: transparent;
}
.overFlowScroll::-webkit-scrollbar-thumb {
  background: #666;
}
.overFlowScroll::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.urlMaintenanceInput .MuiInputLabel-outlined {
  transform: translate(14px, 16px) scale(1);
  font-size: 13px;
}

.editMaintenanceCheckbox .MuiSvgIcon-root {
  font-size: 2rem;
}

.escalationSection .MuiInputBase-input {
  padding: 11.55px 14px;
}

.hostNameInput .MuiFormHelperText-contained {
  color: rgba(0, 0, 0, 0.6) !important;
}

.no-select {
  -webkit-user-select: none; /* Chrome/Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE/Edge */
  user-select: none;
}

.UrlDocUpload {
  padding: 1rem;
  margin: auto;
}

.fileName {
  background: #e5e5e5;
  padding: 3px 9px;
  border-radius: 5px;
  margin-top: 0.5rem;
}

.hoveredDropBox:hover {
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px !important;
  transition: 0.2s ease-in-out;
  transform: scale(1.01);
  border-radius: 5px;
}

.hoveredDropBox:active {
  transform: scale(0.98);
}

.icon-wrapper {
  height: 0;
  margin-top: 2px;
  margin-left: 8px;
}

.notificationLogContainer .MuiInputLabel-outlined {
  font-size: 1.4rem;
  transform: translate(14px, 17px) scale(1);
}

.notificationLogTable {
  height: fit-content;
  padding: 0px 13px;
  margin-bottom: 2rem;
}

.notificationLogTableContainer {
  max-height: 50vh;
  overflow-y: auto;
  overflow-x: hidden !important;
}
