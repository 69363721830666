.soraFont h1,
.soraFont h2,
.soraFont h3,
.soraFont h4,
.soraFont h5,
.soraFont h6,
.soraFont p,
.soraFont span,
.soraFont ul,
.soraFont li {
  font-family: 'Sora', sans-serif !important;
  color: black;
}

.soraFont input,
.soraFont label {
  font-family: 'Sora', sans-serif !important;
  color: #000;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 130%;
  letter-spacing: 1px;
}

.trialText {
  max-width: 525px;
  margin: auto;
}

.trialText > h5 {
  color: #000;
  font-size: 36px;
  /* font-family: Sora; */
  font-style: normal;
  font-weight: 700;
  line-height: 130%;
  letter-spacing: 1px;
  text-transform: capitalize;
  /* border: 1px solid; */
}

.trialText > p {
  color: #000;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 130%;
  letter-spacing: 1px;

  margin: 10px 0px 41px 0px;
}

.registrationImage {
  min-height: 90vh;
}
.homeIcon {
  color: #1583dc;
  font-size: 2.5rem;
  cursor: pointer;
}
.registrationLogin {
  border-radius: 5px;
  width: 525px;
  margin: auto;
}

.registrationLogin .secondaryTagline {
  font-size: 2.5rem;
  margin-top: 30px;
  text-align: center;
  line-height: 3.5rem;
  font-weight: 300;
}
.positionRelative {
  position: relative;
}
.w100 {
  width: 100%;
}
#phone-number-helper-text {
  margin-left: 0px;
  margin-right: 0px;
}

.rightImage {
  min-height: 90vh;
  /* padding: 60px; */
  /* position: relative; */
  margin-left: 75px;
  width: 625px;
  /* border: 1px solid black; */
}
.registrationContainer {
  display: flex;
  width: 1170px;
  height: 734px;
  overflow: hidden;
  position: relative;
  margin: auto;
  justify-content: space-between;
  margin-top: 107.89px;
  border-radius: 20px;
  background: rgba(255, 255, 255, 0.05);
  backdrop-filter: blur(50px);
  border: 1px solid #00d2bf;
}
.registrationBanner {
  overflow: hidden;
  position: relative;
}
.registrationBanner:before {
  content: '';
  /* background: url('../../assets/images/registration-banner.png') no-repeat; */
  background-position: left center;
  background-size: 60%;
  overflow: hidden;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0.6;
}

.fullWidthInput input {
  width: 524px;
}

.fullWidthInput .Mui-focused,
.fullNameSection .Mui-focused {
  color: black !important;
  font-size: 20px;
}

.fullWidthInput span,
.fullNameSection span {
  font-size: 20px;
}
.asterisk {
  color: red !important;
}
.fullWidthInput .css-1480iag-MuiInputBase-root-MuiInput-root:after,
.fullNameSection .css-1480iag-MuiInputBase-root-MuiInput-root:after {
  border-bottom: 1px solid black;
}

/* .fullNameSection .css-1480iag-MuiInputBase-root-MuiInput-root,
.fullNameSection .css-v4u5dn-MuiInputBase-root-MuiInput-root,
.fullWidthInput .css-v4u5dn-MuiInputBase-root-MuiInput-root,
.fullWidthInput .css-1480iag-MuiInputBase-root-MuiInput-root {
  margin-top: 40px;
} */

.fullNameSection .MuiInputBase-root,
.fullWidthInput .MuiInputBase-root {
  margin-top: 40px;
}

/* .textField {
  position: relative;
  padding-bottom: 8px;
} */

/* .textField input {
  position: absolute;
  bottom: 0;
} */
/* .registrationBanner .bgIcon {
  position: absolute;
  top: -10%;
  left: 0;
  background: #fff;
  width: 200%;
  height: 100%;
  width: 200%;
  height: 150%;
  transform: skew(-5deg, -5deg);
  opacity: 0;
} */
.registrationBanner .rightbody {
  position: relative;
  z-index: 5;
  background: #fff;
  min-height: 790px;
}
.registrationBanner .leftTextArea {
  width: 458px;
  color: #fff;
  position: relative;
  z-index: 9;
  display: flex;
  align-items: center;
  border: 1px solid black;
  /* padding: 0 30px; */
}
.registrationBanner .rightImage {
  width: 712px;
  min-width: 460px;
  position: relative;
  z-index: 3;
  /* padding: 0px 70px; */
  display: flex;
  align-items: center;
  border: 1px solid black;
}

.fullNameSection {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 525px;
}

.fullNameSection .MuiFormControl-root {
  width: 252px;
}

/* .loginReg h5 {
  color: #132b8f;
} */
.companyNameField {
  position: relative;
}
.companyNameField .companyNameIcon {
  position: absolute;
  font-size: 1.8rem;
  color: #1583dc;
  margin: auto;
  top: 0;
  bottom: 0;
  height: 25px;
  right: 15px;
}
.companyNameField .outlineInputArea {
  background: transparent;
}
.companyNameField .MuiSelect-iconOutlined {
  right: 2px;
}
.bEmail.companyLegend label {
  top: 0 !important;
}

.leftLoginSection {
  width: 458px;
  height: 702px;
  flex-shrink: 0;
  border-radius: 20px;
  background: #3d008c;
  backdrop-filter: blur(175.5px);
  overflow: hidden;
  margin: 14px 0px 14px 12px;
}

.mainBgEllipse1 {
  width: 435px;
  height: 435px;
  flex-shrink: 0;
  border-radius: 435px;
  background: #00d2bf;
  filter: blur(150px);
  position: absolute;
  z-index: -1;
  left: 1337px;
  bottom: 570px;
  top: 19px;
}

.mainBgEllipse2 {
  width: 560px;
  height: 560px;
  flex-shrink: 0;
  border-radius: 560px;
  background: #3d008c;
  filter: blur(150px);
  position: absolute;
  z-index: -1;
  top: 802px;
  left: 1180px;
}

.mainBgEllipse3 {
  width: 435px;
  height: 435px;
  flex-shrink: 0;
  border-radius: 435px;
  background: #00d2bf;
  filter: blur(150px);
  z-index: -1;
  position: absolute;
  right: 1280px;
  top: 748px;
}

.ellipse {
  width: 165px;
  height: 165px;
  flex-shrink: 0;
  border-radius: 165px;
  background: rgba(144, 0, 255, 0.8);
  position: absolute;
  top: 470px;
  left: 224px;
  z-index: 2;
}

.ellipse2 {
  width: 140px;
  height: 162.715px;
  flex-shrink: 0;
  border-radius: 162.715px;
  border: 1px solid #000;
  background: #49fce3;
  filter: blur(74.5px);
  position: absolute;
  top: 217px;
  right: 366px;
}

.ellipse3 {
  width: 140px;
  height: 162.715px;
  flex-shrink: 0;
  border-radius: 162.715px;
  border: 1px solid #000;
  background: #e41f60;
  filter: blur(74.5px);
  position: absolute;
  right: 352px;
  bottom: 563.59px;
}

.ellipse4 {
  width: 140px;
  height: 162.715px;
  flex-shrink: 0;
  border-radius: 162.715px;
  border: 1px solid #000;
  background: #07a6ff;
  filter: blur(74.5px);
  position: absolute;
  top: 86px;
  bottom: 453.28px;
  left: 389px;
}

.intersect {
  position: absolute;
  top: 501px;
  left: 295px;
}

.termsAndConditionText {
  color: #000;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 100%;
}

.testimonialCard {
  width: 373px;
  height: 249px;
  flex-shrink: 0;
  background: #651a8b;
  position: absolute;
  left: 9.5%;
  top: 63px;
  border-radius: 20px;
  /* background: rgba(255, 255, 255, 0.02); */
  backdrop-filter: blur(92px);
  border: 1px solid #ccc;
  padding: 20px;
}

.testimonial {
  margin-bottom: 20px;
  margin-left: 15px;
}

.testimonial > p {
  display: flex;
  width: 312px;
  flex-direction: column;
  flex-shrink: 0;
  color: #fff !important;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: 1px;
}

.profile {
  display: flex;
  align-items: center;
}

.profile-image {
  width: 40px;
  height: 40px;
  flex-shrink: 0;
  border-radius: 10px;
  /* background: url(<path-to-image>), lightgray 50% / cover no-repeat; */
  margin-left: 15px;
}

.profile-info {
  display: flex;
  flex-direction: column;
  margin-left: 15px;
}

.testimonialName,
.testimonialPost {
  color: #fff !important;

  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 1px;
}

.testimonialName {
  font-size: 16px;
}

.testimonialPost {
  font-size: 12px;
}

.post {
  font-size: 14px;
  color: #888;
}

.selectTag .MuiFormControl-root {
  padding: 0px 0px 25px 0px !important;
}
.selectTag .Mui-focused {
  padding: 0px !important;
}

.selectTag .MuiInputBase-formControl {
  margin-top: 20px !important;
}

.selectTag .Mui-focused {
  margin-top: 15px !important;
}

.selectTag .MuiInputLabel-animated {
  font-size: 15px !important;
}

.selectTag .asterisk {
  font-size: 15px;
}
.selectTag .css-yf8vq0-MuiSelect-nativeInput {
  font-size: 15px !important;
}
.selectTag .MuiInput-input {
  font-size: 16px !important;
  font-weight: 600 !important;
  color: black !important;
  /* border-bottom: 1px solid red !important; */
}
.selectTag .MuiInputBase-formControl {
  border-bottom: 1.5px solid rgb(25, 118, 210) !important;
  font-weight: 500 !important;
}

.selectTag .MuiInput-input:focus {
  background-color: transparent !important;
}
