.first {
  margin-bottom: 2rem;
}

.addScript {
  width: 100%;
  border: 1px solid grey;
  border-radius: 2px;
  color: green;
  padding: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

.addScript .MuiSvgIcon-fontSizeMedium {
  margin: 0 5px 0 5px;
}

.addScript > .MuiTypography-h6::-webkit-scrollbar {
  display: none;
}

.rumAdvanceSetting .MuiTypography-body1 {
  font-size: 16px;
}
