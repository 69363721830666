.rsTableHeader .tabHeading{ min-height: auto;}
.tabHeading .MuiTabs-indicator{ display: none;}
.tabHeading button{ padding: 0 15px; min-width: auto; font-weight: 300; min-height: auto; line-height: inherit;}
.tabHeading button.MuiTab-textColorPrimary.Mui-selected{font-weight: 300;}
.tabHeading button:nth-child(1){padding-left: 0;}
.tabHeading button:nth-child(2){ border-left:1px solid #ccc; border-right:1px solid #ccc;}
/* .tabHeading button:nth-child(3){ border-right:1px solid #ccc;} */
.tabHeading button span{ font-size: 24px; text-transform: capitalize;}
.ignoreBtn, .scheduleBtn{cursor: pointer;}

.customVMschedule{ display: block;}
.customVMschedule .cVMlist{ border-radius: 3px; border:1px solid #a2c7e4; min-width: 300px; width: 600px;}
.customVMschedule .cVMlist h6{ font-weight: 400; padding: 8px 10px; background: #f7fbff;}
.customVMschedule .cVMlist ul{ list-style: none; margin: 0; overflow: auto; max-height: 150px;}
.customVMschedule .cVMlist ul li{ list-style: none; padding: 5px 10px;}
.customVMschedule .cVMdateTimePicker{ padding: 0 0 0 10px; width: 100%;}
.customVMschedule .cVMdateTimePicker .cDatePicker{ display: flex; align-items: flex-start; width: 100%;}
.customVMschedule .cVMdateTimePicker .cDate{ display: flex; flex-direction: column; align-items: center; border:1px solid #a2c7e4; border-radius: 3px; overflow: hidden; width: 100%; margin: 0 10px;}
.customVMschedule .cVMdateTimePicker .cDate:last-child{margin-right: 0;}
.customVMschedule .cDateInput > div input{ padding: 14px 10px; font-size: 13px;}
.customVMschedule span.cDateLabel{ width: 100%;}
.customVMschedule span.cDateLabel label{ margin: 0;}
.customVMschedule span.cDateLabel label span{ padding: 0; font-size: 13px; font-weight: 500;}
.customVMschedule .cDateInput{ width: 100%; background: #ebf4ff; border: 1px solid #cfe5ff; padding: 10px; border-radius: 3px;}
.customVMschedule .cDateInput > div{ margin: 0; display: flex; justify-content: space-between; max-width: 400px;}
.customVMschedule .cDateInput label{ margin: 0 10px 0 0;}
.customVMschedule .cDateInput label span{ padding:0px; font-size: 13px;}
.cDateTime{ display: flex; justify-content: space-between; margin-top: 20px;}
.cDateTime > div{ width: 48%;}
.cDateTime > div .MuiInputBase-formControl input{ padding: 16px 14px 14px; font-size: 13px;}
.vmSelect > div .MuiSelect-selectMenu{ padding: 16px 14px 10px;}
.vmSelect > div * {font-size: 13px;}
.vmSelect > div svg{top: 14px;}
.vmSelect label{font-size: 13px;}
.vmSelect .MuiInputLabel-outlined{transform: translate(14px, 15px) scale(1);}
.vmSelect .MuiInputLabel-outlined.MuiInputLabel-shrink{transform: translate(14px, -6px) scale(0.8)}

.customVMschedule .cButtons{ padding: 0 0 0 10px; margin-top: -35px; display: flex; flex-direction: row-reverse;}
.inputTime input{font-size: 13px; padding: 14px;}
.disabled{cursor: default;}
.disabled svg{fill:#cccccc;}
.disabled {color:#cccccc;}
.vmRunning{ color: #006954; background: #f0fffb; padding: 2px 5px; border-radius: 3px; font-size: 12px; font-weight: 600;}
.vmStopped{ color: #b87100; background: #fffcf6; padding: 2px 5px; border-radius: 3px; font-size: 12px; font-weight: 600;}
.cancelScheduleBtn{ font-size: 20px; line-height: 20px;}
.cIcon{ position: absolute; top:0.5px; left:6px; font-size: 10px; font-weight: 700;}
