.RUMCustomTooltip {
  background: white;
  border: 1px solid #ccc;
  padding: 10px;
  border-radius: 5px;
  text-align: left;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}
.RUMCustomTooltip > div {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
  justify-content: space-between;
}

.RUMCustomTooltip > div p {
  font-size: 1.3rem !important;
  line-height: 2.3rem;
  font-weight: 500;
}
/* .RUMCustomTooltip .tooltip-flag {
  width: 20px;
  height: 15px;
  margin-right: 5px;
} */

.RUMDetail-container {
  /* display: flex;
  justify-content: space-between; */
  padding: 16px;
  /* height: 100%; */
  /* border: 1px solid; */
  border-radius: 10px;
  /* box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px; */
  border: 1px solid #e0e0e0;
  max-width: 1200px;
  margin: 2rem auto;
  background: #ffff;
  /* margin-botto; */
}

/* .RUMDetailContainer > div:nth-child(1) {
  margin-top: 8rem;
} */

.RUMContainer {
  width: 100%;
}

.RUMDetailContainer {
  width: 100%;
  background: rgb(249, 249, 249);
  padding: 2rem;
  margin-top: -2rem;
}
.map-parent {
  height: 490px;
}
.map-div {
  height: 480px;
  width: 83%;
  /* display: flex;
  justify-content: center; */
}

.map-container {
  width: 80%;
  /* border: 1px solid red; */
  margin: auto;
  height: 90%;
  /* margin-right: 5rem; */
  margin-right: 16px;
}

.session-container {
  width: 30%;
  padding: 20px;
  background-color: #f5f5f5;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  text-align: center;
  /* height: 507px; */
}

.sessions-total {
  font-size: 24px;
  font-weight: bold;
  margin-top: 20px;
}

.sessions-median-pageviews,
.sessions-duration {
  margin-top: 10px;
  font-size: 14px;
}

.echarts-tooltip {
  background-color: #fff;
  /* border: 1px solid #ccc; */
  padding: 5px;
  border-radius: 5px;
  /* box-shadow: 0 0 5px rgba(0, 0, 0, 0.2); */
}

.flag {
  width: 24px;
  height: 24px;
  margin-right: 5px;
}

.descriptionCardTitle {
  text-align: left;
  margin-bottom: 10px;
  padding-bottom: 0px;
  width: 22.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.topCards {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 507px;
  /* border: 1px solid black; */
}

.map-session-divider {
  display: none;
  color: 1px solid grey;
  height: 300px;
}
@media (max-width: 768px) {
  .parent-div p {
    font-size: 12px; /* Smaller devices */
  }
  .map-parent {
    flex-direction: column;
    align-items: center;
    height: 800px;
  }
  .map-div {
    height: 50%;
  }
  .map-session-card {
    width: 83%;
    display: flex;
    justify-content: space-between;
    height: 50%;
    /* justify-content: space-evenly; */
  }
  .map-session-pie {
    width: 45%;
    padding-right: 70px;
  }
  .map-session-data {
    width: 45%;
  }
  .map-session-divider {
    display: inline;
  }
}

@media (min-width: 769px) and (max-width: 1024px) {
  .parent-div p {
    font-size: 14px; /* Tablets */
  }
  .map-parent {
    flex-direction: column;
    align-items: center;
    height: 800px;
  }
  .map-div {
    height: 50%;
  }
  .map-session-card {
    width: 83%;
    display: flex;
    justify-content: space-between;
    height: 50%;
    /* justify-content: space-evenly; */
  }
  .map-session-pie {
    width: 45%;
    padding-right: 70px;
  }
  .map-session-data {
    width: 45%;
  }
  .map-session-divider {
    display: inline;
  }
}

@media (min-width: 1025px) {
  .parent-div p {
    font-size: 16px; /* Desktops */
  }
}

.tableExpandSelect {
  width: 80px;
  margin-top: 1rem !important;
}
.tableExpandSelect .MuiSelect-outlined {
  padding: 12px;
  font-weight: 500;
}
