.error.container {
  min-height: 100vh;
}
.container-404 {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  flex-wrap: wrap;
}
.item-404 {
  color: #0065b9;
  font-size: 160px;
  text-align: center;
  line-height: 1;
  font-weight: 600;
  text-shadow: 10px 6px 8px rgb(117 117 117 / 30%);
}

.freeTrialPlan {
  color: #0065b9;
  font-size: 100px;
  text-align: center;
  line-height: 1;
  font-weight: 600;
  text-shadow: 10px 6px 8px rgb(117 117 117 / 30%);
}

.freeTrialPlanWelcome {
  color: #0065b9;
  font-size: 64px;
  text-align: center;
  line-height: 1;
  font-weight: 700;
  text-shadow: 10px 6px 8px rgb(117 117 117 / 30%);
  margin-top: -40px;
}

.error-text {
  font-size: 24px;
  text-align: center;
  color: #0065b9;
  font-weight: 600;
  color: #000000;
}
.home-link {
  font-size: 24px;
  color: #0065b9;
  padding: 5px 20px;
  text-decoration: none;
  font-weight: 300;
  border-radius: 3px;
}
