.soraFont h1,
.soraFont h2,
.soraFont h3,
.soraFont h4,
.soraFont h5,
.soraFont h6,
.soraFont p,
.soraFont span,
.soraFont ul,
.soraFont li,
.soraFont div,
.soraFont input,
.soraFont label {
  font-family: 'Sora', sans-serif !important;
  color: black;
}

.FP-Modal {
  max-width: 770px !important;
}

.borderRadius {
  border-radius: 20px;
}

.FP-OuterCont {
  height: 573px;
}

.FP-CrossOuterCont {
  margin-left: 93%;
  cursor: pointer;
}

.FP-BoxBody {
  /* border: 1px solid black; */
  width: 65%;
  margin: 20px 18%;
}

.FP-Title {
  /* font-family: Sora !important; */
  font-size: 36px;
  font-weight: 600;
  line-height: 47px;
  letter-spacing: 1px;
  text-align: left;
  text-align: center;
}

.FP-InfoText {
  margin-top: 30px;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 1px;
  text-align: center;
}

.FP-EmailBox {
  /* border: 1px solid black; */
  margin-top: 100px;
  height: 50px;
}

.FP-Button {
  cursor: pointer;
  justify-content: center;
  margin-top: 100px;
  width: 100%;
  height: 46px;
  border-radius: 10px;
  padding-top: 2.5%;
  padding-left: 35%;
  background-image: linear-gradient(to right, #3d008c, #00d2bf);
}

.FP-Button:hover {
  background-image: linear-gradient(90deg, #23014e 0%, #0b9488 100%);
  transition: background-image 3s ease-in !important;
}

.FP-ResetPasswordText {
  color: white !important;
  font-weight: 700;
  font-size: 16px !important;
}

.EmailText {
  color: #000;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 130%;
  letter-spacing: 1px;
}
