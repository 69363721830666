.createInvoice {
  display: block;
}
.createInvoice .list {
  border-radius: 3px;
  border: 1px solid #a2c7e4;
  width: 558px;
}
.createInvoice .Select {
  border-radius: 3px;
  border: 1px solid #a2c7e4;
  min-width: 300px;
  width: 640px;
}
.createInvoice .list h6 {
  font-weight: 400;
  padding: 8px 10px;
  background: #f7fbff;
}
.createInvoice .list ul {
  list-style: none;
  margin: 0;
  overflow: auto;
  max-height: 150px;
}
.createInvoice .list ul li {
  list-style: none;
  padding: 5px 10px;
}
.createInvoice .cVMdateTimePicker {
  padding: 0 0 0 10px;
  width: 100%;
}
.createInvoice .cVMdateTimePicker .cDatePicker {
  display: flex;
  align-items: flex-start;
  width: 100%;
}
.createInvoice .cVMdateTimePicker .cDatePicker .cDate {
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid #a2c7e4;
  border-radius: 3px;
  overflow: hidden;
  width: 100%;
}

.createInvoice .cVMdateTimePicker .cDatePicker .cDate:last-child {
  margin-right: 0;
}
.createInvoice .cVMdateTimePicker .cDatePicker .cDate .cDateInput > div input {
  padding: 14px 10px;
  font-size: 13px;
}
.createInvoice .cVMdateTimePicker .cDatePicker .cDate span.cDateLabel {
  padding: 10px;
  background: #f7fbff;
  width: 100%;
}
.createInvoice .cVMdateTimePicker .cDatePicker .cDate span.cDateLabel label {
  margin: 0;
}
.createInvoice
  .cVMdateTimePicker
  .cDatePicker
  .cDate
  span.cDateLabel
  label
  span {
  padding: 0 5px 0 0;
  font-size: 13px;
  font-weight: 500;
}
.createInvoice .cVMdateTimePicker .cDatePicker .cDate .cDateInput {
  width: 100%;
}
.createInvoice .cVMdateTimePicker .cDatePicker .cDate .cDateInput > div {
  margin: 0;
  display: flex;
  justify-content: space-between;
  max-width: 400px;
}
.createInvoice .cVMdateTimePicker .cDatePicker .cDate .cDateInput label {
  margin: 0 10px 0 0;
}
.createInvoice .cVMdateTimePicker .cDatePicker .cDate .cDateInput label span {
  padding: 0px;
  font-size: 13px;
}

.createInvoice .checkBox {
  padding: 0 0 0 10px;
  margin-top: 50px;
  display: flex;
  flex-direction: row;
  margin-left: 450px;
}

.createInvoice .checkBox1 {
  padding: 0 0 0 10px;
  margin-top: -52px;
  display: flex;
  flex-direction: row;
  margin-left: 950px;
}
.checkbox2 {
  margin-top: -118px !important;
}
.DateInput {
  padding: 14px 10px;
  font-size: 23px !important;
}

.createInvoice .cButtons {
  padding: 0 0 0 10px;
  margin-top: 10px;
  display: flex;
  flex-direction: row-reverse;
}
.tabInvoiceHeading .MuiTabs-indicator {
  display: none;
}
.tabInvoiceHeading button {
  padding: 0 15px;
  min-width: auto;
  font-weight: 300;
  min-height: auto;
  line-height: inherit;
  max-width: initial;
}
.tabInvoiceHeading button.MuiTab-textColorPrimary.Mui-selected {
  font-weight: 300;
}
.tabInvoiceHeading button:nth-child(1) {
  padding-left: 0;
}
.tabInvoiceHeading button:nth-child(2) {
  border-left: 1px solid #ccc;
  /* border-right: 1px solid #ccc; */
}
.rsTableHeader .tabInvoiceHeading {
  min-height: auto;
}
.tabInvoiceHeading button span {
  font-size: 24px;
  text-transform: capitalize;
}

.tabCont1 {
  display: inline-flex !important;
  background: #ebf4ff;
  border-radius: 3px;
  border-bottom: 1px solid #a2c7e4;
  width: 100%;
}
.tabCont1 button {
  font-weight: 600;
  text-transform: capitalize;
  font-size: 13px;
}
.ddwidth {
  width: 400px !important;
}

.invoiceDD {
  min-width: 100%;
  border-radius: 5px;
  overflow: hidden;
  display: flex;
  align-items: center;
}
.invoiceDD .MuiInputBase-input {
  padding: 15px 18px;
}
.invoiceDD .invoiceLabel {
  width: 200px;
  background: #ebf4ff;
  padding: 12px 15px;
  font-size: 12px;
  text-transform: capitalize;
  color: #000;
  text-align: center;
}
.invoiceDD .invoiceLabel + div {
  width: 100%;
}
.invoiceDD .MuiFormControl-root label {
  display: none;
}
.invoiceDD .MuiOutlinedInput-root {
  margin-top: 14px;
  font-size: 13px;
  min-width: 100px;
  width: 100%;
  margin-top: 0;
}
.invoiceDD .MuiOutlinedInput-root svg {
  top: calc(50% - 8px);
  right: 2px;
}
.invoiceDD .MuiOutlinedInput-root * {
  border: none;
}

.invoiceDD .MuiOutlinedInput-root .MuiSelect-outlined.MuiSelect-outlined {
  padding: 10px 15px 10px 10px;
  text-align: center;
  background-color: #fff !important;
  font-weight: 300;
}

.cardBox {
  height: 10rem !important;
}

.invoiceMonthSelect {
  min-width: 100px;
  /* max-width: 280px; */
  border-radius: 3px;
  /* border: 1px solid #cfe5ff; */
  overflow: hidden;
  display: flex;
  align-items: center;
  /* background: #fff; */
  text-transform: capitalize;
}
.invoiceMonthSelect .MuiInputBase-input {
  padding: 15px 18px;
  color: white;
}
.invoiceMonthSelect .subscriptionLabel {
  /* background: #ebf4ff; */
  padding: 8px 15px;
  font-size: 12px;
  text-transform: uppercase;
  color: #333;
  text-align: center;
  font-weight: 500;
}
.invoiceMonthSelect .subscriptionLabel + div {
  width: 100%;
}
.invoiceMonthSelect .MuiFormControl-root label {
  display: none;
}
.invoiceMonthSelect .MuiOutlinedInput-root {
  margin-top: 14px;
  font-size: 13px;
  min-width: 100px;
  width: 100%;
  margin-top: 0;
}
.invoiceMonthSelect .MuiOutlinedInput-root svg {
  top: calc(50% - 8px);
  right: 2px;
}
.invoiceMonthSelect .MuiOutlinedInput-root * {
  border: none;
}
.invoiceMonthSelect
  .MuiOutlinedInput-root
  .MuiSelect-outlined.MuiSelect-outlined {
  padding: 8px 15px 8px 10px;
  text-align: center;
  /* background-color: #fff !important; */
  background: #2a8cdd !important;
  font-weight: 300;
}
.invoiceName {
  white-space: nowrap;
  width: 100px !important;
  text-overflow: ellipsis;
}
.tableColumnWidth {
  white-space: nowrap !important;
  overflow: hidden;
  max-width: 195px !important;
  /* border: 1px solid; */
  text-overflow: ellipsis !important;
}
.invoiceCard {
  min-width: 30rem;
  margin-right: 20px;
  height: 10rem !important;
  background: #0078db !important;
}
.invoiceTagTableHeader {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0 0 5px 5px;
  text-transform: capitalize;
  margin-bottom: 10px;
}
.invoiceBtn {
  padding: 1rem 10rem;
}

.arrowLogo {
  height: 0.9rem;
  width: 1rem;
  color: #b80909;
}

.highPriorityText {
  color: #b80909;
  font-weight: 600;
  font-size: 11px;
}
.lowPriorityText {
  color: #0b9b0b;
  font-weight: 600;
  font-size: 11px;
}

.widthChange {
  width: 98% !important;
}

.scrollingTable {
  max-height: 400px;
  overflow: scroll;
}

.autoCompleteBox .MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root'] {
  padding: 0;
}

.autoCompleteBox .MuiAutocomplete-endAdornment {
  top: calc(57% - 14px);
}

.autoCompleteInput
  .MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root']
  .MuiAutocomplete-input:first-child {
  padding-left: 60%;
}
