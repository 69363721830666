/* .idleBox {
  height: 112px;
  width: 312px;
  left: 82px;
  top: 128px;
  border-radius: 5px;
  
} */

.idleBox {
  display: flex;
  font-size: 16px;
  line-height: 21.79px;
  justify-content: space-around;
  align-items: center;
  border: 1px solid #ccc;
  height: 112px;
  /* width: 280px; */
  /* width: 22vw; */
  color: #000000;
  border-radius: 5px;
  overflow: hidden;
  /* filter: grayscale(100%); */
  background: #f6f6f6;
  /* left: 82px; */
  filter: grayscale(0%);
}

.idleBox p {
  font-weight: 400 !important;
}

.idleBox:hover {
  cursor: pointer;
  background-color: #00bbdc;
  transition: background-color 0.5s ease;
  color: #ffffff;
}

.idleBox:hover img {
  filter: brightness(0) invert(1);
}

.idleImg {
  height: 66px;
  width: 73px;
  left: 301px;
  top: 151px;
  border-radius: 0px;
}

.accordionTitle {
  padding: 1rem 2rem;
  font-size: 1.4rem;
  cursor: auto;
}
.actionsBtn {
  text-align: center;
  padding: 1rem 2rem;
  color: #333;
  font-size: 1.4rem;
}

/* .accordionContainer:nth-child(even){ background:#e6f4ff;} */

.highRisk {
  background: #fde8e8;
}
.highRisk .accordionTitle span {
  display: inline-block;
  padding: 0.8rem 0.6rem;
  color: #333;
}
.modrateRisk {
  background: #fff2de;
}
.modrateRisk .accordionTitle span {
  display: inline-block;
  padding: 0.8rem 0.6rem;
  background: #ffb135;
  color: #333;
}
.accordionTitle span:first-child {
  display: inline-block;
  padding: 0.8rem 0.5rem 0.8rem 2rem;
  cursor: pointer;
}
.accordionTitle p {
  font-size: 1.5rem;
  padding: 1.2rem 1rem;
  display: inline-block;
  cursor: auto;
  width: calc(100% - 5rem);
}
.accordionContentBox {
  padding: 0 !important;
}
.accordionContentBox .accordionContentDesc {
  background: #f4f4f4;
  padding: 0.5rem;
  font-size: 1.4rem;
  margin: 0.3rem !important;
  box-shadow: 0 0.3rem 0.3rem #ccc;
}
.accordionContentBox .accordionContentDesc span {
  display: inline-block;
  padding: 0;
  color: #0078db;
  background: transparent;
}

.accordionContent {
  display: flex;
}
/*  .accordionContent span{ display: flex; background: #e8e8e8; padding: 0.8rem 0.4rem 0.8rem 0.5rem;} */
.accordionContent span [data-prefix='fas'] {
  font-size: 1.8rem;
  align-self: center;
  transform: rotate(90deg);
}

.actionsBtn {
  display: flex;
  justify-content: flex-end;
  color: #333;
  padding-right: 2rem;
}
.actionsBtn .disableIcon {
  opacity: 0.4;
  cursor: default;
}
.actionsBtn [data-prefix='fas'] {
  margin: 0 1.2rem;
  font-size: 1.5rem;
  align-self: center;
}
.actionsBtn [data-prefix='fas'].fa-toggle-on {
  font-size: 1.8rem;
}
.actionsBtn span {
  align-self: center;
  width: auto;
  height: auto;
  font-size: initial;
  background: transparent;
}

.accDetails {
  width: 100%;
  padding: 0px;
  border: 1px solid #ddd;
  margin: 0 0.5rem 0.5rem !important;
  border-radius: 5px;
  overflow: hidden;
}
.accDetails .accDetailHead {
  background: #fafafa;
  padding-right: 10px;
  border-bottom: 1px solid #eee;
}
.accDetails .accDetailCont {
  max-height: auto;
  overflow-y: scroll;
  overflow-x: auto;
  background: #fff;
}
.accDetails .accDetailRow {
  background: #fff;
  border-bottom: 1px solid #eee;
}
.accDetails .accDetailRow .actionsBtn {
  justify-content: flex-end;
  padding-right: 0.5rem;
}

.accDetails .accDetailArea {
  display: flex;
  flex-wrap: nowrap;
  font-size: 1.3rem;
  align-items: center;
}
.accDetails .accDetailArea > div {
  padding: 0.5rem;
  width: 20%;
  word-break: break-word;
}
.accDetails .accDetailArea b {
  font-weight: 600;
}
.accDetails .accDetailArea span {
  display: inline-block;
  padding: 0;
  background: transparent;
}
.accDetails .accDetailArea .tableData {
  margin: 0.5rem 0;
  padding: 0;
  display: flex;
  width: 100%;
}
.accDetails .accDetailArea .tableData li {
  list-style: none;
  display: inline-block;
}
.accDetails .accDetailArea .tableData li span {
  color: inherit;
}
.accDetails .accDetailArea > div:nth-child(2) {
  width: 40%;
}
.actionIcons {
  cursor: pointer;
  font-size: 1.8rem !important;
}
.disabledRow {
  color: #721c24 !important;
  /* background-color: #f8d7da !important;
  border-color: #f5c6cb !important; */
  background-color: #eeeeee !important;
  border-color: #999 !important;
}
.configIcon {
  background: url('../../assets/images/icons.png') !important;
  background-position: 0 0 !important;
  background-size: 250px !important;
  padding: 0 !important;
  align-self: center;
  margin: 0 1rem;
  min-width: 1.7rem !important;
  min-height: 1.8rem !important;
  opacity: 0.9;
}
.shieldToggle {
  background: url('../../assets/images/icons.png') !important;
  background-position: -39px 0px !important;
  background-size: 300px !important;
  padding: 0 !important;
  align-self: center;
  margin: 0 1rem;
  min-width: 1.8rem !important;
  min-height: 1.8rem !important;
  opacity: 0.9;
}
.shieldToggle.inactive {
  background-position: -56px 0px !important;
}
.Mui-expanded .accordionTitle span .fa-caret-right {
  transform: rotate(90deg);
}

.paginationStack {
  position: relative;
  bottom: 0;
  /* left: 50%; */
  width: 100%;
  /* transform: translateY(-50%); */
}

.paginationStack > div {
  width: fit-content;
  /* border: 1px solid; */
  margin: auto;
  margin-top: 2vh;
}

.instanceInfo {
  font-size: 1rem;
  cursor: pointer;
  margin-left: 1rem;
  display: inline-block;
}
.Id,
.Unit,
.Currency_Code {
  display: none;
}
.Predicted_Monthly_Cost .fas {
  font-size: 1.4rem;
}

.accContainer {
  border: 1px solid #ddd;
  border-radius: 3px;
  overflow: hidden;
}
/* .accContainer th {
  border: none !important;
} */
.accContainer tbody tr {
  border-top: 1px solid #eee !important;
}
.accContainer tbody tr:first-child {
  border-top: none !important;
}
.tabResourceHeading {
  /* min-height: auto !important; */
  /* padding: 0 15px; */
  /* min-width: auto; */
  font-weight: 300;
  min-height: auto;
  /* line-height: inherit; */
  max-width: none;
  font-size: 20px;
  text-transform: capitalize;
  /* display: none; */
}

.tabResourceHeading button {
  padding: 0 15px;
  min-width: auto;
  font-weight: 300;
  min-height: auto;
  line-height: inherit;
  max-width: none;
}
/* .tabResourceHeading button.MuiTab-textColorPrimary.Mui-selected {
  font-weight: 300;
}
.tabResourceHeading button:nth-child(1) {
  padding-left: 0;
}
.tabResourceHeading button:nth-child(2) {
  border-left: 2px solid #ccc;
}
.tabResourceHeading button span {
  font-size: 24px;
  text-transform: capitalize;
} */
/* .UnusedTab {
  border-left: 2px solid #ccc !important;
} */
.tdCell {
  max-width: 1800px;
}
/* .accDetailCont.unusedTable th {
  padding: 1.2rem 0.8rem;
}
.accDetailCont.unusedTable th:first-child {
  width: 200px;
  max-width: none;
}
.accDetailCont.unusedTable th:nth-child(2) {
  width: 180px;
}
.accDetailCont.unusedTable th:last-child {
  width: 100px;
} */
.listCount {
  margin-left: 10px;
  color: #999;
}

.idleCardContainer .MuiGrid-item {
  height: fit-content;
}
.idleCardContainer {
  height: 68vh;
  align-content: flex-start;
}

/* Pagination Css */
.paginationList {
  display: flex;
  list-style: none;
  align-items: center;
}

.paginationList > li a {
  height: 42px;
  width: 42.400001525878906px;
  left: 0px;
  top: 0px;
  border-radius: 5px;
  padding: 12px 16px 12px 16px;
  cursor: pointer;
  /* border: 1px solid; */
}

.paginationList a {
  text-decoration: none;
}
.paginationList .MuiButton-containedPrimary {
  background-color: #4285f4;
}
.paginationList .MuiButton-root {
  min-width: 42px;
  margin: 5px;
}

.activePagination > a {
  border: 1px solid #71a3f6;
}

.navButton {
  background-color: #4285f4;
  height: 42px;
  width: 42.400001525878906px;
  left: 0px;
  top: 0px;
  border-radius: 5px;
  padding: 12px 16px 12px 16px;
}

.resourceDetailsHeader {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.idleTableContainer {
  width: 100%;
  overflow: overlay;
}
