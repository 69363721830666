.home-banner {
  background-color: #0f054c !important;
  color: white;
}

.azureDetail-banner {
  background-color: #0d0443 !important;
  color: white;
}

.img-width {
  width: 100%;
  max-width: 350px;
}
.blue {
  color: #0065b7 !important;
}
.blueL {
  color: #3a6cab !important;
}
.purple {
  color: #81498a !important;
}
.red {
  color: #db4f36 !important;
}
.green {
  color: #8b865c !important;
}
.greenL {
  color: #51bfa6 !important;
}
.greenD {
  color: #005c1a !important;
}
.white {
  color: #ffffff !important;
}
.paper {
  text-align: center;
  border: 1px solid;
}
.blue-border {
  border-color: #0065b7 !important;
}
.purple-border {
  border-color: #81498a !important;
}
.orange-border {
  border-color: #ff8877 !important;
}
.orange-border .fa-walking {
  color: #ff8877;
}
.red-border {
  border-color: #db4f36 !important;
}
.redGC-border {
  border-color: #ea4335 !important;
}
.dBlue-border {
  border-color: #470081 !important;
}
.yellow-border {
  border-color: #ff9900 !important;
}
.green-border {
  border-color: #008f0c !important;
}
.green-border .fa-file-medical-alt {
  color: #008f0c;
}
.white-border {
  border-color: #ffffff !important;
}
.icon-width {
  max-width: 40px;
}
.grid-image {
  display: flex;
  justify-content: center;
  align-items: center;
}
.tile-background {
  background-color: #fbfbfb;
}
.flex-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.flex-content {
  flex-basis: 58%;
  flex-grow: 0;
}
.flex-img {
  flex-basis: 42%;
  flex-grow: 0;
}
.flex-item {
  flex-basis: 30%;
  flex-grow: 0;
  flex: 1;
}
.border {
  border-radius: 5px;
}

.homeBanner {
  min-height: 400px;
  display: flex;
  overflow: hidden;
}
.homeBanner .homeBImage {
  background: url('../../../assets/images/cfx-0.png') no-repeat;
  background-position: right center;
  background-size: 55%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  transform: scaleX(-1);
}

.homeBanner .rightsizingDetailImage {
  background: url('../../../assets/images/right-sizing-top-banner.png') no-repeat;
  background-position: right center;
  background-size: 55%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  transform: scaleX(-1);
}

.homeBanner .rightsizingDetailImage .homeBannerText{ margin-right: 50%; width: 50%;}


.homeBanner .schedulingDetailImage {
  background: url('../../../assets/images/scheduling-top-banner.png') no-repeat;
  background-position: right center;
  background-size: 50%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  transform: scaleX(-1);
}

.homeBanner .schedulingDetailImage .homeBannerText{ margin-right: 50%; width: 50%;}

.homeBanner .azureSpendImage {
  background: url('../../../assets/images/top-banner.png') no-repeat;
  background-position: left center;
  /* background-size: 600px 100%; */
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.homeBanner .azureSpendText {
  width: 55%;
  float: right;
  text-align: center;
  padding: 8% 20px;
}

.homeBanner .azureSpendText p {
  line-height: 2.5rem;
  padding: 0 2px;
  font-size: 1.5rem;
}

.homeBanner .homeBannerText {
  width: 55%;
  float: right;
  text-align: left;
  padding: 8% 20px;
  margin-right: 43%;
  transform: scaleX(-1);
}
.homeBanner .homeBannerText h1 {
  margin-bottom: 10px;
  font-weight: 300;
  font-size: 3.5rem;
}
.homeBanner .homeBannerText h1 span {
  font-size: 2.5rem;
}
.homeBanner .homeBannerText p {
  line-height: 2.5rem;
  padding: 0 2px;
  font-size: 1.5rem;
}
.contentArea p {
  margin-top: 10px;
  font-size: 1.5rem;
  font-weight: 300;
  line-height: 2.5rem;
}

.security-banner {
  background-color: #1743ba !important;
  color: white;
}
.aboutBanner .securityBImage {
  background: url('../../../assets/images/IT-compliance.png') no-repeat;
  background-position: left center;
  background-size: auto;
  display: flex;
  align-items: center; flex-direction: row-reverse;
}
.aboutBanner .securityBannerText {
  width: 55%;
  float: right;
  text-align: left;
  padding: 8% 20px;
  /* margin-right: 43%; */
  /* transform: scaleX(-1); */
}
.bannerSubText{ line-height: 28px; font-size: 16px; font-weight: 300; margin-top: 20px; width: 80%;}

.securityList{ display: flex; flex-wrap: wrap; justify-content: space-around;}
.securityList .listItem{ width: 48%; padding: 10px; min-width: 450px; display: flex; align-items: flex-start;}
.securityList .listItem .checkIcon{color: #999; padding-top: 5px;}
.securityList .listItem h6{font-size: 20px; line-height: 28px; color: #222; font-weight: 400;}
.securityList .listItem p{margin-top: 10px;}

.itComplianceList{ display: flex; flex-wrap: wrap; justify-content: space-around;}
.itComplianceList .listItem{ width: 32%; padding: 20px; min-width: 200px; display: flex; align-items: flex-start;}
.itComplianceList .listItem .checkIcon{color: #999; padding-top: 5px; display: none;}

.itComplianceCont{display: flex; justify-content: center;}
.itCompliance{ display: flex; flex-wrap: wrap; list-style: none; counter-reset: my-awesome-counter; justify-content: space-between;}
.itCompliance li::before { content: counter(my-awesome-counter) ". "; color: #002ea9; font-weight: 600; position: absolute; left:-5px;}
.itCompliance li{ list-style: none; counter-increment: my-awesome-counter; width: 270px; padding: 15px 20px; font-size: 16px; font-weight: 300; position: relative;}

.aboutBanner .aboutBImage {
  background: url('../../../assets/images/cfx-about-0.png') no-repeat;
  background-position: right center;
  background-size: 70%;
  display: flex;
  align-items: center;
}
.aboutBanner .aboutBannerText {
  width: 55%;
  text-align: left;
  padding: 10% 20px;
}
.aboutBanner .aboutBannerText h1 {
  margin-bottom: 10px;
  font-weight: 300;
  font-size: 3.8rem;
  line-height: 5.5rem;
}
.aboutBanner .aboutBannerText h1 span {
  font-size: 2.5rem;
}
.aboutBanner .aboutBannerText p {
  line-height: 2.8rem;
  padding: 0 2px;
  font-size: 1.7rem;
}

.aboutBanner .BFIBannerText {
  width: 55%;
  text-align: left;
  padding: 10% 20px;
}
.aboutBanner .BFIBannerText h1 {
  margin-bottom: 10px;
  font-weight: 500;
  font-size: 4rem;
  line-height: 5.5rem;
}
.infrastructureSolutions{background: #00227e !important; color: #fff;}

@media (max-width: 960px) {
  .flex-item {
    flex-basis: 45%;
    max-width: 45%;
  }
  .homeBanner .homeBannerText h1 {
    font-size: 3rem;
  }
  .aboutBanner .BFIBannerText{width: 70%;}
}

@media (max-width: 768px) {
  .homeBanner {
    min-height: 280px;
  }
  .homeBanner .homeBImage {
    background-size: 100%;
  }
  .homeBanner .homeBannerText {
    background: rgba(15, 5, 76, 0.7);
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 10% 20px;
  }
  .securityBannerText .bannerSubText, .securityBannerText h1, .securityBannerText button {margin-left: 30px;}
  .aboutBanner .BFIBannerText{width: 85%;}
  .aboutBanner .BFIBannerText h1{font-size: 3.5rem; line-height: 3.5rem;}
  
}

@media (max-width: 600px) {
  .homeBanner {
    min-height: 350px;
  }
  .flex-img {
    flex-basis: 100%;
    order: 1;
    max-width: 350px;
  }
  .flex-content {
    flex-basis: 100%;
    order: 2;
  }
  .flex-item {
    flex-basis: 100%;
    max-width: 100%;
  }
  .aboutBanner .securityBImage{ background-position: center; background-size: contain; padding: 0 !important;}
  .securityBImage .securityBannerText{ background: rgba(0,46,169,0.85); width: 100%; padding: 10% 20px; height: 100%; display: flex; flex-direction: column; justify-content: center;}
  .securityBImage .securityBannerText h1{ margin-left: 30px;}
  .securityBImage .securityBannerText button{ width: 150px;}
  .security-banner, .home-banner{ min-height: 280px;}
  .aboutBanner .BFIBannerText h1{font-size: 2.5rem; line-height: 3.5rem;}
  .cmBanner{min-height: 280px;}
}

@media (max-width: 360px) {
  .homeBanner .homeBannerText h1 {
    font-size: 2.5rem;
    line-height: 3.5rem;
  }
}

@media (max-width: 480px) {
  
  .sectionTitle{font-size: 20px !important; padding: 10px 0;}
  .sectionTitle span{padding: 15px 5px; width: 100%;}
  .aboutBImage .BFIBannerText{ background: rgba(15,5,76,0.4); width: 100%; padding: 15% 20px;}
  .pricingBanner{margin-bottom: 0;}
  .homeBanner{min-height: 320px;}
  .homeBanner .schedulingDetailImage, .homeBanner .rightsizingDetailImagey, .homeBanner .azureSpendImage, .homeBanner .rightsizingDetailImage {background-size: 120%; background-position: center center;}
  .homeBanner .schedulingDetailImage .homeBannerText, .homeBanner .rightsizingDetailImage .homeBannerText, .homeBanner .azureSpendText { margin-right: 0; width: 100%; text-align: left;}
  .homeBanner .azureSpendText{background: rgba(13,4,67,0.5); padding: 20% 20px;}
}
