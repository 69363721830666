.dateInputBox .MuiInputBase-input {
  font-size: 12px;
}

.dateInputBox .MuiOutlinedInput-input {
  padding: 12.5px 14px;
}

.dateInputBox .MuiInputLabel-outlined.MuiInputLabel-shrink {
  font-size: 1.6rem;
}
