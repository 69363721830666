.loadingContainer {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loadingWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
}

.loadingDot {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  animation: dotJumpVibrate 0.8s infinite
    cubic-bezier(0.455, 0.03, 0.515, 0.955);
}

.firstDot {
  background-color: #3d008c;
  animation-delay: 0s;
}

.secondDot {
  background-color: #00d2bf;
  animation-delay: 0.1s;
}

.thirdDot {
  background-color: #1c71a7;
  animation-delay: 0.2s;
}

.fourthDot {
  background-color: #1099b0;
  animation-delay: 0.3s;
}

@keyframes dotJumpVibrate {
  0%,
  100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-12px);
  }
  60% {
    transform: translateY(0);
  }
  65% {
    transform: translateY(-3px);
  }
  70% {
    transform: translateY(0);
  }
  75% {
    transform: translateY(-1px);
  }
  80% {
    transform: translateY(0);
  }
}
