.serviceReportWrapper {
  display: flex;
  align-items: center;
  padding: 4px 0;
  background-color: #ffffff;
  border-bottom: 1px solid #eaeaea;
  height: 'fit-content';
  width: 100%;
  box-sizing: border-box;
}

.serviceReportTitle {
  font-size: 16px;
  font-weight: 600;
  color: #333333;
  margin-right: 20px;
  white-space: nowrap;
}

.serviceReportActions {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-grow: 1;
  gap: 8px;
}

.exportButton {
  min-width: 40px !important;
  width: 40px !important;
  height: 40px !important;
  padding: 8px !important;
  background-color: #00823b !important;
  color: white !important;
  border-radius: 4px !important;
}

.viewToggleGroup {
  height: 40px;
  border: 1px solid #e0e0e0 !important;
  border-radius: 4px !important;
  overflow: hidden;
  background-color: #ffffff;
}

.viewButton {
  min-width: 40px !important;
  width: 40px !important;
  padding: 0 !important;
  color: #757575 !important;
  border: none !important;
  background-color: #fff !important;
}

.viewButtonActive {
  background-color: #f5f5f5 !important;
}

.timeframeContainer {
  display: flex;
  height: 40px;
  margin-left: 8px;
  border-radius: 4px;
  overflow: hidden;
  border: 1px solid #e0e0e0;
}

.timeframeButton {
  font-size: 12px !important;

  font-weight: 500 !important;
  padding: 0 16px !important;
  min-width: unset !important;
  /* height: 40px !important; */
  border-radius: 0 !important;
  text-transform: none !important;
  /* font-weight: 400 !important; */
  color: rgba(0, 0, 0, 0.87) !important;
  border: none !important;
  border-right: 1px solid #e0e0e0 !important;
  /* background-color: #ffffff !important; */
}

.timeframeButton:last-child {
  border-right: none !important;
}

.timeframeActive {
  background: rgba(61, 0, 140, 0.1) !important;
  color: rgba(61, 0, 140, 1) !important;
  border-radius: 0 !important;
}

.selectControl {
  min-width: 180px !important;
  height: 40px;
  margin-left: 8px;
}

.selectDropdown {
  height: 40px;
  background-color: #ffffff;
}

.selectDropdown .MuiOutlinedInput-notchedOutline {
  border-color: #e0e0e0;
}

/* Force everything to stay on one line */
/* @media (min-width: 1024px) {
  .serviceReportActions {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
} */

.autocompleteInput .MuiOutlinedInput-root {
  height: 40px;
  border-radius: 4px;
}

.autocompleteInput .MuiOutlinedInput-notchedOutline {
  border-color: #e0e0e0;
}

.autocompleteInput .MuiInputLabel-outlined {
  transform: translate(14px, 12px) scale(1);
  font-size: 12px;
}

.autocompleteInput .MuiInputLabel-shrink {
  transform: translate(14px, -6px) scale(0.75) !important;
  background-color: #ffffff;
  padding: 0 4px;
}

.autocompleteInput .MuiAutocomplete-endAdornment {
  right: 8px;
}

.autocompleteInput .MuiAutocomplete-input {
  padding: 3.5px 4px !important;
  font-size: 12px;
}

.autocompleteInput .MuiAutocomplete-endAdornment {
  top: calc(60% - 14px) !important;
}

.autocompleteArrow {
  font-size: 12px;
  color: #757575;
}

.autocompletePaper {
  margin-top: 4px;
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}

/* Make sure the autocomplete fields keep the right styling */
.selectDropdown.MuiAutocomplete-root {
  min-width: 180px;
  height: 40px;
  background-color: #ffffff;
}

/* Adjust positioning of elements inside autocomplete */
.selectDropdown .MuiAutocomplete-inputRoot {
  padding-top: 0;
  padding-bottom: 0;
}

/* Update the existing selectControl to work well with Autocomplete */
.selectControl {
  min-width: 180px;
  height: 40px;
  margin-left: 8px;
}
