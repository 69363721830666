.logSignSection {
  width: 21%;
  margin-left: 5%;
}

.loginBtnSection {
  border: 1.5px solid #00d2bf;
  padding: 2px;
  border-radius: 10px;

  width: 100%;
  align-items: center;
}

/* .loginSection {
  width: 56%;
  text-align: center;
}

.signUpSection {
  border-radius: 10px;
  background: linear-gradient(90deg, #3d008c 0%, #00d2bf 100%);
  width: 70%;
  text-align: center;
} */

.signUpSection {
  border-radius: 10px;
  width: 12rem;
  text-align: center;
}

.signUpSection.activeLandingBtn:hover {
  background: linear-gradient(90deg, #3b0483 0%, #0b9488 100%);
  transition: 2s ease-in-out !important;
}

.signUpSection.activeLandingBtn {
  background: linear-gradient(90deg, #23014e 0%, #0b9488 100%);
}

.signUpSection.activeLandingBtn {
  background: linear-gradient(90deg, #23014e 0%, #0b9488 100%);
  border-radius: 10px;

  width: 12rem !important;
}

.signUpSection a:hover {
  background: #70e4db;
  padding: 10px 18px;
  transition: 0.2s ease-in-out !important;
}

.loginBtnRadius a:hover {
  border-radius: 10px 0 0 10px;
}

.signUpBtnRadius a:hover {
  border-radius: 0 10px 10px 0;
}

.signUpSection.activeLandingBtn a {
  color: white !important;
  background: transparent;
  padding: 10px 18px;
}

.currencyDropbox .MuiSelect-select.MuiSelect-select {
  display: flex;
  padding-right: 5px;
  justify-content: space-between;
  text-align: center;
  align-items: center;
  color: #fff;
  margin: 0 1rem;
}

.currencyDropbox .MuiInput-underline:before,
.currencyDropbox .MuiInput-underline:after,
.currencyDropbox .MuiInput-underline:hover:not(.Mui-disabled):before {
  border-bottom: none;
}

.accountselectedDisplay {
  background: rgba(255, 255, 255, 0.527);
  border-radius: 12px;
  color: white;
  font-weight: 700;
  font-size: 16px;
  padding: 12px 12px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  min-width: 80px;
  margin: 0 10px;
}

/* .currencyDropbox .MuiInput-underline:after {
  border-bottom: 2px solid #e6e1e1;
} */

.currencyDropbox .MuiSelect-icon {
  color: #fff;
}

.currencySection {
  width: 21rem !important;
  display: flex !important;
  justify-content: space-between !important;
}
