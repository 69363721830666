.DocUpload-UpperCont {
}

.uploadDocTextHeading {
  font-size: 20px;
  font-weight: 300;
  line-height: 27px;
  letter-spacing: 0em;
  text-align: left;
}

.DocUploadBtn-active {
  width: 228.17px;
  height: 46px;
  background-color: #00bbdc;
  border: none;
  color: white;
  border-radius: 5px;
  font-size: 18px;
  font-weight: 400;
  line-height: 25px;
  text-align: center;
}

.DocUploadBtn-inactive {
  font-weight: 400;
  font-size: 18px;
  line-height: 25px;
  text-align: center;
  color: #00bbdc;
  width: 228.17px;
  height: 46px;
  background: #ffffff;
  border: 1px solid #00bbdc;
  border-radius: 5px;
}
.DocUploadBtn-inactive:hover {
  cursor: pointer;
  font-weight: 400;
  font-size: 18px;
  line-height: 25px;
  text-align: center;
  color: #00bbdc;
  width: 228.17px;
  height: 46px;
  background: #ffffff;
  border: 1px solid #00bbdc;
  border-radius: 5px;
}

.DocUpload-BottomCont {
  display: flex;
  gap: 2%;
  width: 100%;
  height: 560px;
}

.DocUpload-leftPanel {
  width: 52%;
  height: 300px;
}
.DocUpload-rightPanel {
  width: 48%;
  height: 300px;
}

.DocUpload-dropBox {
  height: 300px;
  cursor: pointer;
}

.clickUploadText {
  font-weight: 600;
  text-decoration: underline;
  font-size: 16px;
}
.uderline {
  text-decoration: underline;
}
.selectBoxOuterCont {
  display: grid;
  grid-template-columns: 40% 56%;
  width: 100%;
  gap: 4%;
}
.DocUpload-selectBox {
  width: 100%;
}

.DocUpload-selectBox .css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root {
  font-size: 1.3rem !important;
  width: 100%;
  /* border: 1px solid #cccccc; */
}

.DocUpload-selectBox .MuiInputBase-root {
  font-size: 1.3rem !important;
  width: 100%;
  /* border: 1px solid #cccccc; */
  height: 58px;
}

.DocUpload-selectBox .MuiOutlinedInput-input {
  padding: 7px 10px;
}

.DocUpload-selectBox
  .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
  font-size: 16px !important;
  padding-top: 20px !important;
}

.DocUpload-selectBox .MuiPopover-paper li {
  font-size: 16px !important;
  gap: 10px !important;
  padding: 10px 15px !important;
  font-weight: 300 !important;
}

.DocUpload-selectBox
  .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
  padding: 12px 12px !important;
}

.countPosition {
  display: grid;
  width: 100%;
  grid-template-columns: 85% 10%;
}

.extraFields-DocUpload {
  width: 30px;
  height: 30px;
  border-radius: 2px;
  color: white;
  text-align: center;
  padding-top: 20%;
  font-size: 12px;
  background-color: #00bbdc;
}

.Custom-chip {
  background-color: #ebf4ff !important;
  height: 30px !important;
  width: 45% !important;
  margin-right: 3% !important;
  font-size: 16px !important;
}

.MuiChip-labelSmall {
  font-size: 12px !important;
}

.MuiPopover-paper li {
  font-size: 16px !important;
}

.dragDropBox {
  display: flex;
  font-size: 16px;
  font-weight: 300;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}
.smallText {
  font-weight: 300;
  font-size: 13px;
}

.uploadedFilesOuterCont {
  overflow-y: scroll;
  max-height: 190px;
  padding-top: 1%;
}

.uploadedFilesOuterCont::-webkit-scrollbar {
  width: 0.6rem;
  height: 0.1rem;
}
.uploadedFilesOuterCont::-webkit-scrollbar-track {
  background: transparent !important;
  border-radius: 5px;
}
.uploadedFilesOuterCont::-webkit-scrollbar-thumb {
  background: #666;
  border-radius: 5px;
}
.uploadedFilesOuterCont::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.UploadedFiles {
  width: 97%;
  margin: auto;
  display: flex;
  border-radius: 5px;
  margin-bottom: 3%;
  padding: 2% 2%;
  gap: 2%;
  box-shadow: 0px 3px 12px -2px rgba(0, 0, 0, 0.79);
  -webkit-box-shadow: 0px 3px 12px -2px rgba(0, 0, 0, 0.79);
  -moz-box-shadow: 0px 3px 12px -2px rgba(0, 0, 0, 0.79);
}

.UploadedFiles .MuiLinearProgress-colorPrimary {
  background-color: #f2f2f2;
  height: 10px;
  border-radius: 5px;
}

.UploadedFiles .MuiLinearProgress-barColorPrimary {
  background-color: #3ccbf4;
}

.progressBarOuter {
  display: grid;
  gap: 2%;
  grid-template-columns: 95% 2%;
}
.percentageText {
  margin-top: -5px;
}

.noteOuterCont {
  width: 100%;
  background-color: #f8f8f8;
  border: 1px solid #cccccc;
  height: 85px;
  border-radius: 5px;
}

.uploadDoc-note .css-dpjnhs-MuiInputBase-root-MuiOutlinedInput-root {
  background-color: #f8f8f8 !important;
  height: 85px !important;
  font-size: 13px !important;
}

.DocUpload-selectBox label {
  font-size: 1.5rem !important;
}

.red {
  color: red;
}

.black {
  color: black;
}

.DocUpload-leftPanel .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input {
  font-size: 16px;
}

.DocUpload-leftPanel label {
  font-size: 16px;
}

.inputSvg .css-hfutr2-MuiSvgIcon-root-MuiSelect-icon {
  color: rgba(0, 0, 0, 0.54);
}

.inputErrorBorder
  .css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root.Mui-focused
  .MuiOutlinedInput-notchedOutline {
  border-color: red !important;
}
.inputErrorBorder
  .css-1sumxir-MuiFormLabel-root-MuiInputLabel-root.Mui-focused {
  color: red !important;
}

.inputErrorBorder .css-hfutr2-MuiSvgIcon-root-MuiSelect-icon {
  color: red !important;
}
.inputErrorBorder .css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root {
  color: red !important;
}
.inputErrorBorder .css-1d3z3hw-MuiOutlinedInput-notchedOutline {
  border-color: red !important;
}
.inputErrorBorder .MuiInputLabel-outlined.MuiInputLabel-shrink {
  color: red !important;
}
.inputErrorBorder .MuiOutlinedInput-notchedOutline {
  border-color: red !important;
}
.inputErrorBorder .css-igs3ac {
  border-color: red !important;
}
.inputErrorBorder .css-1636szt {
  border-color: red !important;
}
.inputErrorBorder label {
  color: red !important;
}

.alert-success-documetUpload {
  color: #155724;
  background-color: #d4edda;
  border: 1px solid #59b36d;
  border-radius: 3px;
  font-size: 12px;
  padding: 2px 5px;
}

.alert-danger-documentUpload {
  color: red;
  border-radius: 3px;
  font-size: 13px;
  text-align: center;
}

.docUpload-inputBoxes .css-1x5jdmq {
  font-weight: 16px;
}
