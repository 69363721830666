.TitleHeader {
  /* top: 0rem;
  z-index: 100;
  background: #fff;
  border-bottom: 1px solid;
  width: 94vw !important;
  margin: auto !important;
  height: 6rem;
  box-sizing: content-box !important;
  margin-bottom: 2rem !important;
  padding-top: 1.5rem;
  padding-bottom: 0.2rem; */

  background: #fff;
  /* position: sticky; */
  border-bottom: 1px solid;
  /* margin-bottom: 0.2rem; */
  width: 100% !important;
  margin: auto !important;
  height: 6rem;
  /* padding-top: 20px; */
  box-sizing: content-box !important;
  /* position: sticky; */
  margin-bottom: 2rem !important;
  /* padding-top: 1.5rem; */
  padding-bottom: 0.2rem;
}
