/* .footer {
  background: ;
  background-repeat: no-repeat;
  background-position: bottom right;
  background-size: cover;
  color: #fff;
  height: 350px;
}
.footer .cf-Logo {
  margin: 5px 0 0;
}
.footer .disclaimer {
  font-size: 12px;
  text-align: center;
  margin-top: 5px;
  color: #fff !important;
}
.socialIcon {
  list-style: none;
  padding: 0;
  margin: 15px 0;
  font-size: 25px;
}
.socialIcon li {
  display: inline-block;
  margin-right: 15px;
}
.footerLinkList {
  list-style: none;
  padding: 0;
  font-size: 16px;
}
.footerLinkList li {
  padding: 0;
  margin: 8px 0;
}

.footer .link {
  text-decoration: none;
  color: #fff;
}
.footer .fAddress {
  font-weight: 300;
  line-height: 2.6rem;
  padding: 0 0.5rem;
  margin: 0px 0 20px;
}
.footer .fAddress h6 {
  font-weight: normal;
  font-size: 1.8rem;
  margin: 5px 0;
}
.footer .fAddress .fa-map-marked-alt {
  position: relative;
  top: 2px;
  font-size: 2rem;
}
.footer .fAddress .fa-mobile-alt {
  position: relative;
  top: 2px;
  font-size: 2rem;
  margin: 0 0.5rem;
} */

.footerBody {
  font-family: 'Sora', sans-serif;
  font-size: 16px;
  letter-spacing: 1px;
  line-height: 1.3;
  color: #000;
}

.footerInnerCont {
  width: 90%;
  margin: auto;
  display: flex;
  flex-direction: row;
  gap: 2%;
}

/* @media (min-width: 1601px) {
  .Footer-container {
    max-width: 1570px;
  }
} */
.Footer-container {
  display: flex;
  flex-direction: row;
  gap: 7%;
}

.footerInnerCont > div:nth-child(1) {
  width: 35%;
}

.Footer-container .navbar .navbar-brand {
  width: 160px;
  padding: 0;
}
.navbar .navbar-nav .nav-item .nav-link {
  color: #000;
  padding: 15px;
}
.navbar .navbar-nav .group-header-btn {
  border: 1px solid #00d1bf;
  padding: 4px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  width: 130px;
  margin-right: 0;
  margin-left: auto;
}
.navbar .navbar-nav .group-header-btn .headerbtn {
  background: transparent;
  border: 0;
  padding: 10px 20px;
  border-radius: 10px;
  width: 120px;
  font-size: 16px;
  color: #000000;
  font-weight: 400;
  text-align: center;
}
.navbar .navbar-nav .group-header-btn .headerbtn:hover {
  background: linear-gradient(90deg, #3d008c 0%, #00d2bf 100%);
  color: #fff;
}
.navbar .navbar-nav .dropdown .dropdown-menu {
  min-width: 220px;
  width: -moz-max-content;
  width: max-content;
  box-shadow: 0 4px 10px -2px rgba(0, 0, 0, 0.1);
  border: none;
  border-radius: 10px;
}
.navbar .navbar-nav .dropdown .dropdown-menu .dropdown-item {
  padding: 15px 35px;
}
.navbar .navbar-nav .dropdown .dropdown-menu .dropdown-item:hover {
  background-color: transparent;
}

footer {
  background-color: #f1f2f5;
  padding: 50px 0;
}
footer .footer-brand {
  width: 180px;
}
footer h3 {
  font-size: 24px;
  font-weight: 700;
  line-height: 24px;
}
footer .footer-nav {
  list-style: none;
  padding: 0;
  margin-top: 50px;
}
footer .footer-nav li a {
  color: #5f5f5f;
  line-height: 35px;
}
footer .footer-nav li a:hover {
  color: #000;
  text-decoration: none;
}
footer .footer-nav li .fas {
  color: #00d2bf;
} /*# sourceMappingURL=style.css.map */
.navbar {
  .navbar-brand {
    width: 160px;
    padding: 0;
  }
  .navbar-nav {
    .nav-item {
      .nav-link {
        color: #000;
        padding: 15px;
      }
    }
    .group-header-btn {
      border: 1px solid #00d1bf;
      padding: 4px;
      border-radius: 10px;
      display: flex;
      justify-content: center;
      width: 130px;
      margin-right: 0;
      margin-left: auto;
      .headerbtn {
        background: transparent;
        border: 0;
        padding: 10px 20px;
        border-radius: 10px;
        width: 120px;
        font-size: 16px;
        color: #000000;
        font-weight: 400;
        text-align: center;
        &:hover {
          background: linear-gradient(90deg, #3d008c 0%, #00d2bf 100%);
          color: #fff;
        }
      }
    }
    .dropdown {
      .dropdown-menu {
        min-width: 220px;
        width: max-content;
        box-shadow: 0 4px 10px -2px rgba(0, 0, 0, 0.1);
        border: none;
        border-radius: 10px;
        .dropdown-item {
          padding: 15px 35px;
          &:hover {
            background-color: transparent;
          }
        }
      }
    }
  }
}

footer {
  background-color: #f1f2f5;
  padding: 50px 0;
  .footer-brand {
    width: 180px;
  }
  h3 {
    font-size: 24px;
    font-weight: 700;
    line-height: 24px;
  }

  .footer-nav {
    list-style: none;
    padding: 0;
    margin-top: 50px;
    li {
      a {
        color: #5f5f5f;
        line-height: 35px;
        &:hover {
          color: #000;
          text-decoration: none;
        }
      }
      .fas {
        color: #00d2bf;
      }
    }
  }
}

div {
  /* border: 1px solid black; */
}
