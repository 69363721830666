.cancelBtn {
  width: 210px;
  margin-right: 1.5rem;
  border: none;
  border-radius: 4px;
  background-color: #ccc;
  height: 40px;
  color: white;
  cursor: pointer;
}
.approveBtn {
  width: 210px;
  margin-right: 1.5rem;
  color: white;
  background-color: #0abde3;
  border: none;
  border-radius: 4px;
  height: 40px;
  cursor: pointer;
}

@keyframes zoomIn {
  from {
    opacity: 0;
    transform: translate(-50%, -50%) scale(0.7);
  }
  to {
    opacity: 1;
    transform: translate(-50%, -50%) scale(1);
  }
}

@keyframes zoomOut {
  from {
    opacity: 1;
    transform: translate(-50%, -50%) scale(1);
  }
  to {
    opacity: 0;
    transform: translate(-50%, -50%) scale(0.7);
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
    background: rgba(0, 0, 0, 0);
  }
  to {
    opacity: 1;
    background: rgba(0, 0, 0, 0.5);
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
    background: rgba(0, 0, 0, 0.5);
  }
  to {
    opacity: 0;

    background: rgba(0, 0, 0, 0);
  }
}

.fixModal {
  padding: 0;
  background: #fff;
  width: calc(100% - 20px);
  max-width: 500px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 3px;
  overflow: hidden;
  z-index: 9999999999;
  animation: zoomIn 0.3s ease-out;
}

.fixModal.closeAnimation {
  animation: zoomOut 0.3s ease-in;
}

.customOverlay {
  position: fixed;
  inset: 0px;
  background: rgba(0, 0, 0, 0.5) !important;
  animation: fadeIn 0.3s ease-out;
}

.customOverlay.closeOverlay {
  background: rgba(0, 0, 0, 0);
  animation: fadeOut 0.3s ease-in;
}

.fixModal:focus {
  outline: 0px;
}
.fixModal *:focus {
  outline: 0px;
}
.fixmodal2 {
  width: 600px !important;
}
.fixModal .modalHeader {
  background: #1583dc;
  color: #fff;
  position: relative;
}
.fixModal .modalHeader {
  padding: 1.4rem;
  display: inline-block;
  /* text-transform: uppercase; */
}
.modalTitle {
  margin-right: 6%;
  display: inline-block;
  text-transform: uppercase;
}
.fixModal .modalHeader .modalClose {
  position: absolute;
  right: 0;
  top: 0;
  font-size: 1.6rem;
  padding: 1.2rem 1rem 0.8rem;
  cursor: pointer;
}
.fixModal .modalHeader .modalClose:hover {
  background: #3096e8;
}
.fixModal .modalBody {
  padding: 1.5rem;
  font-size: 1.4rem;
  width: 100%;
  overflow: auto;
  max-height: calc(100vh - 110px);
}
.fixModal .modalBody ul.instanceList {
  list-style: none;
  margin: 1rem 0 0;
  padding: 0;
}
.fixModal .modalBody ul.instanceList li {
  list-style: none;
  padding: 0.2rem 0;
  font-weight: 500;
  display: flex;
}
.fixModal .modalBody ul.instanceList li .checkIcon {
  position: relative;
  top: 3px;
  font-size: 1.5rem;
  color: #555;
}
.fixModal .modalBody .modalFooter div {
  box-sizing: border-box;
}
.fixModal .modalBody .modalFooter div button {
  width: 100%;
  border: 0px;
  color: #fff;
  padding: 1rem;
  text-transform: uppercase;
  cursor: pointer;
}
.fixModal .modalBody .modalFooter div .yesBtn {
  background: #1583dc;
  border: 1px solid #1583dc;
  width: 100%;
  max-width: 225px;
  height: 100%;
  color: #fff;
  cursor: pointer;
  margin: 0 auto;
  display: block;
}
.fixModal .modalBody .modalFooter div .yesBtn:hover {
  background: #1678c8;
  border: 1px solid #1678c8;
}
.fixModal .modalBody .modalFooter div .yesBtn:disabled {
  cursor: default;
}
.fixModal .modalBody .modalFooter div .noBtn {
  background: #fff;
  border: 1px solid #777;
  color: #333;
  box-sizing: border-box;
  cursor: pointer;
}
.fixModal .modalBody .modalFooter div .noBtn:hover {
  background: #777;
  color: #fff;
}
.fixModal .text-upper {
  text-transform: uppercase;
}
.fixModal .modalBody .sendStatus {
  display: block;
}
.fixModal .modalBody .sendStatus label {
  display: inline-flex;
  margin: 0;
}
.fixModal .modalBody .sendStatus label .MuiRadio-colorSecondary.Mui-checked {
  color: #1583dc;
}
.fixModal .modalBody .sendStatus label > span {
  font-size: 1.4rem;
  margin: 0 0.5rem 0 0;
  padding: 0;
}
.fixModal .modalBody .legend {
  padding: 2rem 1.8rem;
  border: 1px solid #666;
  position: relative;
  margin-top: 3rem;
  border-radius: 3px;
}
.fixModal .modalBody .legend * {
  font-size: 1.3rem;
  padding: 5px 0;
}
.fixModal
  .modalBody
  .legend
  .MuiInput-underline:hover:not(.Mui-disabled):before,
.fixModal .modalBody .legend .MuiInput-underline:after {
  border-bottom: 1px solid rgb(0, 73, 132);
}
.fixModal .modalBody .legend .legendTitle {
  background: #fff;
  padding: 0.5rem;
  position: absolute;
  top: -15px;
  text-transform: uppercase;
}
.fixModal .modalFooter {
  padding: 10px 15px;
  display: flex;
  flex-direction: row-reverse;
  width: 100%;
  background: #f8f8f8;
  border-top: 1px solid #ddd;
  margin-top: 5px;
}
.days {
  width: 100%;
  margin-bottom: 2.5rem;
}
.util {
  margin-bottom: 2.5rem;
  width: 100%;
}
.closeBtn {
  float: right;
  position: absolute;
  right: 0;
  font-size: 2rem;
  top: 0px;
  cursor: pointer;
}
.header {
  background: #1583dc;
}
.accordionHeader {
  background: #fbfbfb;
  border-top: 2px solid #1582dc;
  border-bottom: 1px solid #ddd;
}
.t {
  background: ddecff;
  padding: 10px !important;
}
.fixModal .input {
  padding: 8px 10px;
  width: 70px;
  margin: 3px 0 3px 0px;
  font-size: 12px;
  box-sizing: border-box;
  border: 1px solid #999;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  outline: none;
  border-radius: 3px;
}

.tableBody .theadBlue {
  background: #ebf4ff;
}
.tableBody .tbody .tableRow {
  border: none;
  border-top: 0.5px solid #ddd;
}
.tableBody .tbody td,
.tableBody thead th {
  padding: 10px;
  border: none;
}
.tableBody thead th {
  color: #0058a0;
}
.newTableBody {
  background-color: salmon;
}
.tbody {
  background-color: #fff;
}
.table-scroll {
  max-height: 30vh;
  overflow: auto;
  width: 100%;
  border: 1px solid #cfe5ff;
  border-radius: 3px;
}
.table-scroll::-webkit-scrollbar {
  width: 0.8rem;
  height: 0.1rem;
}
.table-scroll::-webkit-scrollbar-track {
  background: #afd0f7 !important;
}
.table-scroll::-webkit-scrollbar-thumb {
  background: #666;
}
.table-scroll::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.azure {
  margin-left: 10px;
  font-size: 11px;
  font-weight: 300px;
}
.subId {
  margin-left: 20px;
  font-size: 11px;
  font-weight: 300px;
  position: relative;
  top: 4px;
}

.subName {
  margin-left: 65px;
  font-size: 11px;
  font-weight: 300px;
  position: relative;
  top: 4px;
}
.setLimit {
  margin-left: 120px;
  font-size: 11px;
  font-weight: 300px;
  position: relative;
  top: 4px;
}
.setLimitInput {
  display: flex;
  align-items: center;
}
.subTableHead {
  background: #ebf4ff;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}
.TableHead {
  display: flex !important;
  justify-content: space-between !important;
}
.fixModal .modalBody::-webkit-scrollbar {
  width: 0.8rem;
  height: 0.8rem;
}
.fixModal .modalBody::-webkit-scrollbar-track {
  background: #f1f1f1;
}
.fixModal .modalBody::-webkit-scrollbar-thumb {
  background: #666;
}
.fixModal .modalBody::-webkit-scrollbar-thumb:hover {
  background: #555;
}
.permission {
  position: absolute;
  left: 20px;
  font-size: 1.2rem;
  line-height: 2rem;
}

.modalBody .export {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 5px;
}
.modalBody .exportLabel {
  display: inline-flex;
  flex-wrap: nowrap;
  min-width: 150px;
  flex-direction: row;
}
.modalBody .exportRadio {
  display: inline-flex;
  flex-wrap: nowrap;
  min-width: 300px;
  flex-direction: row;
}
.modalBody .exportRadio span {
  font-size: 13px;
}
.modalBody .invoiceOption {
  width: 115px;
}
.modalBody .invoiceOption span {
  font-size: 13px;
}
.MuiFormHelperText-root.Mui-error {
  font-size: 10px;
  margin-right: 0px !important;
  margin-left: 1px;
}
.MuiFormHelperText-contained {
  font-size: 11px !important;
  color: #f44336 !important;
  margin-right: 0px !important;
  margin-left: 1px;
}
.disabled {
  pointer-events: none;
}
.MuiPickersToolbar-toolbar,
.MuiPickerDTTabs-tabs {
  background-color: #1583dc !important;
}
input[readonly] {
  background: transparent !important;
}
.MuiTypography-subtitle1 {
  font-size: 1.3rem !important;
}
.MuiTypography-body2 {
  font-size: 1.2rem !important;
}
.MuiTypography-caption,
.MuiTypography-body1 {
  font-size: 1.2rem;
  color: rgba(0, 0, 0, 0.87) !important;
}

.modalScrollBar {
  overflow-y: auto;
  height: 45vh;
}

.modalScrollBar::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #f5f5f5;
}

.modalScrollBar::-webkit-scrollbar {
  width: 5px;
  background-color: #f5f5f5;
}

.modalScrollBar::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #555;
}

.tagKeyInput label,
.tagKeyInput input {
  font-size: 13px;
}

.cut {
  text-decoration: line-through;
  color: #999;
  transition: 0.5s linear;
}

.unCut {
  text-decoration: none;

  transition: 0.5s linear;
}

.removeTag {
  margin-top: 1%;
  padding-top: 2.2%;
  height: 50px;
}
