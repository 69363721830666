.dashboardAccountBox {
  display: flex;
  align-items: center;
  border: 1px solid #ccc;
  height: 60px;
  border-radius: 3px;
  overflow: hidden;
  /* filter: grayscale(100%); */
}
.dashBoardBox {
  border: 1px solid #ccc;
  overflow: hidden;
  border-radius: 3px;
}
.dashboardBoxBig {
  border: 1px solid #ccc;
  overflow: hidden;
  height: 330px;
  border-radius: 3px;
}
.accountLogo {
  width: 30%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f1f1f1;
  height: 100%;
}

.dashboardText {
  color: #222222;
}

.rightSizingCardHeader {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin: auto;
  padding: 0 20px;
  height: 3rem;
}

.spendSection {
  width: 100%;
  display: flex;
  justify-content: space-around;
  margin: 1.5rem 0 4rem 0px;
}

/* Dashboard RecentActivities */
.noActivitiesFound {
  padding: 10px;
  background: #fff;
  color: #333;
  width: 100%;
}
.dashBoardActivities > span {
  cursor: pointer;
  padding: 15px;
  display: block;
  /* position: relative; */
}
.notificationCount {
  width: 18px;
  height: 18px;
  background: #ffc967;
  color: brown;
  border-radius: 50%;
  /* position: absolute; */
  top: 5px;
  right: 5px;
  text-align: center;
  font-size: 10px;
  font-weight: 600;
  padding: 2px 1px;
}
.dashBoardActivities > span:hover,
.nOpen {
  background: #046ec6;
}
.dashBoardActivities .activitiesBubble {
  /* position: absolute; */
  right: -2px;
  top: 52px;
}
.dashBoardActivities .activitiesBubble .activitiesList {
  border: 1px solid #b0d2ff;
  border-radius: 3px;
}
.notification
  .activitiesBubble
  .activitiesList
  .MuiAccordion-root.Mui-expanded {
  margin: 0;
}
.dashBoardActivities
  .activitiesBubble
  .activitiesList
  .MuiAccordion-root:before {
  display: none;
}
.dashBoardActivities .activitiesBubble .activitiesList .activitiesHead {
  display: flex;
  justify-content: space-between;
  background: #e6f4ff;
}
.dashBoardActivities .activitiesBubble .activitiesList .activitiesHead h6 {
  font-weight: 400;
  color: #0058a0;
  padding: 10px;
}
.dashBoardActivities
  .activitiesBubble
  .activitiesList
  .activitiesHead
  .activityClose {
  font-weight: 400;
  color: #0058a0;
  padding: 8px 6px 4px;
  cursor: pointer;
}
.dashBoardActivities .activitiesBubble .activitiesList .activitiesBody {
  height: 530px !important;
  overflow: auto;
  background-color: #fff;
}
.notification
  .activitiesBubble
  .activitiesList
  .activitiesBody::-webkit-scrollbar {
  width: 0.6rem;
}
.notification
  .activitiesBubble
  .activitiesList
  .activitiesBody::-webkit-scrollbar-track {
  background: #f1f1f1;
}
.notification
  .activitiesBubble
  .activitiesList
  .activitiesBody::-webkit-scrollbar-thumb {
  background: #666;
}
.notification
  .activitiesBubble
  .activitiesList
  .activitiesBody::-webkit-scrollbar-thumb:hover {
  background: #555;
}
.dashBoardActivities .activitiesBubble ul li .updatedBy_nTime {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row-reverse;
  margin-top: 5px;
}
/* .updatedBy_nTime{ display: flex; justify-content: space-between; flex-direction: row-reverse; margin-top: 5px;} */
.dashBoardActivities .activitiesBubble .activitiesList .activitiesBody .nTime {
  color: #666;
  text-align: right;
  font-style: italic;
  font-size: 10px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.notification .activitiesBubble .activitiesList .activitiesBody .nUpdatedBy {
  color: #333;
  font-style: italic;
  font-size: 10px;
  display: flex;
  align-items: center;
  max-width: 125px;
}
.notification .activitiesBubble .activitiesList .activitiesBody .nUpdatedBy i {
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 100%;
  overflow: hidden;
  text-align: left;
}
.dashBoardActivities
  .activitiesBubble
  .activitiesList
  .MuiAccordionSummary-root {
  min-height: auto;
}
.notification .activitiesBubble .activitiesList .MuiAccordionSummary-content {
  margin: 5px 0;
}
.notification
  .activitiesBubble
  .activitiesList
  .MuiAccordionSummary-content
  + div {
  padding: 5px 0;
}
.dashBoardActivities
  .activitiesBubble
  .activitiesList
  .MuiAccordionDetails-root {
  padding: 0px;
}
.dashBoardActivities .activitiesBubble ul {
  list-style: none;
  width: 100%;
}
.dashBoardActivities .activitiesBubble ul li {
  list-style: none;
  display: flex;
  align-items: flex-start;
  padding: 5px;
  border-top: 1px solid #eee;
}
.dashBoardActivities .activitiesBubble ul li > span {
  list-style: none;
  padding: 2px 5px;
  display: flex;
  margin-top: 2px;
}
.dashBoardActivities .activitiesBubble ul li > div {
  width: calc(100% - 30px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}
.dashBoardActivities .activitiesBubble ul li > div span {
  display: block;
  padding: 2px 5px;
  font-size: 1.2rem;
  word-break: break-all;
}
.dashBoardActivities .activitiesBubble ul li.Failed {
  color: #a30000;
  background: #fff7f7;
}
.dashBoardActivities .activitiesBubble ul li.Success {
  color: #006954;
  background: #f0fffb;
}
.dashBoardActivities .activitiesBubble ul li.InProgress {
  color: #b87100;
  background: #fffcf6;
}
.dashBoardActivities .activitiesBubble ul li.Info {
  color: #0c5460;
  background: #ebfcff;
}
.notificationAlert {
  width: 30%;
  /* position: absolute; */
  z-index: 10;
  right: 0;
  top: 10;
  font-size: 13px;
}

.createAccountContainer {
  max-width: 1000px;
  margin: 0 auto;
}

.loadingBox {
  width: 100%;
  height: 153px;
  padding: 10% 45%;
}

.loadingBoxIdle {
  width: 100%;
  height: 113px;
  margin: 90% 0% 10% 420%;
}

.loadingBoxSpend {
  width: 100%;
  height: 200px;
  padding: 20% 45%;
}

.blueBorder {
  border: 1px solid #d0e4f3;
}

.infoBtn {
  padding-top: 1.7%;
  /* border: 1px solid black; */
}
.infoText {
  font-size: 1vw;
  margin-left: 3%;
  cursor: pointer;
  font-weight: 500;
}

/* App Dashboard */
.appDashboard {
  width: 100%;
  display: flex;
  flex-direction: column;
  column-gap: 20px;
  row-gap: 20px;
}
.appDashboard .dashboardLeftPanel {
  display: flex;
  column-gap: 20px;
  row-gap: 20px;
  width: calc(100% - 320px);
  justify-content: flex-start;
}
.appDashboard .dashboardRightPanel {
  display: flex;
  column-gap: 20px;
  width: 300px;
}
.appDashboard .topStrip {
  display: flex;
  column-gap: 20px;
  flex-wrap: wrap;
}
.appDashboard .topStrip .cloudAccountCard {
  width: 380px;
  display: flex;
  text-align: center;
  border-radius: 5px;
  border: 1px solid #ccc;
  overflow: hidden;
}
.appDashboard .topStrip .cloudAccountCard .cloudAccountLogo {
  width: 90px;
  background: #f3f3f3;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  flex-direction: column;
}
.appDashboard .topStrip .cloudAccountCard .cloudAccountLogo img {
  margin: 15px;
  display: inline-flex;
}
.appDashboard .topStrip .cloudAccountCard h3 {
  display: inline-flex;
  font-size: 17px;
  line-height: 20px;
  font-weight: 600;
}
.appDashboard .topStrip .cloudAccountCard div.subText {
  font-size: 11px;
  line-height: 14px;
  font-weight: 400;
  color: #666;
}
.appDashboard .topStrip .cloudAccountCard .cloudAccountDetail {
  width: calc(100% - 80px);
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}
.appDashboard
  .topStrip
  .cloudAccountCard
  .cloudAccountDetail
  .cloudAccountCount {
  display: flex;
  align-items: baseline;
}
.appDashboard
  .topStrip
  .cloudAccountCard
  .cloudAccountDetail
  .cloudAccountCount
  .cloudAccountLabel {
  font-size: 15px;
  font-weight: 300;
}
.appDashboard
  .topStrip
  .cloudAccountCard
  .cloudAccountDetail
  .cloudAccountCount
  .cloudAccountNumber
  span {
  font-size: 20px;
  font-weight: 600;
  margin-left: 5px;
}
.appDashboard .topStrip .cloudAccountCard.spendMonthly {
  max-width: 300px;
}
.appDashboard .bottomPanel {
  display: flex;
  column-gap: 20px;
  flex-wrap: wrap;
}
.appDashboard .bottomPanel .flexColumn {
  flex-direction: column;
}
.appDashboard .bottomPanel .topLevel {
  display: flex;
  column-gap: 10px;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
}
.appDashboard .bottomPanel .topLevelCards {
  width: 100%;
  max-width: 32%;
  border-radius: 5px;
  border: 1px solid #ccc;
}
.appDashboard .bottomPanel .sbsSFCards {
  display: flex;
  column-gap: 10px;
  flex-wrap: wrap;
  width: 100%;
  justify-content: space-between;
}
.appDashboard .bottomPanel .sbsSFCards .sbsCard {
  width: 100%;
  max-width: 51%;
  border-radius: 5px;
  border: 1px solid #ccc;
  overflow: hidden;
}
.appDashboard .bottomPanel .sbsSFCards .sfCard {
  width: 100%;
  max-width: 47%;
  border-radius: 5px;
  border: 1px solid #ccc;
  overflow: hidden;
}
.appDashboard .recharts-legend-wrapper {
  width: 260px !important;
}
.appDashboard .recharts-legend-wrapper .recharts-legend-item-text {
  width: 220px;
}
.appDashboard .bottomPanel .sbsSFCards .sfCard .recharts-default-legend {
  display: flex !important;
  justify-content: center;
  column-gap: 10px;
  row-gap: 10px;
  margin-top: 10px;
}
.appDashboard .bottomPanel .sbsSFCards .sfCard .recharts-default-legend li {
  display: inline-flex !important;
}
.appDashboard
  .bottomPanel
  .sbsSFCards
  .sfCard
  .recharts-default-legend
  li
  .recharts-legend-item-text {
  width: auto;
}

.centerBaseline {
  display: flex;
  align-items: baseline;
  justify-content: center;
}

.tooltipText {
  font-size: 13px !important;
  text-align: justify;
  font-weight: 500;
}

.tooltipText span {
  display: block;
}

.idleTooltip {
  padding: 0 5px 5px 5px;
  display: flex;
  width: 7vw;
  justify-content: space-between;
}
