.trialDemoBtn {
  margin-top: 30px;
  margin-right: 40px;
}
.rightHeader {
  text-align: left;
}
.loginNavItem {
  padding: 15px;
}
.loginHeader {
  padding: 18px;
}
.loginForm {
  margin: 0;
}
.wrap {
  border: 2px solid #66caea;
  padding: 5%;
  width: 25%;
  min-width: 389px;
  margin: auto;
}
.wrap .MuiTextField-root {
  margin: 10px;
}

.submit {
  width: 25%;
  padding: 3%;
  border: 1px solid #45b3e7;
  font-size: 15px;
  background-color: #45b3e7;
  color: #fff;
}
.formField {
  width: 40ch !important;
}
.fieldIcons {
  float: right;
  position: relative;
  bottom: 40px;
  right: 35px;
}
.loginBottom {
  overflow: hidden;
  position: relative;
  width: 100%;
  font-size: 13px;
}
.checkboxf {
  float: left;
}
.checkboxf span {
  padding: 0 !important;
}
.forgotf {
  float: right;
  padding: 0px;
  color: #1583dc;
  cursor: pointer;
  text-decoration: underline;
}
.btnDiv {
  display: flex;
  justify-content: center;
  align-items: center;
}
.loginBtn {
  background: #1583dc;
  color: #fff;
  margin-top: 20px;
}
.errField {
  color: red;
  font-size: 12px;
  margin-left: 12px;
  float: left;
}
.loginBG {
  display: flex;
  width: 100%;
  height: 100vh;
  position: relative;
  background-image: linear-gradient(to bottom, #0096b9, #184798);
}
.loginBG:before {
  content: '';
  display: block;
  background: url(../../assets/images/landing-page.jpg) no-repeat;
  background-position: left center;
  background-size: 60%;
  overflow: hidden;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 1;
}
.loginForm {
  height: calc(100vh - 70px);
  width: 360px;
  padding: 60px 20px;
  background: #fff;
  display: flex;
  align-items: center;
}
.networkError {
  background: rgb(236, 135, 135);
  color: #850000;
  height: 28px;
  text-align: center;
  letter-spacing: 2px;
  font-size: 16px;
}
.networkErrorButton {
  margin-left: 14px;
  background: rgb(236, 135, 135);
  border: 1px solid #850000;
  color: #850000;
  border-radius: 3px;
  text-align: center;
  letter-spacing: 2px;
}

.inputContainer {
  position: relative;
}

.formLoginBtn {
  padding-right: 40px; /* Adjust as needed */
}

.inputText {
  position: absolute;
  top: 54%;
  left: 44%;
  pointer-events: none;
  color: #fff !important;
  font-size: 16px !important;
  font-family: Sora;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 1px;
  display: flex;
  width: 71px;
  height: 25px;
  flex-direction: column;
}

.fastForwardIcon {
  position: absolute;
  left: 57%;
  top: 62%;
}

.forgetPassText {
  color: #29015f;
  text-align: right;
  cursor: pointer;
  font-size: 14px;
  font-family: Sora;
  font-style: normal;
  font-weight: 600;
  line-height: 130%;
  letter-spacing: 1px;
  text-decoration-line: underline;
}

.secondaryTagline a,
.secondaryTagline span {
  color: #000;
  font-size: 16px !important;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 1px;
}

.secondaryTagline a {
  font-weight: 700;
}

/* .loginForm{ background: url(../../assets/images/registration-banner.png) no-repeat; background-position: left center; background-size: 60%; overflow: hidden; position: absolute; width: 100%; height: 100%; top: 0; left: 0; opacity: 0.6;} */

@media (max-width: 960px) {
  .loginBG:before {
    background-size: cover;
    background-position: center;
  }
  .loginBG .loginForm {
    height: calc(100vh - 64px);
  }
}

@media (max-width: 768px) {
  .loginBG > .w-50 {
    display: none;
  }
  .loginBG .loginForm {
    margin: 0 auto;
  }
}
@media (max-width: 480px) {
  .rightImage {
    padding: 5% 0;
  }
  .loginBG .loginForm {
    background: rgba(255, 255, 255, 0.9);
    width: 100%;
  }
}
