.jsonViewer {
    background-color: white;
    overflow: auto;
    padding: 0 0;
    margin: 0 0;
    height: 200px;
}

.jsonViewer .jsonContainer {
    display: inline-block;
    padding: 5px;
}

.jsonViewer .lineNumberContainer {
    display: inline-block;
    border-right: 1px solid #7C7C7C;
    text-align: right;
    color: #7C7C7C;
    padding: 5px;
}

.jsonViewer .lineNumber {
    width: 100%;
    text-align: right;
}

.jsonViewer .line {
    padding-left: 10px;
};
