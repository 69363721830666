.trialDemoBtn{margin-top: 30px;margin-right: 40px;}
.rightHeader{text-align: left;}
.loginNavItem{padding: 15px;}
.loginHeader{padding: 18px;}
.loginForm{margin: 0;}
.wrap{border: 2px solid #66caea;padding: 5%;width: 25%;min-width: 389px;margin: auto;}
.wrap .MuiTextField-root{margin: 10px;}

.submit{width: 25%;padding: 3%;border: 1px solid #45b3e7;font-size: 15px;background-color: #45b3e7;color: #fff;}
.formField{width: 40ch !important;}
.fieldIcons{float: right;position: relative;bottom: 40px;right: 35px;}
.loginBottom{ overflow: hidden; position: relative; width: 100%; font-size: 13px;}
.checkboxf{float: left;}
.checkboxf span{ padding: 0 !important;}
.forgotf{ float: right; padding: 0px; color: #1583dc; cursor: pointer; text-decoration: underline;}
.btnDiv{ display: flex; justify-content: center; align-items: center;}
.loginBtn{ background: #1583dc; color: #fff; margin-top: 20px;}
.errField{ color: red; font-size: 12px; margin-left: 12px; float: left;}
.demoBG{display: flex; width: 100%; position: relative; background-image: linear-gradient(to right, #02155c, #325cf5);}
.demoBG:before{ content:''; display: block;  background: url(../../../assets/images/cfx-demo-Img.png) no-repeat; background-position: 20% center; background-size: 40%; overflow: hidden; position: absolute; width: 100%; height: 100%; top: 0; left: 0; opacity: 1;}
.loginForm{ height: calc(150vh - 70px); width: 360px; padding: 60px 20px; background: #fff; display: flex; align-items: center;}
.networkError{background: rgb(236, 135, 135);color:#850000;height: 28px;text-align: center;letter-spacing: 2px;font-size: 16px;}
.networkErrorButton{   margin-left: 14px; background: rgb(236, 135, 135);border: 1px solid #850000;color: #850000;border-radius: 3px;text-align: center;letter-spacing: 2px;}
/* .loginForm{ background: url(../../assets/images/registration-banner.png) no-repeat; background-position: left center; background-size: 60%; overflow: hidden; position: absolute; width: 100%; height: 100%; top: 0; left: 0; opacity: 0.6;} */
