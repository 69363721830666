.adminDashbord {
  width: 99.5%;
  margin: 0.3%;
  display: flex;
  gap: 2%;
}

.leftCont {
  width: 75%;
}

.upperStrip {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.OrgText {
  font-size: 20px;
  line-height: 27.24px;
  font-weight: 300;
}

.middleStrip {
  width: 100%;
  height: 200px;
  display: flex;
  gap: 3.5%;
}
.companiesBox {
  width: 31%;
  height: 100%;
  background-color: #f6f6f6;
  border: 1px solid #d6d6d6;
  border-radius: 5px;
}

.companiesBox:hover {
  cursor: pointer;
  background-color: #00bbdc;
  transition: background-color 0.5s ease;
  color: #ffffff;
}
.companiesBox:hover svg,
.companiesBox:hover img {
  filter: brightness(0) invert(1);
}
.companyName {
  font-family: Open Sans;
  font-size: 18px;
  font-weight: 300;
  line-height: 25px;
  letter-spacing: 0em;
  text-align: left;
}
.cloudProvider {
  display: flex;
}
.companyLogo {
  /* width: 50%; */
  height: 40px;
  margin-right: 4%;
  display: flex;
  align-items: flex-end;
}

.imgAdmin {
  width: 100%;
  height: auto;
  /* height: 100%; */
}

.bottom-upperStrip {
  display: flex;
  justify-content: space-between;
}
.bottom-upperStrip .MuiOutlinedInput-input {
  padding: 5px !important;
}

.bottom-upperStrip .MuiOutlinedInput-adornedEnd {
  padding-right: 4px !important;
}

.bottom-upperStrip .rightCont {
  width: 23%;
  height: 460px;
}

.bottom-upperStrip .MuiSvgIcon-root {
  margin-top: 3px !important;
}
.bgcolor {
  background-color: #f6f6f6;
  border-radius: 4px;
}

.evenRow {
  background-color: #f5f5f5;
}

.oddRow {
  background-color: #ffffff;
}

/* .active {
  width: 58px;
  background: #198754;
  border-radius: 50px;
  color: white;
  text-align: center;
  left: 15px;
  top: 15px;
  padding: 1px 10px 1px 10px;
} */
.active {
  background: #198754;
  color: #ffffff;
  text-align: center;
  height: 25px;
  width: 70px;
  border-radius: 50px;
  padding: 1px 10px;
  font-size: 14px;
}

.inactive {
  /* padding: 3%;
   */
  font-size: 14px;
  height: 25px;
  width: 80px;
  left: 15px;
  top: 15px;
  border-radius: 50px;
  padding: 1px 10px;
  background: #dc3545;
  border-radius: 50px;
  color: #ffffff;
  text-align: center;
}

.rightCont {
  width: 23%;
}

.rightContHeader {
  display: flex;
  width: 100%;
  height: 50px;
  background-color: #0078db;
  border-radius: 5px 5px 0 0;
  justify-content: space-between;
  padding: 2%;
}

.teamHeading {
  font-size: 20px;
  font-weight: 300;
  color: white;
  line-height: 27.24px;
}
.teamDetailsBox {
  background-color: #f6f6f6;
  max-height: 610px;
  overflow-x: hidden;
  overflow-y: hidden;
}
.teamDetailsBox:hover {
  overflow: scroll;
  overflow-x: hidden;
}

/* width */
.teamDetailsBox::-webkit-scrollbar {
  width: 5px;
}

/* Track */
.teamDetailsBox::-webkit-scrollbar-track {
  display: none;
}

/* Handle */
.teamDetailsBox::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 2.5px;
}

/* Handle on hover */
.teamDetailsBox::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.avatarBox {
  width: 100%;
  display: flex;
  gap: 5%;
  margin-bottom: 40px;
}

.nameText {
  font-size: 16px;
  line-height: 21.79px;
  font-weight: 400;
}

.roleText {
  font-size: 12px;
  font-weight: 300;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
}

.nameBox {
  width: 45%;
}
.editBox {
  display: flex;
}
.adminPanelTable th {
  background: rgba(241, 242, 245, 1);
}
.clickble {
  color: blue !important;
  text-decoration: underline;
  cursor: pointer;
}

.searchDocumentUpload .MuiSvgIcon-root {
  margin-top: 3px !important;
}

.searchDocumentUpload .MuiOutlinedInput-adornedEnd {
  padding-right: 4px !important;
}

.searchDocumentUpload .MuiOutlinedInput-input {
  padding: 12px !important;
}

.tripleLoader {
  justify-content: space-between;
  width: 100%;
  padding: 100px;
}

.teamLoadingText {
  padding: 10px 0px 20px 0px;
  text-align: center;
}
