.section-title {
  font-weight: 600;
  font-size: large;
  margin-bottom: 20px;
  text-align: center;
}

.top-entity-chart {
  display: flex;
  padding: 0;
  flex-direction: row;
}

.bar-chart {
  width: 55%;
  height: 190px;
}
.pie-chart {
  width: 42%;
  height: 190px;
}
.horizontal-divider {
  display: none;
}
@media (max-width: 768px) {
  .parent-div p {
    font-size: 12px; /* Smaller devices */
  }
  .map-container {
    height: 120%;
  }
  .top-entity-chart {
    display: flex;
    flex-direction: column;
  }
  .topCards {
    width: 50%;
  }
  .country-wise-card {
    width: 50%;
  }
  .bar-chart {
    width: 100%;
  }
  .pie-chart {
    width: 100%;
  }
  .vertical-divider {
    display: none;
  }
  .horizontal-divider {
    display: inline;
  }
}

@media (min-width: 769px) and (max-width: 1024px) {
  .parent-div p {
    font-size: 14px; /* Tablets */
  }
  .map-container {
    height: 120%;
  }
  .top-entity-chart {
    display: flex;
    flex-direction: column;
  }
  .topCards {
    width: 50%;
  }
  .country-wise-card {
    width: 50%;
  }
  .bar-chart {
    width: 100%;
  }
  .pie-chart {
    width: 100%;
  }
  .vertical-divider {
    display: none;
  }
  .horizontal-divider {
    display: inline;
  }
}

@media (min-width: 1025px) {
  .parent-div p {
    font-size: 16px; /* Desktops */
  }
}
