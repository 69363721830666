.slackCredsOuterCont {
  height: 300px;
  overflow-y: scroll;
  width: 100%;
}
.slackCredInnerCont {
  display: flex;
  background-color: #fafafa;
  border-bottom: 1px solid #e7e7e7;
}
.bgColor {
  background-color: #fafafa;
}

.slackInputBox {
  width: 100%;
}

.slackInputBox input {
  padding: 15px !important;
  font-size: 1.4rem;
  letter-spacing: inherit;
  font-family: 'Open Sans', sans-serif !important;
}
.slackInputBox label {
  font-size: 1rem;
  top: -3px !important;
}

.addMoreBtnOuterCont {
  text-align: right;
}

.numbering {
  margin-top: 9%;
  font-size: 2rem;
  margin-left: 10px;
  color: #c9c9c9;
}

.addMoreBtn {
  float: right;
}
