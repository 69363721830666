.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
}

.dot {
  width: 10px;
  height: 10px;
  background-color: #007bff;
  border-radius: 50%;
  margin: 0 5px;
  animation: pulse 1s infinite;
}

.dot1 {
  animation-delay: 0.2s;
}

.dot2 {
  animation-delay: 0.4s;
}

.dot3 {
  animation-delay: 0.6s;
}

@keyframes pulse {
  0%,
  100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
}
