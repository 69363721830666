.snapBack {
  width: 200px;
}
.snapBack label {
  font-size: 1.2rem;
}
.snapBack input {
  font-size: 1.4rem;
}
.cardSummary {
  background: none;
}
.cardSummary .details {
  display: flex;
  flex-direction: column;
  width: calc(100% - 100px);
}
.shortSummary {
  text-align: center;
  box-shadow: 0px 0px 2px #ccc;
  border-radius: 5px;
}
.shortSummary > div {
  height: 130px;
  border-right: 1px solid #eee;
  border-bottom: 1px solid #eee;
}
.shortSummary > div:hover {
  background: #fafafa;
}
.shortSummary > div:nth-child(3n) {
  border-right: 0px;
}
.shortSummary > div:nth-child(4) {
  border-bottom: 0px;
}
.shortSummary > div:nth-child(5) {
  border-bottom: 0px;
}
.shortSummary > div:nth-child(6) {
  border-bottom: 0px;
}

.accCont {
  margin: 0 !important;
}
.accDetail {
  background: #fafafa;
}
.accRow {
  min-height: 40px !important;
}
.accRow > div {
  margin: 0 !important;
}
.shortSummaryDetails {
  padding: 0 15px;
  background: #fbfbfb;
}
.shortSummaryDetails .accordionTitle > * {
  padding: 10px 0;
  font-size: 1.3rem;
  font-weight: 600;
}
.accordionTitle {
  font-size: 1.4 rem;
}
.accordionTitle span.toggleArrow {
  display: inline-block;
  position: relative;
  top: 2px;
  margin-right: 10px;
}
.accordionTitle span.toggleArrow + span {
  display: inline-block;
}
.instanceTable {
  text-align: center;
  width: 100%;
  border-radius: 3px;
  border: 1px solid #ddd;
  overflow: hidden;
}
.instanceTable .itHead {
  font-weight: 600;
  background: #fbfbfb;
}
.instanceTable .itHead > div > * {
  padding: 10px;
}
.instanceTable .itBody .itRow {
  background: #fff;
}
.instanceTable .itBody .itRow:nth-child(even) {
  background: #e6f4ff;
}
.instanceTable .itBody .itRow > * {
  padding: 10px;
}
.cardLink {
  text-decoration: none;
  display: flex;
  color: #333;
  justify-content: center;
  height: 100%;
  align-items: center;
}

.content {
  flex: 1 0 auto;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  text-align: center;
}
.cover {
  width: 90px;
}
.realisedSavingIcon {
  width: 44px;
  height: 44px;
  border-radius: 50%;
  position: relative;
  font-size: inherit;
  color: #fff;
  text-align: center;
  padding: 12px 6px;
}
.realisedSavingIcon:before {
  content: '';
  width: 44px;
  height: 44px;
  border-radius: 50%;
  border: 4px solid #fff;
  position: absolute;
  top: 0;
  left: 0;
  box-sizing: border-box;
}
.realisedSavingIcon:after {
  content: '';
  width: 28px;
  height: 28px;
  border-radius: 50%;
  border: 1px solid #fff;
  position: absolute;
  top: 8px;
  left: 8px;
  box-sizing: border-box;
}
.cardSummary > div .catImage {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  background: #0078db;
  width: 100px;
}
.cardSummary > div > div {
  margin: 0 5px;
}
.cardSummary > div:last-child > div {
  margin-right: 0px;
}
.cardSummary > div:first-child > div {
  margin-left: 0px;
}
