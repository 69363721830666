.uploadedDoc-UpperStrip {
  width: 100%;
}
.docName {
  cursor: pointer;
  font-size: 14px !important;
  color: #0078db !important;
}
.uploadedDoc-serialNo {
  font-size: 14px !important;
}
.docName:hover {
  cursor: pointer;
  font-size: 14px !important;
  color: #0078db !important;
  text-decoration: underline;
}

.customToasterHeader {
  font-size: 13px;
  font-weight: 600;
}

.customToasterText {
  font-size: 14px;
}

.customToasterUsersOuter {
  text-align: left;
}

.MuiAlert-action {
  display: block !important ;
  margin-top: 5px;
}
