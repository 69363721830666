.RUMContainer::-webkit-scrollbar {
  width: 0 !important;
}

.RUMHeader {
  top: 0rem;
  z-index: 1;
  background: #fff;
  /* position: sticky; */
  border-bottom: 1px solid;
  /* margin-bottom: 0.2rem; */
  width: 98% !important;
  margin: auto !important;
  height: 6rem;
}

.dashboard-container {
  padding: 16px;
  /* border: 1px solid; */
  border-radius: 10px;
  margin-top: 2rem;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  border: 1px solid #e0e0e0;
}

.RUMCardHeader {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 0 0rem 1rem 1rem;
}

.RUMCardHeader h5 {
  display: flex;
  align-items: center;
  font-weight: 600;
  color: black;
}

.RUMdomain-link {
  font-size: 17px;
  margin-left: 8px;
  display: flex;
  align-items: center;
  text-decoration: none;
  color: inherit;
}

.RUMdomain-link:hover {
  color: #0078db;
  transition: 0.2s ease-in-out;
}

.dashboard-card {
  padding: 16px;
  position: relative;
  border-radius: 8px !important;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1) !important;
  background: #f3f3f3 !important;
  display: flex;
  /* justify-content: space-between; */
}
.dashboard-card > div:nth-child(1),
.dashboard-card > div:nth-child(3) {
  width: 40%;
}
.card-title {
  font-size: 1.7rem;
  font-weight: 500;
  margin-bottom: 1rem;
  color: #666;
}

.breakLine {
  background: #666;
  width: 0.5px !important;
  margin: 0 2rem 0 2rem;
}

.middleBreakLine {
  background-color: #666;
  width: 100%;
  height: 0.5px;
  margin: 0.5rem 0;
}

.RUMInfo {
  padding: 0;
  margin-top: 2%;
  margin-left: 0.3rem;
}

.card-value {
  font-weight: 600;
  /* margin-bottom: 0.6rem; */
  color: #333;
}

.comparison {
  font-size: 1.3rem;
  letter-spacing: 0.05rem;
  /* color: #666;
  margin-bottom: 16px; */
  margin-top: 0.5rem;
}

.comparison div {
  display: flex;
  align-items: center;
  margin-bottom: 4px;
}

.comparison div span:first-child {
  width: 73px;
}

.comparison div span.colon {
  padding: 0 7px;
}

.comparison div span:last-child {
  flex-grow: 1;
  text-align: left;
  font-size: 1.4rem;
  font-weight: 700;
}

.comparison .positive {
  color: #4caf50;
}

.comparison .negative {
  color: #f44336;
}

.RUMSecondSection {
  display: flex;
  flex-direction: column !important;
  justify-content: space-between;
}

.RUMSecSubSection p {
  margin-bottom: 0.5rem;
}

.activeLoadBoxes {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}

.rumCardRightHeader {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  width: 23rem;
}

.rumCardRightHeader > .MuiIconButton-root {
  padding: 6px;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
}

.rumCardRightHeader > .MuiIconButton-root:nth-child(3) {
  color: red;
}

.rumCardRightHeader > .MuiIconButton-root:nth-child(2) {
  color: black;
}

.RUMDashboardCardContainer {
  padding: 0 2rem;
  margin: auto;
}

.RUMDashboardCardContainer > div:nth-child(1) {
  margin-top: 3rem;
}
