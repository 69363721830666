.pricingBanner{ text-align: left; padding: 0 10px; min-height: 400px; margin-bottom: 100px;}
.pricingBanner .pBannerText{ width: 50%; padding: 9% 30px; background: #0065b9;}
.pricingBanner .pBannerText h1{ font-size: 3.8rem;}
.RequestPricingForm{ position: absolute; top:40px; right: 0; margin-right: 10px; width: 40%; max-width: 400px; min-width: 350px;}
.RequestPricingForm .rpFormCont{ width: 100%; min-height: 300px; background: #fff; border: 2px solid #1583dc; color:#2F0700; border-radius: 10px; padding: 30px;    justify-content: space-around;}
.pContent{clear: both;}

@media (max-width: 768px){
    .pricingBanner{ background: transparent; padding: 0; background: #fbfbfb; margin-bottom: 0;}
    .RequestPricingForm{ float: none; margin:0px auto; width: 100%; min-width: auto; position: static;}
    .RequestPricingForm .rpFormCont{background: transparent; border: none;}
    .pricingBanner .pBannerText{width: 100%; position: static;}
    .pKeyPointsCont{flex-wrap: wrap; justify-content: center; padding: 40px 20px;}
    .pKeyPointsCont .pKeyPoints{ margin: 10px; min-width: 280px;}
}

@media (max-width: 480px){
    .RequestPricingForm{ padding:20px;}
    .RequestPricingForm .rpFormCont{padding: 15px 0; background: transparent;}
}