/* * {
  font-family: 'Open Sans';
} */

.adminTable .MuiTableCell-sizeSmall {
  font-family: Open Sans;
  font-size: 13px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  /* text-align: left; */
  padding: 8px;
  min-width: 110px;
  white-space: nowrap;
}

.adminTable .MuiTableSortLabel-root {
  font-family: Open Sans;
  font-size: 16px;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0em;
  min-width: 69px;
  white-space: nowrap;
}

/* filter: brightness(0) invert(1); */

.adminTableheading {
  font-family: Open Sans !important;
  font-size: 13px !important;
  font-weight: 600 !important;
  line-height: 22px !important;
  letter-spacing: 0em !important;
  /* text-align: left !important; */
}

.adminSrNoSection {
  width: 52px;
}

.loadingSection {
  width: 100px;
}

.accountName {
  height: 22px;
  width: 110px;
  left: 15px;
  top: 15px;
}

.organizationDetails {
  width: 99.5%;
  margin: 0.1%;
}

.OrgDetails-uppterStrip {
  width: 100%;
  margin-bottom: 15px;
  height: 5rem;
  display: flex;
  justify-content: space-between;
}

.OrgDetails-us-searchBar .MuiOutlinedInput-input {
  padding: 5px !important;
}

.OrgDetails-us-searchBar .MuiOutlinedInput-adornedEnd {
  padding-right: 4px !important;
}

.OrgDetails-us-searchBar .rightCont {
  width: 23%;
  height: 460px;
}

.OrgDetails-us-searchBar .MuiSvgIcon-root {
  margin-top: 3px !important;
}

.OrgDetails-bs-firstPart {
  display: flex;
  justify-content: space-between;
}

.accountText {
  font-weight: 300;
  font-size: 2rem;
}

.numberText {
  font-size: 2rem;
}

.OrgDetails-bottomStrip {
  display: flex;
  gap: 2%;
  margin-top: 1vh;
}

.OrgDetails-leftPanel {
  width: 75%;
}

.OrgDetails-rightPanel {
  width: 23%;
}

.OrgDetails-bs-firstPart-div-selected {
  width: 31%;
  background-color: #00bbdc;
  border-radius: 5px;
  height: 180px;
  cursor: pointer;
  color: white;
  transition: background-color 0.5s ease;
}

.invertedColor {
  filter: brightness(0) invert(1);
}

.OrgDetails-bs-firstPart-div {
  width: 31%;
  background-color: #f6f6f6;
  border-radius: 5px;
  height: 180px;
}

.Org-teamDetailsBox {
  background-color: #f6f6f6;
  max-height: 570px;
  overflow: hidden;
}

.Org-teamDetailsBox:hover {
  overflow: scroll;
  overflow-x: hidden;
}

/* width */
.Org-teamDetailsBox::-webkit-scrollbar {
  width: 5px;
}

/* Track */
.Org-teamDetailsBox::-webkit-scrollbar-track {
  display: none;
}

/* Handle */
.Org-teamDetailsBox::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 2.5px;
}

/* Handle on hover */
.Org-teamDetailsBox::-webkit-scrollbar-thumb:hover {
  background: #555;
}
.cursor {
  cursor: pointer;
}
.cursor-disabled {
  cursor: not-allowed;
}
.OrgTable th {
  background-color: #ebf4ff !important;
}
