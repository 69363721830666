.zoomInModalWidth {
  max-width: 1400px !important;
}

.zoomInModalGraphContainer {
  display: flex;
  width: 100%;
  height: 75vh;
  align-items: center;
  grid-gap: 10px;
  padding: 0 2rem 2rem 2rem;
  background: #ffff;
  border-radius: 10px;
  /* overflow-x: scroll; */
}

.zoomInModalGraphContainer .recharts-legend-wrapper {
  width: 100% !important;
}

.zoomInModalGraphContainer .custom-tooltip {
  padding: 15px;
}
.zoomInModalGraphContainer .custom-tooltip .custom-tooltip-label {
  font-size: 18px;
  font-weight: 700;
}
.zoomInModalGraphContainer .custom-tooltip .custom-tooltip-payload {
  font-size: 15px;
  margin-top: 5px;
  font-weight: 600;
}
.zoomInModalGraphContainer
  .custom-tooltip
  .custom-tooltip-payload
  .custom-tooltip-status {
  font-size: 15px;
  margin-top: 3px;
  font-weight: 600;
  color: black;
}

.zoomOutIcon:hover {
  transform: scale(0.9);
  transition: 0.2s linear;
}
