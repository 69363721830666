.editOuterBox {
  width: 70%;
  margin: auto;
  font: 18px;
  font-weight: 400;
}

.EditUserSelect .css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root {
  font-size: 1.3rem !important;
  width: 100%;
}
.EditUserSelect
  .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
  padding: 10px 10px !important;
}

.fixModal::-webkit-scrollbar {
  display: none !important;
}

.discardButton {
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin-left: 53%;
  width: 70px;
  height: 32px;
  font-size: 1.2rem;
  background: #f7f7f7;
  border: 1px solid #cccccc;
  border-radius: 5px;
  cursor: pointer;
}

.acceptButton-disabled {
  cursor: pointer;
  align-items: center;
  /* padding: 10px 20px; */
  gap: 10px;
  color: white;
  width: 70px;
  height: 32px;
  font-size: 1.2rem;
  border: 1px solid #6abcff;
  background: #6abcff;
  border-radius: 5px;
}
.acceptButton {
  cursor: pointer;
  align-items: center;
  /* padding: 10px 20px; */
  gap: 10px;
  color: white;
  width: 70px;
  height: 32px;
  font-size: 1.2rem;
  border: 1px solid #0078db;
  background: #0078db;
  border-radius: 5px;
}
