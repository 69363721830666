.inventoryXlsxIcon {
  margin-left: auto;
  order: 2;
  color: #666666;
  height: 100%;
  min-width: 70px;
  display: flex !important;
  justify-content: center;
  align-items: center !important;
  background-color: #fafafa;
}

.inventoryReportBox {
  display: flex;
  align-items: center;
  border: 1px solid #ccc;
  height: 60px;
  border-radius: 3px;
  overflow: hidden;
  filter: grayscale(100%);
}

.inventoryReportBox:hover {
  filter: grayscale(0%);
  cursor: pointer;
}
