.supportBanner.cmBanner{background: #3c075f; background-image: linear-gradient(to right, #16054e , #3c075f); padding: 0;}
.cmBanner .cmBImage{background: url('../../../assets/images/cfx-cm-0.png') no-repeat; background-position: left bottom; background-size: auto 100%; display: flex; align-items: center; justify-content: flex-end;}
.cmBanner .cmBannerText{ width: 55%; float: right; text-align: left; padding: 12% 20px;}
.cmBanner .cmBannerText h1{ margin-bottom: 20px;}
.cmBanner .cmBannerText h1 span{ font-size: 2.5rem;}
.cmBanner .cmBannerText p{ line-height: 3.5rem; padding: 0 2px;}

.supportBanner.riuBanner{background: #241a70; background-image: linear-gradient(to right, #16054e , #241a70); padding: 0;}
.riuBanner .riuBImage{background: url('../../../assets/images/cfx-riu-b.png') no-repeat; background-position: left center; background-size: auto 90%; display: flex; align-items: center; justify-content: flex-end;}
.riuBanner .riuBannerText{ width: 50%; float: right; text-align: left; padding: 10% 20px;}  
.riuBanner .riuBannerText h1{ margin-bottom: 20px;}
.riuBanner .riuBannerText h1 span{ font-size: 2.5rem;}
.riuBanner .riuBannerText p{ line-height: 3.5rem; padding: 0 2px;}


.cf-row{ padding: 20px;}
.cf-row:nth-child(odd){ background: #fff;}
.cf-row:nth-child(odd) .flex-container{flex-direction: row-reverse;}
.cf-row:nth-child(even){ background: #fbfbfb;}
h5{color: #3c075f;}

.featureSection{ margin: 20px auto 40px;}
.featureSection .cardsCont{ display: flex; justify-content: space-between; flex-wrap: wrap;}
.featureSection .cardsCont .cards{ display: flex; max-width: 400px; width: 31%; margin: 20px 0; padding: 0 10px;}
.featureSection .cardsCont .cards h5{ font-size: 1.8rem;}
.featureSection .cardsCont .cards p{ margin-top: 10px; font-size: 1.5rem; font-weight:300; line-height: 2.3rem;}

.featureSection .cardsCont .cards.w-50{ display: flex; max-width: 500px; width: 48% !important; margin: 20px 0; padding: 0 10px;}


.contentArea{ width: 55%; padding: 10px; display: flex; align-items: center;}
.contentArea h4{ line-height: 3.5rem;}
.contentArea h4 span:first-child{ font-size: 3rem;}
.contentArea h4 span:nth-child(2){ display: block; width: 100%; color: #333;}
.contentArea p{ margin-top: 10px; font-size: 1.5rem; font-weight: 300; line-height: 2.5rem;}
/* .contentArea button{ background: transparent; padding: 5px;} */
.cmSection{ margin: 20px auto 40px; display: flex; justify-content: center;}
.osdSection{ margin: 20px auto 40px; display: flex; justify-content: space-between;}
.ccpSection{ margin: 20px auto 40px; display: flex; justify-content: center;}
.contentImage{ width: 40%; align-items: center; display: flex;}
.contentImage img{ max-width: 400px; width: 100%; margin: 0 auto;}
.ccpSection .contentImage img{ max-width: 300px;}

@media (max-width: 920px){
    .cmBanner .cmBannerText{width: 60%;}
    .riuBanner .riuBImage{background-size: auto 70%;}
}

@media (max-width: 768px){
    .cmBanner .cmBannerText h1{ font-size: 3rem; line-height: 4rem;}
    .cmBanner .cmBannerText p{font-size: 1.7rem; line-height: 3rem;}
    .featureSection .cardsCont .cards{ width: 48%;}
    .contentArea{ width: 55%;}
    .contentArea h4 span:first-child{ font-size: 2.4rem;}
    .contentArea h4 span:nth-child(2){ font-size: 2rem;}
    .riuBanner .riuBImage{background-size: auto 100%; background-position: center center;}
    .riuBanner .riuBannerText{ width: 100%; padding: 12% 30px; background: rgba(36,26,112, 0.7);}
}

@media (max-width: 600px){
    .cmBanner .cmBannerText{ width: 100%; background: rgba(60,7,95,0.7); padding: 50px 30px;}
    .cmBanner .cmBannerText h1{ font-size: 3.4rem; line-height: 3.5rem;}
    .cmBanner .cmBannerText p{font-size: 1.5rem; line-height: 3rem;}
    .featureSection .cardsCont .cards{ width: 100%; margin: 20px auto;}
    .cmSection, .osdSection, .ccpSection{ flex-wrap: wrap;}
    .contentImage{ order: 1; width: 100%;}
    .contentImage img{ max-width: 300px; margin: 0 auto; display: block;}
    .contentArea{ order: 2; width: 100%;}
    .contentArea p{ width: 100%;}
    .supportBanner.cmBanner{min-height: 300px;} 
    .cmBanner .cmBannerText{height: 100%; display: flex; flex-direction: column; justify-content: center;}
}

@media (max-width: 480px){    
    .sectionTitle span{padding: 15px 5px;}
    .aboutBenefitsCont{padding: 20px 5px !important;}
    .aboutBenefitsCont .abPoints{margin: 10px 5px;}
}