.accordionContainer_account {
  padding: 0;
  margin: 0 !important;
  box-shadow: none !important;
}
.accordionContainer_account > div {
  margin-bottom: 5px;
  border-radius: 3px;
}
.accordionContainer_account > div::before {
  background-color: initial;
}
.accordionContainer_account .MuiAccordion-root.Mui-expanded {
  margin: 10px 0 10px;
  background: #f5faff;
  border: 1px solid #b0d2ff;
}
.accordionContainer_account .MuiAccordionSummary-root.Mui-expanded {
  min-height: auto;
}
.parentAccountCont {
  display: flex;
  align-items: center;
  width: 100%;
}
.parentAccountCont:hover {
  background: #f5faff !important;
}
.parentAccountCont .toggleRow {
  display: flex;
  width: 40px;
  text-align: center;
  padding: 5px;
  justify-content: space-between;
  align-items: center;
}
.parentAccountCont .toggleRow .toggleDD {
  margin-top: 3px;
  padding: 5px;
  display: block;
}
.parentAccountCont .rowDetail {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 1.3rem;
  cursor: default;
  padding: 5px 0;
}
.parentAccountCont .rowDetail .primaryDetails > * {
  padding: 0 8px;
}
.parentAccountCont .rowRadio {
  width: 30px;
  padding: 4px 0;
}
.parentAccountCont .rowDetail .rowName {
  width: 200px;
  max-width: 200px;
  display: table-cell;
  vertical-align: middle;
  padding: 0 8px;
}
.parentAccountCont .rowDetail .rowName span {
  display: block;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 100%;
  overflow: hidden;
}
.parentAccountCont .rowDetail .rowId {
  width: 200px;
  max-width: 200px;
  display: table-cell;
  vertical-align: middle;
  padding: 0 8px;
}
.parentAccountCont .rowDetail .rowServiceType {
  width: 100px;
  display: table-cell;
  vertical-align: middle;
  padding: 0 12px;
}
.parentAccountCont .rowDetail .rowCurrentUpdated {
  width: 80px;
  display: table-cell;
  vertical-align: middle;
  padding: 0 1px;
}
.parentAccountCont .rowDetail .rowStatus {
  width: 80px;
  text-align: center;
  display: table-cell;
  vertical-align: middle;
}
.parentAccountCont .rowDetail .rowAction {
  width: 80px;
  text-align: center;
  display: table-cell;
  vertical-align: middle;
}
.primaryDetails {
  display: table;
  width: 100%;
  align-items: center;
}
/* .rowDetail .secondaryDetails{} */
.rowDetail .secondaryDetails span {
  padding: 0;
  font-size: 1.1rem;
}
.rowDetail .secondaryDetails .rowCreatedAt {
  width: 200px;
  display: inline-block;
}
.rowDetail .secondaryDetails .rowCreatedAt b {
  font-weight: 600;
}
.rowDetail .secondaryDetails .rowUpdatedAt {
  width: 200px;
  display: inline-block;
}
.rowDetail .secondaryDetails .rowUpdatedAt b {
  font-weight: 600;
}
.accordionContainer .MuiAccordion-root.Mui-expanded {
  margin: 0;
}
.accordionContainer_account .accordionContentDesc {
  border-bottom: none;
}
.accordionContainer_account .accordionContentDesc * {
  font-size: 1.3rem;
}

.collapseTable {
  border: 1px solid #ddecff;
  background: #f7fbff !important;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
}

.collapseTable td {
  padding: 10px;
}
.collapseTable table {
  border-radius: 5px !important;
}

.collapseTableHeader {
  margin: 10px;
  overflow: hidden;
  border: 1px solid #ccc;
  padding: 4px;
  background: rgba(250, 246, 246, 0.979);
}

.tableHeader {
  border: 1px solid #ddecff;
  background: #ebf4ff !important;
  text-transform: uppercase;
  padding: 5px 0;
  font-size: 1.2rem;
}
thead .tableRow:hover {
  background: #f3f3f3 !important;
}

.tableRow {
  border: 1px solid #ddecff;
  padding: 5px 0 0 5px;
  background: rgb(255, 255, 255);
  border-collapse: separate;
  border-spacing: 0 10px;
}
.tableRow * {
  text-transform: initial;
}
.tableRowOpen {
  background: #eee !important;
}

.tableRow:hover {
  background: #f7fbff !important;
}

.tableRow .rowName {
  width: 200px;
  max-width: 200px;
  display: table-cell;
  vertical-align: middle;
  padding: 0 2px 0px 5px;
  font-size: 1.3rem;
}

.tableRow .rowId {
  width: 250px;
  max-width: 250px;
  display: table-cell;
  vertical-align: middle;
  padding: 0 8px;
  font-size: 1.3rem;
}

.tableRow .rowServiceType {
  width: 100px;
  display: table-cell;
  vertical-align: middle;
  padding: 0 12px;
  font-size: 1.3rem;
}
.tableRow .rowCurrentUpdated {
  width: 80px;
  display: table-cell;
  vertical-align: middle;
  padding: 5px 1px;
  font-size: 1.3rem;
}
.tableRow .rowStatus {
  width: 80px;
  text-align: center;
  display: table-cell;
  vertical-align: middle;
  font-size: 1.3rem;
}
.tableRow .rowAction {
  width: 80px;
  text-align: center;
  display: table-cell;
  vertical-align: middle;
  font-size: 1.3rem;
}

.tableRow .rowRadio {
  width: 60px;
  padding: 4px 0;
}

.parentAccountCont.tableHead {
  border: 1px solid #ddecff;
  background: #ebf4ff !important;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  text-transform: uppercase;
  padding: 5px 0;
}
.parentAccountCont.tableHead .rowDetail {
  font-size: 1.2rem;
  padding: 8px 0;
}
.parentAccountCont.tableHead.subscriptionCont {
  background: none;
  border: none;
  padding: 0;
}
.accordionContainer_account .subscriptionTable {
  margin: 10px;
  border-radius: 3px;
  overflow: hidden;
  border: 1px solid #ccc;
  padding: 0;
  background: #fff;
}
.accordionContainer_account .subscriptionTable .parentAccountCont {
  border-bottom: 1px dotted #eee;
}
.accordionContainer_account .subscriptionTable .tableHead {
  background: #f3f3f3 !important;
}
/* .accordionContainer_account .subscriptionTable .parentAccountCont:nth-child(even){ background: #f8f8f8;} */
.selectedRow {
  color: #0066ba !important;
  font-weight: 600;
  min-height: auto !important;
}
.selectedRow > div {
  margin: 0 !important;
}
.accordionContainer_account .styleRow {
  min-height: auto;
}
.styleRow > div {
  margin: 0 !important;
}
.accOuter {
  box-shadow: none !important;
  border: 1px solid #eee;
}
.tabCont {
  display: inline-flex !important;
  background: #ebf4ff;
  border-radius: 3px;
}
.tabCont button {
  font-weight: 600;
}
.emptyRow {
  font-size: 18px;
  color: #666;
  text-align: center;
  padding: 20px 0;
}

.accountsTable tfoot p {
  font-size: 13px;
}

@media (max-width: 960px) {
  .primaryDetails {
    display: flex;
    flex-wrap: wrap;
  }
}
