.NMSHeading {
  color: #0078db;
  font-weight: 400;
  font-size: 2.4rem;
}
.borderRight {
  border-right: 2px solid #666666;
}
.slack {
  font-size: 1.1vw;
  font-weight: 600;
}
.flex {
  display: flex;
}
.outerSlackTextBox {
  display: flex;
  justify-content: space-between;
}
.flex-end {
  align-items: flex-end;
}

.companyName {
  font-weight: 400;
  font-size: 1.3rem;
  margin-top: 9px;
}

.slackInfoText {
  font-size: 1.5vw;
  font-weight: 600;
  margin: 0 0 2% 0.8%;
}

.slackInputBox {
  width: 100%;
}
.slackInputBox .fa-envelope,
.slackInputBox .fa-address-card,
.slackInputBox .fa-building,
.slackInputBox .fa-phone-alt {
  font-size: 1.1rem;
  color: #1583dc;
}
.slackInputBox input {
  padding: 10px;
  font-size: 1.1rem;
  letter-spacing: inherit;
  font-family: 'Open Sans', sans-serif !important;
}
.slackInputBox label {
  font-size: 1rem;
  top: -8px !important;
}
.submitBtn {
  margin-left: 92.5%;
  margin-top: 1%;
}

.slackInputHeading {
  display: flex;
  justify-content: space-between;
}
.cross {
  cursor: pointer;
}
.red {
  /* color: red; */
}

.align-right {
  display: flex;
  align-items: flex-end;
}

.companyNameOuterCont {
  display: grid;
  grid-template-columns: 48% 48%;
  grid-gap: 1%;
}
.slackChannelOuterCont {
  width: 100%;
  display: flex;
  justify-content: space-between;
  border: 1px solid #bbcec5;
  margin-bottom: 20px;

  background-color: #f1fff8;
}
.slackChannelName {
  padding: 16px 0 0 10px;
  font-weight: 500;
  width: 90%;
}
.notificationModalTitle {
  display: inline-block;
  text-transform: uppercase;
  font-size: 1.5rem;
}
.notificationBox {
  margin-top: 4%;
}
.nmsTable {
  width: 100% !important;
}
.notificationText {
  font-size: 2.4rem;
  margin: 5%;
  font-weight: 500;
  color: gray;
}

.notificationType {
  margin-left: 50%;
}

.submitBtn {
  display: flex;
  align-items: flex-end;
}

.heading {
  font-size: 1.3vw !important;
}

.resourceNames {
  font-size: 1.2vw !important;
  line-height: 15px !important;
}

.NMSTable .MuiTableCell-head {
  padding: 15px 0 15px 10px !important;
}
.NMSTable .MuiTableCell-sizeSmall {
  padding: 5px 0 0 10px;
}
.backgroundColor {
  background-color: #fafdff;
}

.disabledFont {
  color: #9e9b9b !important;
}
.disabledCheckbox {
  width: 15px;
  height: 15px;
  background-color: #e7e6e6;
  border: 1px solid #646464;
  border-radius: 2px;
  margin-left: 3.5%;
}

.tooltipFont {
  font-size: 12px !important;
  text-align: left;
  font-weight: 500;
}

.renderValue {
  font-size: 0.8rem;
  width: 100%;
  border: 1px solid black;
}
.MuiChip-sizeSmall {
  padding: 0px !important;
  border-radius: 2px !important;
  border: 1px solid #eaeaea !important;
  background-color: #f7f7f7 !important;
  margin-right: 1% !important;
}
.MuiChip-labelSmall {
  font-size: 8px !important;
  margin: 0px !important;
}
.extraFields {
  width: 22px;
  height: 22px;
  /* max-width: 40px; */
  margin-top: 160% !important;
  margin-left: 40%;
  border-radius: 2px;
  padding-top: 35%;
  padding-left: 10%;
  color: white;
  font-size: 10px !important;
  background-color: #0078db !important;
}

/* .MuiInputBase-input {
  padding: 10px 10px 5px 10px !important;
} */

/* .MuiSelect-icon {
  margin-top: 5px !important;
  margin-right: 5px !important;
} */

.selectOuter {
  display: grid;
  grid-template-columns: 90% 10%;
}

.sslRecommBox {
  letter-spacing: 1px;
  font-weight: 600;
}
