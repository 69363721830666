.URLcontainer {
  display: flex;
  /* flex-direction: row; */
  gap: 16px;
}

.URLcontainer > div {
  padding: 20px 16px 16px 16px;
  border-radius: 8px;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.2);
}

.URLcontainer > div:nth-child(2) {
  padding: 0 16px 16px 16px;
}

.URLcontainer > div:nth-child(2) {
  background-color: #f3f3f3;
  max-height: 88vh;
}

.URLMonitoringTable .MuiTableBody-root {
  background-color: #ffff;
}

.searchContainer {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  justify-content: space-between;
}

.searchContainer .MuiOutlinedInput-root {
  border-radius: 20px;
}
.searchContainer .MuiOutlinedInput-input {
  height: 0em;
  font-family: Open Sans;
  font-size: 13px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;
  padding: 26px 14px 20.5px 14px;
}

.searchContainer .MuiInputLabel-root {
  font-family: Open Sans;
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
}

.searchContainer .MuiButton-startIcon {
  margin-right: 3px;
}

.searchContainer .MuiButton-root {
  font-family: Inter;
  font-size: 14px;
  font-weight: 300;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
  width: 7rem;
  margin-left: 1rem;
  margin-right: 2px;
}

.searchContainer .MuiIconButton-root {
  padding: 6px;
}

.selectEnvironmentBox .MuiSelect-outlined {
  padding: 26px 14px 2.5px 14px !important;
}

/* .searchContainer > * {
  margin-right: 8px;
} */

.accordionContainer {
  margin-top: 16px;
  overflow: auto;
  max-height: 69%;
}

.accordionContainer .MuiTypography-root {
  font-family: Open Sans !important;
  font-size: 16px !important;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
}

.noDataMessage {
  padding: 8px;
  background-color: rgba(243, 243, 243, 1);
  border-radius: 8px;
}

.accordionContainer .MuiAccordion-rounded.Mui-expanded {
  background: rgba(243, 243, 243, 1) !important;
}

.tab-wrapper {
  background-color: rgba(255, 255, 255, 1);
  margin-bottom: 1.5rem;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  overflow: hidden;
  width: 100%;
}

.tab-wrapper .MuiTab-wrapper {
  font-weight: 600;
}

.tab {
  /* color: white; */
  transition: background-color 0.3s ease;
}

.tab.Mui-selected {
  background-color: #0078db;
  width: 60%;

  color: #ffff;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
}

.popperSection {
  border: 1px solid #d0cccc;
  padding: 15px;
  border-radius: 5px;
  display: flex;
}

.maintenanceURL {
  opacity: 64% !important;
}

.accDetailSection > div:nth-child(1) {
  width: 57%;
}

.accordionSummary svg {
  font-size: 2rem;
}

.accordionSummary .MuiAccordionSummary-expandIconWrapper:hover {
  background-color: #f1f1f1;
  border-radius: 50%;
}

.hoverableIcon .MuiSvgIcon-root:hover {
  color: #1976d2;
}

.accordionSummary .MuiAccordionSummary-content,
.accordionSummary .MuiAccordionSummary-content.Mui-expanded {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-right: 15px;
  width: 85%;
}

.accFriendlyName {
  width: 49%;
  /* text-align: left; */

  font-family: Open Sans;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
}

.accFriendlyName:hover,
.regionName:hover {
  color: #0078db;
  font-weight: 500;
  transition: 0.3s ease-out;
  cursor: pointer;
}

.activeRegion {
  color: #0078db;
}

.urlName {
  margin-left: 17%;
  font-family: Open Sans;
  font-size: 13px;
  margin-top: -7%;
  margin-bottom: 5%;
  font-weight: 300;
}

.urlName:hover {
  color: #1976d2;
  cursor: pointer;
}

.UrlregionCount {
  background: rgba(44, 178, 44, 1);
  padding: 0.1rem 1rem 0.1rem 1rem;
  border-radius: 50px;
  color: rgba(255, 255, 255, 1) !important;
}

.UrlDownTimeCount {
  font-weight: 700 !important;
  border: 1px solid rgba(234, 67, 53, 1);
  border-radius: 19px;
  padding: 0 1rem;
  color: rgba(234, 67, 53, 1) !important;
}

.UrlAccRegionDetail {
  list-style-type: none;
}

.UrlAccRegionDetail > li {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 2rem;
}

.UrlAccRegionDetail > li:nth-child(1) {
  margin-top: 0;
}

.UrlAccRegionDetail > li > div:nth-child(1) {
  margin-left: 21%;
  font-family: Open Sans;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
}

.deactiveURL {
  background: rgba(220, 53, 69, 1);
  width: 1rem;
  border-radius: 50%;
  height: 1rem;
}
.activeURL {
  background: rgba(44, 178, 44, 1);
  width: 1rem;
  border-radius: 50%;
  height: 1rem;
}

.maintenancedotURL {
  background: rgba(217, 147, 54, 1);
  width: 1rem;
  border-radius: 50%;
  height: 1rem;
}

.quickStatusHeader {
  justify-content: space-between;
  align-items: center;
}

.quickStatusHeader .MuiButton-outlinedPrimary {
  height: 3.5rem;
  margin-right: 1rem;
}

.quickStatsBox {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
  color: rgba(255, 255, 255, 1);
  padding: 1rem;
  border-radius: 10px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
    rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
  /* box-shadow: 7px 6px 28px 1px rgba(66, 66, 66, 0.24); */
}

.uptimeBox {
  background: rgba(44, 178, 44, 1);
}

.uptimeBox:hover {
  background: rgb(36, 148, 36);
}

.uptimeBox:active,
.downTimeBox:active,
.maintenanceBox:active,
.pauseBox:active {
  transform: scale(0.98);
  /* box-shadow: 3px 2px 22px 1px rgba(0, 0, 0, 0.24); */
  box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset,
    rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset;
}

.uptimeBox:hover,
.downTimeBox:hover,
.maintenanceBox:hover,
.pauseBox:hover {
  transition: 0.2s ease-in-out;
  cursor: pointer;
}

.downTimeBox {
  background: rgba(234, 67, 53, 1);
}

.downTimeBox:hover {
  background: rgb(206, 59, 45);
}

.maintenanceBox {
  background: rgba(217, 147, 54, 1);
}
.maintenanceBox:hover {
  background: rgb(194, 131, 48);
}

.mixedUrlStatus {
  background: rgba(255, 193, 7, 1);
}
.connectingBox {
  background: #ffe567;
}
.pauseBox {
  background: rgba(58, 130, 223, 1);
}

.pauseBox:hover {
  background: rgb(53, 118, 203);
}

.quickStatsBox svg {
  margin-bottom: 2rem;
}

.quickStatsBox span {
  font-size: 0.8rem;
}

.quickStatsBox > div {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-right: 0.5rem;
  margin-bottom: 1rem;
}

.quickStatsBox > div > p:nth-child(1) {
  font-family: Open Sans;
  font-size: 40px;
  font-weight: 600;
  line-height: 54px;
  letter-spacing: 0em;
  /* text-align: left; */
}

.quickStatsBox > div > p:nth-child(2) {
  font-family: Open Sans;
  font-size: 20px;
  font-weight: 400;
  line-height: 27px;
  letter-spacing: 0em;
  /* text-align: right; */
}

.tableContainer {
  margin-top: 16px;
}

.firstURLSection {
  width: 25%;
  height: 88vh;
}

.secondURLSection {
  width: 75%;
  max-height: 93vh;
}

.urlStatus > div {
  text-align: center;
  padding: 1px 10px 1px 10px;
  border-radius: 50px;
  color: rgba(255, 255, 255, 1);
  font-family: Open Sans;
  font-size: 14px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0em;
  width: 68px;
}

.urlConnecting {
  background: #ffe567;
  width: fit-content !important;
}

.urlUp {
  background: rgba(44, 178, 44, 1);
}

.urlMaintenance {
  background: rgba(217, 147, 54, 1);
  width: fit-content !important;
}

.urlDown {
  background: rgba(220, 53, 69, 1);
}

.urlTableContainer {
  overflow-y: auto;
  max-height: 60%;
}
.urlRegionSection {
  display: block;
}

@media (min-width: 1180px) {
  .urlTableContainer {
    max-height: 53%;
  }
  .accordionContainer {
    max-height: 58%;
  }
}

@media (min-width: 1430px) {
  .accordionContainer {
    max-height: 69%;
  }
}

@media (min-width: 1440px) {
  .urlTableContainer {
    max-height: 63%;
  }
  .urlRegionSection {
    display: inline;
  }
}

@media (min-width: 1800px) {
  .urlTableContainer {
    max-height: 72%;
  }
}
