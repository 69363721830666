.table {
  min-width: 650px;
  border: 1px solid var(--border, rgba(223, 223, 235, 1));
}
.table th.MuiTableCell-root.MuiTableCell-body.MuiTableCell-sizeSmall {
  padding: 0.6rem 0.9rem 0.6rem 0.9rem;
  border: 1px solid var(--border, rgba(223, 223, 235, 1));
}
.table th {
  font-size: 1.2rem;
  border: 1px solid var(--border, rgba(223, 223, 235, 1));
}
.table td {
  font-size: 1.2rem;
  border: 1px solid var(--border, rgba(223, 223, 235, 1));
}
/* .table .MuiTableRow-root.Mui-selected, .MuiTableRow-root.Mui-selected:hover{ background-color: #ccc;} */
.table .MuiTableCell-sizeSmall:first-child {
  text-align: left;
}
.table .MuiTableCell-sizeSmall {
  padding: 1.2rem 0.8rem;
  min-width: 90px;
  word-break: break-word;
}
.table .fa-wrench {
  margin-bottom: 0.5rem;
  font-size: 1.6rem;
}
.table .guageChart {
  width: 45px !important;
  margin: 0 auto;
}
.table .guageChartText {
  text-align: center;
  position: relative;
  top: -5px;
  font-size: 1.1rem;
}
.table .actionIcons {
  font-size: 1.8rem;
  margin: 3px;
}
.table .disabledIcons {
  cursor: default;
}
.table .actionIcons.fa-wrench {
  width: 1.4rem;
  height: 1.4rem;
}
.tablePaginaion * {
  font-size: 1.2rem !important;
}
.tablePaginaion .MuiTablePagination-selectIcon {
  top: 7px;
}
.tablePaginaion .MuiTablePagination-select {
  padding-left: 5px;
  padding-right: 18px;
  border: 1px solid #ccc;
  border-radius: 3px;
}
.table .float-r {
  float: right;
}
.configIconRS {
  background: url('../../assets/images/icons.png') !important;
  background-position: 0 0 !important;
  background-size: 265px !important;
  padding: 0 !important;
  align-self: center;
  margin: 3px;
  min-width: 1.8rem !important;
  min-height: 1.8rem !important;
  opacity: 0.9;
  align-self: inherit;
}
.notificationIcon {
  cursor: pointer;
  margin-right: 5px;
}
.rsTableHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 0 5px 5px;
  text-transform: capitalize;
  margin-bottom: 10px;
}
.tableHead {
  background: #ebf4ff;
  border-bottom: 1px solid #cfe5ff;
}
.rsTableHeader .tabRightsizingHeading {
  min-height: auto;
}
.tabRightsizingHeading .MuiTabs-indicator {
  display: none;
}
.tabRightsizingHeading button {
  padding: 0 15px;
  min-width: auto;
  font-weight: 300;
  min-height: auto;
  line-height: inherit;
  max-width: initial;
}
.tabRightsizingHeading button.MuiTab-textColorPrimary.Mui-selected {
  font-weight: 300;
}
.tabRightsizingHeading button:nth-child(1) {
  padding-left: 0;
}
.tabRightsizingHeading button.prevGenTab {
  border-left: 1px solid #ccc;
  border-right: 1px solid #ccc;
}
.tabRightsizingHeading button.RDSRightsizing {
  border-left: 1px solid #ccc;
}
.tabRightsizingHeading button span {
  font-size: 24px;
  text-transform: capitalize;
}

@media (max-device-width: 767px) {
  .tabRightsizingHeading button:first-child + .notificationIcon {
    position: absolute;
    right: 5px;
    top: 6px;
  }
  .tabRightsizingHeading button {
    width: 100%;
  }
}

.SingleDatePicker_picker {
  top: 40px !important;
}
