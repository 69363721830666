.AddOrgBody {
  width: 100%;
  font-size: 25px;
}
.AddOrgBody label {
  font-size: 16px;
  margin-top: 3px !important;
}
.AddOrgBody .css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root {
  height: 60px;
}
.AddOrgBody .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input {
  font-size: 16px !important;
}
