.shareDoc-fixModal {
  max-width: 800px !important;
}

.shareDocOuterBox {
  width: 100%;
  height: 350px;
  overflow-y: scroll;
}

.modalTitle-shareDocModal {
  margin-right: 6%;
  display: inline-block;
}

.shareDoc-documentName {
  font-size: 15px !important;
}
