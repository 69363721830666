.UrlDetailContainer {
  height: 86vh;
  overflow-y: auto;
  position: relative;
}

.UrlDetailContainer::-webkit-scrollbar {
  width: 0; /* Hide scrollbar */
}

.UrlHeaderBox {
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1;
  background: #f3f3f3;
  padding-bottom: 2.5rem;
  /* margin-top: 5px; */
}

.detailPageTitleBox {
  align-items: baseline;
  width: 80%;
}

.detailPageTitleBox .MuiOutlinedInput-notchedOutline,
.detailPageTitleBox
  .MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root.Mui-focused
  .MuiOutlinedInput-notchedOutline {
  border-color: transparent !important;
}

.detailPageTitleBox > div > div:nth-child(1) {
  font-size: 24px;
  font-weight: 400;
  line-height: 33px;
  letter-spacing: 0em;
  text-align: left;
}

.detailPageTitleBox .MuiSelect-select {
  background: #ffff;
}

.detailPageTitleBox .MuiInputBase-root {
  height: 4rem;
}

.rightDetailButtons {
  width: 28rem;
  display: flex;
  justify-content: space-between;
  height: 5rem;
  margin-top: 1.5rem;
}

.rightDetailButtons p {
  font-size: 14px !important;
}
.rightDetailButtons svg {
  font-size: 19px;
  margin-right: 0.5rem;
  color: black;
}

.rightDetailButtons > button:nth-child(3),
.rightDetailButtons > button:nth-child(3) .MuiTypography-root,
.rightDetailButtons > button:nth-child(3) svg {
  color: red !important;
}

.detailPageTitleBox > div > div:nth-child(2) {
  color: rgba(58, 130, 223, 1);
  max-width: 19rem;
  line-break: anywhere;
}

.detailPageURL {
  cursor: pointer;
}
.detailPageURL:hover {
  color: rgb(10, 83, 179) !important;
}

.UrlStatsContainer {
  display: flex;
  width: 100%;
  justify-content: space-between;
  grid-gap: 10px;
  margin: 1.5rem 0 1.5rem 0;
  padding: 2rem;
  background: #ffff;
  border-radius: 10px;
}

.UrlStatsSubsection {
  display: flex;
  flex-direction: column;
  align-items: center;
  grid-gap: 5px;
}

.UrlStatsSubsection > p:nth-child(1) {
  font-family: Inter;
  font-size: 20px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
}

.UrlStatsSubsection > p:nth-child(2) {
  font-family: Inter;
  font-size: 12px;
  font-weight: 400;
  line-height: 15px;
  letter-spacing: 0em;
  text-align: left;
  color: rgba(154, 154, 154, 1);
}

.UrlStatsSubsection > p:nth-child(3) {
  font-family: Inter;
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;
}

.loading {
  text-align: center;
  animation: loading 1.2s ease-in-out infinite;
  font-size: 30px !important;
}

.regionSortHeader {
  cursor: pointer;
}

.regionSortHeader:hover {
  background: #b3d0f2 !important;
  color: wheat !important;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  transition: 0.2s ease-in-out;
}

.regionSortHeader:active {
  box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset,
    rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset !important;
  transform: scale(0.98);
}

.helperText {
  font-size: 1rem;
  line-height: 1.66;
  letter-spacing: 0.03333em;
  color: red;
  margin-top: 8px;
  margin-left: 14px;
  margin-bottom: -1rem;
  /* position: absolute; */
}

.dateInputBoxWrapper {
  position: relative;
}

/* .dateInputBoxWrapper .MuiInputBase-root {
  width: 100%;
} */

.dateInputBoxWrapper .MuiFormHelperText-root {
  position: absolute;
  bottom: -20px;
  left: 14px;
  font-size: 1rem;
  line-height: 1.66;
  letter-spacing: 0.03333em;
  color: red;
}

.datePickerContainer {
  margin-left: 2rem;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  align-items: baseline;
  gap: 1.5rem;
}

.dateInputBox {
  /* width: 300px; */
  padding: 10px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border: 1px solid #ccc;
  background: #ffff;
  cursor: pointer;
}

@keyframes loading {
  0% {
    color: #e9e9e9;
  }
  50% {
    color: #b89b84;
  }
  100% {
    color: #e9e9e9;
  }
}
.GraphContainer {
  /* display: flex; */
  width: 100%;
  height: 435px;
  align-items: center;
  grid-gap: 10px;
  margin: 1.5rem 0 1.5rem 0;
  padding: 2rem;
  background: #ffff;
  border-radius: 10px;
  overflow-x: clip;
  position: relative;
}

.GraphContainer .recharts-legend-wrapper {
  width: 100% !important;
}

.GraphContainer .custom-tooltip {
  padding: 15px;
}
.GraphContainer .custom-tooltip .custom-tooltip-label {
  font-size: 18px;
  font-weight: 700;
}
.GraphContainer .custom-tooltip .custom-tooltip-payload {
  font-size: 15px;
  margin-top: 5px;
  font-weight: 600;
}
.GraphContainer .custom-tooltip .custom-tooltip-payload .custom-tooltip-status {
  font-size: 15px;
  margin-top: 3px;
  font-weight: 600;
  color: black;
}

.zoomInIcon:hover {
  transform: scale(1.1);
  transition: 0.2s linear;
}

.urlDropDownBtn svg {
  font-size: 2.2rem;
}

.urlDropDownBtn .MuiInputLabel-outlined.MuiInputLabel-shrink {
  top: -10px !important;
  font-size: 1.4rem !important;
}

.urlDropDownBtn .MuiSelect-outlined.MuiSelect-outlined {
  padding: 11.5px 14px;
}

.durationHeader {
  font-size: 14px !important;
  font-weight: 600;
  line-break: 1.2px;
  letter-spacing: 0.1rem;
}

.toggleBtnGroup .MuiToggleButtonGroup-root {
  display: flex;
  justify-content: center;
  margin: 1rem 0;
}

.toggleBtnGroup .MuiToggleButton-root {
  color: gray;
  border: 1px solid #ccc;
  font-size: 1.3rem;
  font-weight: 500;
  padding: 0.5rem 1rem;
  text-transform: none;
}

.toggleBtnGroup .Mui-selected {
  background-color: #0078db;
  color: white;
}

.toggleBtnGroup .MuiToggleButtonGroup-grouped:not(:first-of-type) {
  border-left: 1px solid #ccc !important;
}

/* .GraphContainer {
  position: relative;
} */
