@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  font-size: 10px;
}

@import url('https://cdn.jsdelivr.net/npm/bootstrap-icons@1.5.0/font/bootstrap-icons.css');
body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
span,
ul,
li {
  margin: 0;
  padding: 0;
  font-family: 'Open Sans', sans-serif !important;
  font-size: 1.3rem;
}
body {
  overflow-x: hidden;
}

.flip-horizontal {
  -moz-transform: scaleX(-1);
  -webkit-transform: scaleX(-1);
  -o-transform: scaleX(-1);
  transform: scaleX(-1);
  -ms-filter: fliph;
  filter: fliph;
}
.text-dBlue {
  color: #132b8f !important;
}
.text-primary {
  color: #006ac3 !important;
}
.text-pink {
  color: #b45ac3 !important;
}
.text-orange {
  color: #ca1900 !important;
}

/* .btn{cursor: pointer; font-family: 'Open Sans', sans-serif !important; padding: 0.6rem 3rem; border-radius: 2px; border:none;} */
.btnDisabled {
  cursor: default;
}

/* .btn-primary{ background: #006ac3; color: #fff;}
.btn-primary:hover{ background: #0062b3;}

.btn-outline-primary{ background: #fff; color: #006ac3; border:1px solid #006ac3;}
.btn-outline-primary:hover{ background: #006ac3; color: #fff;}

.btn-outline-secondary{ background: #fff; color: #b45ac3; border:1px solid #b45ac3;}
.btn-outline-secondary:hover{ background: #b45ac3; color: #fff;} */

.btn {
  display: inline-block;
  font-weight: 400;
  color: #212529;
  text-align: center;
  height: fit-content;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.5rem 1.5rem;
  font-size: 1.3rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  -webkit-transition: color 0.15s ease-in-out,
    background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
}

.btn:hover {
  color: #212529;
  text-decoration: none;
}

.btn:focus,
.btn.focus {
  outline: 0;
  -webkit-box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.btn.disabled,
.btn:disabled {
  opacity: 0.65;
}

a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none;
}

/* .btn-primary {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
} */

.btn-primary:hover {
  color: #fff;
  background-color: #0069d9;
  border-color: #0062cc;
}

.btn-primary:focus,
.btn-primary.focus {
  color: #fff;
  background-color: #0069d9;
  border-color: #0062cc;
  -webkit-box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);
}

.btn-primary.disabled,
.btn-primary:disabled {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
  pointer-events: none;
}

.btn-primary:not(:disabled):not(.disabled):active,
.btn-primary:not(:disabled):not(.disabled).active,
.show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #0062cc;
  border-color: #005cbf;
}

.btn-primary:not(:disabled):not(.disabled):active:focus,
.btn-primary:not(:disabled):not(.disabled).active:focus,
.show > .btn-primary.dropdown-toggle:focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);
}

.btn-secondary {
  color: #48749b;
  background-color: #fff;
  border-color: #48749b;
}

.btn-secondary:hover {
  color: #fff;
  background-color: #3c6081;
  border-color: #385a78;
}

.btn-secondary:focus,
.btn-secondary.focus {
  color: #fff;
  background-color: #3c6081;
  border-color: #385a78;
  -webkit-box-shadow: 0 0 0 0.2rem rgba(99, 137, 170, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(99, 137, 170, 0.5);
}

.btn-secondary.disabled,
.btn-secondary:disabled {
  color: #fff;
  background-color: #48749b;
  border-color: #48749b;
  pointer-events: none;
}

.btn-secondary:not(:disabled):not(.disabled):active,
.btn-secondary:not(:disabled):not(.disabled).active,
.show > .btn-secondary.dropdown-toggle {
  color: #fff;
  background-color: #385a78;
  border-color: #34536f;
}

.btn-secondary:not(:disabled):not(.disabled):active:focus,
.btn-secondary:not(:disabled):not(.disabled).active:focus,
.show > .btn-secondary.dropdown-toggle:focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(99, 137, 170, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(99, 137, 170, 0.5);
}

.btn-success {
  color: #fff;
  background-color: #18a957;
  border-color: #18a957;
}

.btn-success:hover {
  color: #fff;
  background-color: #138846;
  border-color: #127c40;
}

.btn-success:focus,
.btn-success.focus {
  color: #fff;
  background-color: #138846;
  border-color: #127c40;
  -webkit-box-shadow: 0 0 0 0.2rem rgba(59, 182, 112, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(59, 182, 112, 0.5);
}

.btn-success.disabled,
.btn-success:disabled {
  color: #fff;
  background-color: #18a957;
  border-color: #18a957;
  pointer-events: none;
}

.btn-success:not(:disabled):not(.disabled):active,
.btn-success:not(:disabled):not(.disabled).active,
.show > .btn-success.dropdown-toggle {
  color: #fff;
  background-color: #127c40;
  border-color: #10713a;
}

.btn-success:not(:disabled):not(.disabled):active:focus,
.btn-success:not(:disabled):not(.disabled).active:focus,
.show > .btn-success.dropdown-toggle:focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(59, 182, 112, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(59, 182, 112, 0.5);
}

.btn-info {
  color: #212529;
  background-color: #36bced;
  border-color: #36bced;
}

.btn-info:hover {
  color: #fff;
  background-color: #15afe8;
  border-color: #14a7dc;
}

.btn-info:focus,
.btn-info.focus {
  color: #fff;
  background-color: #15afe8;
  border-color: #14a7dc;
  -webkit-box-shadow: 0 0 0 0.2rem rgba(51, 165, 208, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(51, 165, 208, 0.5);
}

.btn-info.disabled,
.btn-info:disabled {
  color: #212529;
  background-color: #36bced;
  border-color: #36bced;
  pointer-events: none;
}

.btn-info:not(:disabled):not(.disabled):active,
.btn-info:not(:disabled):not(.disabled).active,
.show > .btn-info.dropdown-toggle {
  color: #fff;
  background-color: #14a7dc;
  border-color: #139ed1;
}

.btn-info:not(:disabled):not(.disabled):active:focus,
.btn-info:not(:disabled):not(.disabled).active:focus,
.show > .btn-info.dropdown-toggle:focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(51, 165, 208, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(51, 165, 208, 0.5);
}

.btn-warning {
  color: #212529;
  background-color: #ffbb38;
  border-color: #ffbb38;
}

.btn-warning:hover {
  color: #212529;
  background-color: #ffae12;
  border-color: #ffaa05;
}

.btn-warning:focus,
.btn-warning.focus {
  color: #212529;
  background-color: #ffae12;
  border-color: #ffaa05;
  -webkit-box-shadow: 0 0 0 0.2rem rgba(222, 165, 54, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(222, 165, 54, 0.5);
}

.btn-warning.disabled,
.btn-warning:disabled {
  color: #212529;
  background-color: #ffbb38;
  border-color: #ffbb38;
  pointer-events: none;
}

.btn-warning:not(:disabled):not(.disabled):active,
.btn-warning:not(:disabled):not(.disabled).active,
.show > .btn-warning.dropdown-toggle {
  color: #212529;
  background-color: #ffaa05;
  border-color: #f7a300;
}

.btn-warning:not(:disabled):not(.disabled):active:focus,
.btn-warning:not(:disabled):not(.disabled).active:focus,
.show > .btn-warning.dropdown-toggle:focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(222, 165, 54, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(222, 165, 54, 0.5);
}

.btn-danger {
  color: #fff;
  background-color: #de0d26;
  border-color: #de0d26;
}

.btn-danger:hover {
  color: #fff;
  background-color: #ba0b20;
  border-color: #ae0a1e;
}

.btn-danger:focus,
.btn-danger.focus {
  color: #fff;
  background-color: #ba0b20;
  border-color: #ae0a1e;
  -webkit-box-shadow: 0 0 0 0.2rem rgba(227, 49, 71, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(227, 49, 71, 0.5);
}

.btn-danger.disabled,
.btn-danger:disabled {
  color: #fff;
  background-color: #de0d26;
  border-color: #de0d26;
}

.btn-danger:not(:disabled):not(.disabled):active,
.btn-danger:not(:disabled):not(.disabled).active,
.show > .btn-danger.dropdown-toggle {
  color: #fff;
  background-color: #ae0a1e;
  border-color: #a2091c;
}

.btn-danger:not(:disabled):not(.disabled):active:focus,
.btn-danger:not(:disabled):not(.disabled).active:focus,
.show > .btn-danger.dropdown-toggle:focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(227, 49, 71, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(227, 49, 71, 0.5);
}

.btn-light {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-light:hover {
  color: #212529;
  background-color: #e2e6ea;
  border-color: #dae0e5;
}

.btn-light:focus,
.btn-light.focus {
  color: #212529;
  background-color: #e2e6ea;
  border-color: #dae0e5;
  -webkit-box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5);
}

.btn-light.disabled,
.btn-light:disabled {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-light:not(:disabled):not(.disabled):active,
.btn-light:not(:disabled):not(.disabled).active,
.show > .btn-light.dropdown-toggle {
  color: #212529;
  background-color: #dae0e5;
  border-color: #d3d9df;
}

.btn-light:not(:disabled):not(.disabled):active:focus,
.btn-light:not(:disabled):not(.disabled).active:focus,
.show > .btn-light.dropdown-toggle:focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5);
}

.btn-dark {
  color: #fff;
  background-color: #3d4956;
  border-color: #3d4956;
}

.btn-dark:hover {
  color: #fff;
  background-color: #2d3640;
  border-color: #283038;
}

.btn-dark:focus,
.btn-dark.focus {
  color: #fff;
  background-color: #2d3640;
  border-color: #283038;
  -webkit-box-shadow: 0 0 0 0.2rem rgba(90, 100, 111, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(90, 100, 111, 0.5);
}

.btn-dark.disabled,
.btn-dark:disabled {
  color: #fff;
  background-color: #3d4956;
  border-color: #3d4956;
}

.btn-dark:not(:disabled):not(.disabled):active,
.btn-dark:not(:disabled):not(.disabled).active,
.show > .btn-dark.dropdown-toggle {
  color: #fff;
  background-color: #283038;
  border-color: #232931;
}

.btn-dark:not(:disabled):not(.disabled):active:focus,
.btn-dark:not(:disabled):not(.disabled).active:focus,
.show > .btn-dark.dropdown-toggle:focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(90, 100, 111, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(90, 100, 111, 0.5);
}

.btn-outline-primary {
  color: #007bff;
  border-color: #007bff;
}

.btn-outline-primary:hover {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}

.btn-outline-primary:focus,
.btn-outline-primary.focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
}

.btn-outline-primary.disabled,
.btn-outline-primary:disabled {
  color: #007bff;
  background-color: transparent;
  pointer-events: none;
}

.btn-outline-primary:not(:disabled):not(.disabled):active,
.btn-outline-primary:not(:disabled):not(.disabled).active,
.show > .btn-outline-primary.dropdown-toggle {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}

.btn-outline-primary:not(:disabled):not(.disabled):active:focus,
.btn-outline-primary:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-primary.dropdown-toggle:focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
}

.btn-outline-secondary {
  color: #48749b;
  border-color: #48749b;
}

.btn-outline-secondary:hover {
  color: #fff;
  background-color: #48749b;
  border-color: #48749b;
}

.btn-outline-secondary:focus,
.btn-outline-secondary.focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(72, 116, 155, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(72, 116, 155, 0.5);
}

.btn-outline-secondary.disabled,
.btn-outline-secondary:disabled {
  color: #48749b;
  background-color: transparent;
}

.btn-outline-secondary:not(:disabled):not(.disabled):active,
.btn-outline-secondary:not(:disabled):not(.disabled).active,
.show > .btn-outline-secondary.dropdown-toggle {
  color: #fff;
  background-color: #48749b;
  border-color: #48749b;
}

.btn-outline-secondary:not(:disabled):not(.disabled):active:focus,
.btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-secondary.dropdown-toggle:focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(72, 116, 155, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(72, 116, 155, 0.5);
}

.btn-outline-success {
  color: #18a957;
  border-color: #18a957;
}

.btn-outline-success:hover {
  color: #fff;
  background-color: #18a957;
  border-color: #18a957;
}

.btn-outline-success:focus,
.btn-outline-success.focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(24, 169, 87, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(24, 169, 87, 0.5);
}

.btn-outline-success.disabled,
.btn-outline-success:disabled {
  color: #18a957;
  background-color: transparent;
}

.btn-outline-success:not(:disabled):not(.disabled):active,
.btn-outline-success:not(:disabled):not(.disabled).active,
.show > .btn-outline-success.dropdown-toggle {
  color: #fff;
  background-color: #18a957;
  border-color: #18a957;
}

.btn-outline-success:not(:disabled):not(.disabled):active:focus,
.btn-outline-success:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-success.dropdown-toggle:focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(24, 169, 87, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(24, 169, 87, 0.5);
}

.btn-outline-info {
  color: #36bced;
  border-color: #36bced;
}

.btn-outline-info:hover {
  color: #212529;
  background-color: #36bced;
  border-color: #36bced;
}

.btn-outline-info:focus,
.btn-outline-info.focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(54, 188, 237, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(54, 188, 237, 0.5);
}

.btn-outline-info.disabled,
.btn-outline-info:disabled {
  color: #36bced;
  background-color: transparent;
}

.btn-outline-info:not(:disabled):not(.disabled):active,
.btn-outline-info:not(:disabled):not(.disabled).active,
.show > .btn-outline-info.dropdown-toggle {
  color: #212529;
  background-color: #36bced;
  border-color: #36bced;
}

.btn-outline-info:not(:disabled):not(.disabled):active:focus,
.btn-outline-info:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-info.dropdown-toggle:focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(54, 188, 237, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(54, 188, 237, 0.5);
}

.btn-outline-warning {
  color: #ffbb38;
  border-color: #ffbb38;
}

.btn-outline-warning:hover {
  color: #212529;
  background-color: #ffbb38;
  border-color: #ffbb38;
}

.btn-outline-warning:focus,
.btn-outline-warning.focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(255, 187, 56, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(255, 187, 56, 0.5);
}

.btn-outline-warning.disabled,
.btn-outline-warning:disabled {
  color: #ffbb38;
  background-color: transparent;
}

.btn-outline-warning:not(:disabled):not(.disabled):active,
.btn-outline-warning:not(:disabled):not(.disabled).active,
.show > .btn-outline-warning.dropdown-toggle {
  color: #212529;
  background-color: #ffbb38;
  border-color: #ffbb38;
}

.btn-outline-warning:not(:disabled):not(.disabled):active:focus,
.btn-outline-warning:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-warning.dropdown-toggle:focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(255, 187, 56, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(255, 187, 56, 0.5);
}

.btn-outline-danger {
  color: #de0d26;
  border-color: #de0d26;
}

.btn-outline-danger:hover {
  color: #fff;
  background-color: #de0d26;
  border-color: #de0d26;
}

.btn-outline-danger:focus,
.btn-outline-danger.focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(222, 13, 38, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(222, 13, 38, 0.5);
}

.btn-outline-danger.disabled,
.btn-outline-danger:disabled {
  color: #de0d26;
  background-color: transparent;
}

.btn-outline-danger:not(:disabled):not(.disabled):active,
.btn-outline-danger:not(:disabled):not(.disabled).active,
.show > .btn-outline-danger.dropdown-toggle {
  color: #fff;
  background-color: #de0d26;
  border-color: #de0d26;
}

.btn-outline-danger:not(:disabled):not(.disabled):active:focus,
.btn-outline-danger:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-danger.dropdown-toggle:focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(222, 13, 38, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(222, 13, 38, 0.5);
}

.btn-outline-light {
  color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-outline-light:hover {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-outline-light:focus,
.btn-outline-light.focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}

.btn-outline-light.disabled,
.btn-outline-light:disabled {
  color: #f8f9fa;
  background-color: transparent;
}

.btn-outline-light:not(:disabled):not(.disabled):active,
.btn-outline-light:not(:disabled):not(.disabled).active,
.show > .btn-outline-light.dropdown-toggle {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-outline-light:not(:disabled):not(.disabled):active:focus,
.btn-outline-light:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-light.dropdown-toggle:focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}

.btn-outline-dark {
  color: #3d4956;
  border-color: #3d4956;
}

.btn-outline-dark:hover {
  color: #fff;
  background-color: #3d4956;
  border-color: #3d4956;
}

.btn-outline-dark:focus,
.btn-outline-dark.focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(61, 73, 86, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(61, 73, 86, 0.5);
}

.btn-outline-dark.disabled,
.btn-outline-dark:disabled {
  color: #3d4956;
  background-color: transparent;
}

.btn-outline-dark:not(:disabled):not(.disabled):active,
.btn-outline-dark:not(:disabled):not(.disabled).active,
.show > .btn-outline-dark.dropdown-toggle {
  color: #fff;
  background-color: #3d4956;
  border-color: #3d4956;
}

.btn-outline-dark:not(:disabled):not(.disabled):active:focus,
.btn-outline-dark:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-dark.dropdown-toggle:focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(61, 73, 86, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(61, 73, 86, 0.5);
}

.btn-outline-pink {
  background: #fff;
  color: #b45ac3;
  border: 1px solid #b45ac3;
}
.btn-outline-pink:hover {
  background: #b45ac3;
  color: #fff;
}

.btn-outline-orange {
  background: #fff;
  color: #ca1900;
  border: 1px solid #ca1900;
}
.btn-outline-orange:hover {
  background-color: #ca1900 !important;
  color: #fff !important;
}

.d-none {
  display: none !important;
}
.d-gray {
  color: #999;
}
.d-block {
  display: block !important;
}
.d-inline-block {
  display: inline-block !important;
}
.d-inline {
  display: inline !important;
}
.d-flex {
  display: flex !important;
}
.d-table {
  display: table !important;
}
.d-table-cell {
  display: table-cell !important;
}
.flex-column {
  flex-direction: column !important;
}
.flex-rowReverse {
  flex-direction: row-reverse !important;
}
.cursor-pointer {
  cursor: pointer;
}
.cursor-default {
  cursor: default !important;
}
.cursor-notAllowed:disabled {
  cursor: not-allowed;
  pointer-events: all !important;
}

.bg-white {
  background-color: #fff !important;
}

.text-darkBlue {
  color: #095a9c;
}
.text-white {
  color: #fff !important;
}
.d-middle-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.justify-content-end {
  justify-content: end;
}

.p-relative {
  position: relative !important;
}
.p-absolute {
  position: absolute !important;
}
.p-fixed {
  position: fixed !important;
}
.p-static {
  position: static !important;
}
.p-sticky {
  position: sticky !important;
}
.p-inherit {
  position: inherit !important;
}
.p-initial {
  position: initial !important;
}

.display1 {
  font-size: 9.6rem;
  line-height: 11.5rem;
  font-weight: 500;
}
.display2 {
  font-size: 8.8rem;
  line-height: 10.6rem;
  font-weight: 500;
}
.display3 {
  font-size: 7.2rem;
  line-height: 8.6rem;
  font-weight: 500;
}
.display4 {
  font-size: 5.6rem;
  line-height: 6.7rem;
  font-weight: 500;
}
.display5 {
  font-size: 1.4rem;
  line-height: 1.4rem;
  font-weight: 500;
}

h1 {
  font-size: 3.8rem;
  line-height: 4.8rem;
  font-weight: 500;
}
h2 {
  font-size: 3.2rem;
  line-height: 3.8rem;
  font-weight: 500;
}
h3 {
  font-size: 2.8rem;
  line-height: 3.4rem;
  font-weight: 500;
}
h4 {
  font-size: 2.4rem;
  line-height: 2.9rem;
  font-weight: 500;
}
h5 {
  font-size: 2rem;
  line-height: 2.4rem;
  font-weight: 500;
}
h6 {
  font-size: 1.6rem;
  line-height: 1.9rem;
  font-weight: 600;
}
.lead {
  font-size: 2rem;
  line-height: 2.4rem;
  font-weight: 500;
}
p {
  font-size: 1.4rem;
  line-height: 2rem;
  font-weight: 300;
}
.small {
  font-size: 1.3rem;
  line-height: 2.4rem;
  font-weight: 500;
}

.font-9px {
  font-size: 0.9rem !important;
  line-height: 1.5rem;
}
.font-10px {
  font-size: 1rem !important;
  line-height: 1.5rem;
}
.font-11px {
  font-size: 1.1rem !important;
  line-height: 1.8rem;
}
.font-12px {
  font-size: 1.2rem !important;
  line-height: 2rem;
}
.font-13px {
  font-size: 1.3rem !important;
  line-height: 2.2rem;
}
.font-14px {
  font-size: 1.4rem !important;
  line-height: 2.5rem;
}
.font-15px {
  font-size: 1.5rem !important;
  line-height: 18.75px;
}
.font-16px {
  font-size: 1.6rem !important;
  line-height: 20px;
}
.font-17px {
  font-size: 1.7rem !important;
  line-height: 21.25px;
}
.font-18px {
  font-size: 1.8rem !important;
  line-height: 22.5px;
}
.font-19px {
  font-size: 1.9rem !important;
  line-height: 23.75px;
}
.font-20px {
  font-size: 2rem !important;
  line-height: 25px;
}
.font-21px {
  font-size: 2.1rem !important;
  line-height: 26.25px;
}
.font-22px {
  font-size: 2.2rem !important;
  line-height: 27.5px;
}
.font-23px {
  font-size: 2.3rem !important;
  line-height: 28.75px;
}
.font-24px {
  font-size: 2.4rem !important;
  line-height: 30px;
}
.font-25px {
  font-size: 2.5rem !important;
  line-height: 31.25px;
}
.font-26px {
  font-size: 2.6rem !important;
  line-height: 32.5px;
}
.font-27px {
  font-size: 2.7rem !important;
  line-height: 33.75px;
}
.font-28px {
  font-size: 2.8rem !important;
  line-height: 35px;
}
.font-29px {
  font-size: 2.9rem !important;
  line-height: 36.25px;
}
.font-30px {
  font-size: 3rem !important;
  line-height: 37.5px;
}
.font-31px {
  font-size: 3.1rem !important;
  line-height: 37.5px;
}
.font-32px {
  font-size: 3.2rem !important;
  line-height: 37.5px;
}
.font-33px {
  font-size: 3.3rem !important;
  line-height: 37.5px;
}
.font-34px {
  font-size: 3.4rem !important;
  line-height: 37.5px;
}
.font-35px {
  font-size: 3.5rem !important;
  line-height: 37.5px;
}
.font-36px {
  font-size: 3.6rem !important;
  line-height: 37.5px;
}
.font-37px {
  font-size: 3.7rem !important;
  line-height: 37.5px;
}
.font-38px {
  font-size: 3.8rem !important;
  line-height: 37.5px;
}
.font-39px {
  font-size: 3.9rem !important;
  line-height: 37.5px;
}
.font-40px {
  font-size: 4rem !important;
  line-height: 37.5px;
}
.font-45px {
  font-size: 4.5rem !important;
  line-height: 6rem;
}

.font-60px {
  font-size: 6rem !important;
  line-height: 6rem;
}

h1 span,
h2 span,
h3 span,
h4 span,
h5 span,
h6 span {
  font-size: inherit;
}

.fw-100 {
  font-weight: 100 !important;
}
.fw-200 {
  font-weight: 200 !important;
}
.fw-300 {
  font-weight: 300 !important;
}
.fw-400 {
  font-weight: 400 !important;
}
.fw-500 {
  font-weight: 500 !important;
}
.fw-600 {
  font-weight: 600 !important;
}
.fw-700 {
  font-weight: 700 !important;
}
.fw-800 {
  font-weight: 800 !important;
}
.fw-900 {
  font-weight: 900 !important;
}

.text-left {
  text-align: left !important;
}
.text-right {
  text-align: right !important;
}
.text-center {
  text-align: center !important;
}

.m-0 {
  margin: 0rem !important;
}
.m-5 {
  margin: 0.5rem !important;
}
.m-10 {
  margin: 1rem;
}
.m-15 {
  margin: 1.5rem;
}
.m-20 {
  margin: 2rem;
}
.m-25 {
  margin: 2.5rem;
}
.m-30 {
  margin: 3rem;
}
.m-35 {
  margin: 3.5rem;
}
.m-40 {
  margin: 4rem;
}

.mt-0 {
  margin-top: 0rem !important;
}
.mt-1 {
  margin-top: 0.1rem !important;
}
.mt-2 {
  margin-top: 0.2rem !important;
}
.mt-3 {
  margin-top: 0.3rem !important;
}
.mt-4 {
  margin-top: 0.4rem !important;
}
.mt-5 {
  margin-top: 0.5rem !important;
}
.mt-10 {
  margin-top: 1rem !important;
}
.mt-15 {
  margin-top: 1.5rem !important;
}
.mt-20 {
  margin-top: 2rem !important;
}
.mt-25 {
  margin-top: 2.5rem !important;
}
.mt-30 {
  margin-top: 3rem !important;
}
.mt-35 {
  margin-top: 3.5rem !important;
}
.mt-40 {
  margin-top: 4rem !important;
}
.mt-44 {
  margin-top: 4.4rem !important;
}
.mt-55 {
  margin-top: 5.5rem !important;
}
.mt-60 {
  margin-top: 6.5rem !important;
}
.mt-90 {
  margin-top: 7rem !important;
}
.mt-100 {
  margin-top: 10rem !important;
}

.mb-0 {
  margin-bottom: 0rem !important;
}
.mb-5 {
  margin-bottom: 0.5rem !important;
}
.mb-10 {
  margin-bottom: 1rem !important;
}
.mb-15 {
  margin-bottom: 1.5rem !important;
}
.mb-20 {
  margin-bottom: 2rem !important;
}
.mb-25 {
  margin-bottom: 2.5rem !important;
}
.mb-30 {
  margin-bottom: 3rem !important;
}
.mb-35 {
  margin-bottom: 3.5rem !important;
}
.mb-40 {
  margin-bottom: 4rem !important;
}
.mb-45 {
  margin-bottom: 4.5rem !important;
}
.mb-50 {
  margin-bottom: 5rem !important;
}
.mb-70 {
  margin-bottom: 7rem !important;
}
.mb-100 {
  margin-bottom: 11rem !important;
}

.ml-0 {
  margin-left: 0rem !important;
}
.ml-5 {
  margin-left: 0.5rem !important;
}
.ml-10 {
  margin-left: 1rem !important;
}
.ml-12 {
  margin-left: 1.2rem !important;
}

.ml-15 {
  margin-left: 1.5rem !important;
}
.ml-20 {
  margin-left: 2rem !important;
}
.ml-25 {
  margin-left: 2.5rem !important;
}
.ml-30 {
  margin-left: 3rem !important;
}
.ml-35 {
  margin-left: 3.5rem !important;
}
.ml-40 {
  margin-left: 4rem !important;
}
.ml-50 {
  margin-left: 5rem !important;
}
.ml-70 {
  margin-left: 7rem !important;
}
.ml-90 {
  margin-left: 9rem !important;
}
.ml-95 {
  margin-left: 9.5rem !important;
}

.mlP-91 {
  margin-left: 91% !important;
}

.ml-130 {
  margin-left: 13rem !important;
}
.ml-150 {
  margin-left: 15rem !important;
}

.ml-200 {
  margin-left: 20rem !important;
}
.ml-500 {
  margin-left: 50rem !important;
}

.mr-0 {
  margin-right: 0rem !important;
}
.mr-5 {
  margin-right: 0.5rem !important;
}
.mr-10 {
  margin-right: 1rem !important;
}
.mr-13 {
  margin-right: 1.3rem !important;
}
.mr-15 {
  margin-right: 1.5rem !important;
}
.mr-20 {
  margin-right: 2rem !important;
}
.mr-25 {
  margin-right: 2.5rem !important;
}
.mr-30 {
  margin-right: 3rem !important;
}
.mr-35 {
  margin-right: 3.5rem !important;
}
.mr-40 {
  margin-right: 4rem !important;
}
.mr-60 {
  margin-right: 6rem !important;
}
.mr-60 {
  margin-right: 6rem !important;
}
.mr-70 {
  margin-right: 7rem !important;
}
.mr-100 {
  margin-right: 10rem !important;
}

.my-0 {
  margin-top: 0rem !important;
  margin-bottom: 0rem !important;
}
.my-5 {
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important;
}
.my-10 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}
.my-15 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important;
}
.my-20 {
  margin-top: 2rem !important;
  margin-bottom: 2rem !important;
}
.my-25 {
  margin-top: 2.5rem !important;
  margin-bottom: 2.5rem !important;
}
.my-30 {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important;
}
.my-35 {
  margin-top: 3.5rem !important;
  margin-bottom: 3.5rem !important;
}
.my-40 {
  margin-top: 4rem !important;
  margin-bottom: 4rem !important;
}

.mx-0 {
  margin-left: 0rem !important;
  margin-right: 0rem !important;
}
.mx-5 {
  margin-left: 0.5rem !important;
  margin-right: 0.5rem !important;
}
.mx-10 {
  margin-left: 1rem !important;
  margin-right: 1rem !important;
}
.mx-15 {
  margin-left: 1.5rem !important;
  margin-right: 1.5rem !important;
}
.mx-20 {
  margin-left: 2rem !important;
  margin-right: 2rem !important;
}
.mx-25 {
  margin-left: 2.5rem !important;
  margin-right: 2.5rem !important;
}
.mx-30 {
  margin-left: 3rem !important;
  margin-right: 3rem !important;
}
.mx-35 {
  margin-left: 3.5rem !important;
  margin-right: 3.5rem !important;
}
.mx-40 {
  margin-left: 4rem !important;
  margin-right: 4rem !important;
}

.p-0 {
  padding: 0rem !important;
}
.p-1 {
  padding: 0.1rem !important;
}
.p-2 {
  padding: 0.2rem !important;
}
.p-3 {
  padding: 0.3rem !important;
}
.p-4 {
  padding: 0.4rem !important;
}
.p-5 {
  padding: 0.5rem !important;
}
.p-6 {
  padding: 0.6rem !important;
}
.p-7 {
  padding: 0.7rem !important;
}
.p-8 {
  padding: 0.8rem !important;
}
.p-9 {
  padding: 0.9rem !important;
}
.p-10 {
  padding: 1rem !important;
}
.p-15 {
  padding: 1.5rem !important;
}
.p-20 {
  padding: 2rem !important;
}
.p-25 {
  padding: 2.5rem !important;
}
.p-30 {
  padding: 3rem !important;
}
.p-35 {
  padding: 3.5rem !important;
}
.p-40 {
  padding: 4rem !important;
}

.pt-0 {
  padding-top: 0rem !important;
}
.pt-1 {
  padding-top: 0.1rem !important;
}
.pt-2 {
  padding-top: 0.2rem !important;
}
.pt-3 {
  padding-top: 0.3rem !important;
}
.pt-4 {
  padding-top: 0.4rem !important;
}
.pt-5 {
  padding-top: 0.5rem !important;
}
.pt-10 {
  padding-top: 1rem !important;
}
.pt-15 {
  padding-top: 1.5rem !important;
}
.pt-20 {
  padding-top: 2rem !important;
}
.pt-25 {
  padding-top: 2.5rem !important;
}
.pt-30 {
  padding-top: 3rem !important;
}
.pt-35 {
  padding-top: 3.5rem !important;
}
.pt-40 {
  padding-top: 4rem !important;
}

.pb-0 {
  padding-bottom: 0rem !important;
}
.pb-1 {
  padding-bottom: 0.1rem !important;
}
.pb-2 {
  padding-bottom: 0.2rem !important;
}
.pb-3 {
  padding-bottom: 0.3rem !important;
}
.pb-4 {
  padding-bottom: 0.4rem !important;
}
.pb-5 {
  padding-bottom: 0.5rem !important;
}
.pb-10 {
  padding-bottom: 1rem !important;
}
.pb-15 {
  padding-bottom: 1.5rem !important;
}
.pb-17 {
  padding-bottom: 1.7rem !important;
}
.pb-20 {
  padding-bottom: 2rem !important;
}
.pb-25 {
  padding-bottom: 2.5rem !important;
}
.pb-30 {
  padding-bottom: 3rem !important;
}
.pb-35 {
  padding-bottom: 3.5rem !important;
}
.pb-40 {
  padding-bottom: 4rem !important;
}

.pl-0 {
  padding-left: 0rem !important;
}
.pl-1 {
  padding-left: 0.1rem !important;
}
.pl-2 {
  padding-left: 0.2rem !important;
}
.pl-3 {
  padding-left: 0.3rem !important;
}
.pl-4 {
  padding-left: 0.4rem !important;
}
.pl-5 {
  padding-left: 0.5rem !important;
}
.pl-10 {
  padding-left: 1rem !important;
}
.pl-15 {
  padding-left: 1.5rem !important;
}
.pl-20 {
  padding-left: 2rem !important;
}
.pl-25 {
  padding-left: 2.5rem !important;
}
.pl-30 {
  padding-left: 3rem !important;
}
.pl-35 {
  padding-left: 3.5rem !important;
}
.pl-40 {
  padding-left: 4rem !important;
}

.pr-0 {
  padding-right: 0rem !important;
}
.pr-1 {
  padding-right: 0.1rem !important;
}
.pr-2 {
  padding-right: 0.2rem !important;
}
.pr-3 {
  padding-right: 0.3rem !important;
}
.pr-4 {
  padding-right: 0.4rem !important;
}
.pr-5 {
  padding-right: 0.5rem !important;
}
.pr-10 {
  padding-right: 1rem !important;
}
.pr-15 {
  padding-right: 1.5rem !important;
}
.pr-20 {
  padding-right: 2rem !important;
}
.pr-25 {
  padding-right: 2.5rem !important;
}
.pr-30 {
  padding-right: 3rem !important;
}
.pr-35 {
  padding-right: 3.5rem !important;
}
.pr-40 {
  padding-right: 4rem !important;
}

.py-0 {
  padding-top: 0rem !important;
  padding-bottom: 0rem !important;
}
.py-5 {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}
.py-10 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}
.py-15 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important;
}
.py-20 {
  padding-top: 2rem !important;
  padding-bottom: 2rem !important;
}
.py-25 {
  padding-top: 2.5rem !important;
  padding-bottom: 2.5rem !important;
}
.py-30 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important;
}
.py-35 {
  padding-top: 3.5rem !important;
  padding-bottom: 3.5rem !important;
}
.py-40 {
  padding-top: 4rem !important;
  padding-bottom: 4rem !important;
}

.px-0 {
  padding-left: 0rem !important;
  padding-right: 0rem !important;
}
.px-5 {
  padding-left: 0.5rem !important;
  padding-right: 0.5rem !important;
}
.px-10 {
  padding-left: 1rem !important;
  padding-right: 1rem !important;
}
.px-15 {
  padding-left: 1.5rem !important;
  padding-right: 1.5rem !important;
}
.px-20 {
  padding-left: 2rem !important;
  padding-right: 2rem !important;
}
.px-25 {
  padding-left: 2.5rem !important;
  padding-right: 2.5rem !important;
}
.px-30 {
  padding-left: 3rem !important;
  padding-right: 3rem !important;
}
.px-35 {
  padding-left: 3.5rem !important;
  padding-right: 3.5rem !important;
}
.px-40 {
  padding-left: 4rem !important;
  padding-right: 4rem !important;
}

.text-uppercase {
  text-transform: uppercase;
}
.text-lowercase {
  text-transform: lowercase;
}
.text-capitalize {
  text-transform: capitalize;
}
.text-inherit {
  text-transform: inherit;
}
.text-initial {
  text-transform: initial;
}

.lazyload-wrapper {
  width: 100%;
}

* {
  box-sizing: border-box;
}
.opacity-1 {
  opacity: 1;
}
.opacity-0 {
  opacity: 0;
}
.text-decoration-none {
  text-decoration: none;
}
ol li ol {
  list-style-type: lower-alpha;
}
.list-style-none {
  list-style: none;
}

.btn-primary {
  background: #1678c8;
  border: 1px solid #1678c8;
  color: #fff;
  cursor: pointer;
}
.btn-primary:hover {
  background: #0a4fb3;
}
.btn-light {
  background: #fff;
  border: 1px solid #ccc;
  color: #333;
  cursor: pointer;
}
.btn-light:hover {
  background: #f8f8f8;
}

.color-light {
  color: #fff !important;
}

.alert-primary {
  color: #004085;
  background-color: #cce5ff;
  border: 1px solid #b8daff;
  border-radius: 3px;
  font-size: 13px;
}
.alert-secondary {
  color: #383d41;
  background-color: #e2e3e5;
  border: 1px solid #d6d8db;
  border-radius: 3px;
  font-size: 13px;
}
.alert-success {
  color: #155724;
  background-color: #d4edda;
  border: 1px solid #59b36d;
  border-radius: 3px;
  font-size: 13px;
}
.alert-danger {
  color: #721c24;
  background-color: #f8d7da;
  border: 1px solid #cf737b;
  border-radius: 3px;
  font-size: 13px;
  text-align: center;
}
.alert-warning {
  color: #856404;
  background-color: #fff3cd;
  border: 1px solid #ffeeba;
  border-radius: 3px;
  font-size: 13px;
}
.alert-info {
  color: #0c5460;
  background-color: #d1ecf1;
  border: 1px solid #bee5eb;
  border-radius: 3px;
  font-size: 13px;
}
.alert-light {
  color: #818182;
  background-color: #fefefe;
  border: 1px solid #fdfdfe;
  border-radius: 3px;
  font-size: 13px;
}
.alert-dark {
  color: #1b1e21;
  background-color: #d6d8d9;
  border: 1px solid #c6c8ca;
  border-radius: 3px;
  font-size: 13px;
}
.background-lightGrey {
  background: #fafafa;
}

input[readonly] {
  background: #f8f8f8;
}

.br-3 {
  border-radius: 3px !important;
}

.w-10 {
  width: 10% !important;
}
.w-17 {
  width: 17% !important;
}
.w-20 {
  width: 20% !important;
}
.w-25 {
  width: 25% !important;
}
.w-30 {
  width: 30% !important;
}
.w-35 {
  width: 35% !important;
}
.w-38 {
  width: 38% !important;
}
.w-40 {
  width: 40% !important;
}
.w-44 {
  width: 44% !important;
}
.w-45 {
  width: 45% !important;
}
.w-48 {
  width: 47% !important;
}
.w-50 {
  width: 50% !important;
}
.w-55 {
  width: 55% !important;
}
.w-60 {
  width: 60% !important;
}
.w-70 {
  width: 70% !important;
}
.w-75 {
  width: 75% !important;
}
.w-80 {
  width: 80% !important;
}
.w-85 {
  width: 85% !important;
}
.w-90 {
  width: 90% !important;
}
.w-95 {
  width: 95% !important;
}
.w-100 {
  width: 100% !important;
}
.h-10 {
  height: 10%;
}

.h-55 {
  height: 55% !important;
}

.h-100 {
  height: 100%;
}
.minw-120 {
  min-width: 120px;
  padding: 0 10px;
}
.mw-100 {
  max-width: 100px;
  padding: 0 10px;
}
.mw-150 {
  max-width: 150px;
  padding: 0 10px;
}
.mw-200 {
  max-width: 200px;
  padding: 0 10px;
}
.mw-220 {
  max-width: 220px;
  padding: 0 10px;
}
.mw-250 {
  max-width: 250px;
  padding: 0 10px;
}
.mw-300 {
  max-width: 300px;
  padding: 0 10px;
}
.mw-350 {
  max-width: 350px !important;
  padding: 0 10px;
}
.mw-400 {
  max-width: 400px;
  padding: 0 10px;
}
.mw-450 {
  max-width: 450px;
  padding: 0 10px;
}
.mw-500 {
  max-width: 500px;
  padding: 0 10px;
}
.mw-550 {
  max-width: 550px;
  padding: 0 10px;
}
.mw-600 {
  max-width: 600px;
  padding: 0 10px;
}
.MuiPopover-paper li {
  font-size: 1.3rem;
}
.row {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.space-around {
  justify-content: space-around;
}

.space-between {
  justify-content: space-between;
}

.nowrap {
  white-space: nowrap !important;
}

.Mui-disabled {
  display: none !important;
}
.shadow-none {
  box-shadow: none !important;
}
.position-relative {
  position: relative;
}
.pointer {
  cursor: pointer;
}
.ReactModalPortal .ReactModal__Overlay.ReactModal__Overlay--after-open {
  z-index: 999 !important;
}

.outerContainer {
  /*height: 100vh;*/
  box-sizing: border-box;
}
.border0 {
  border: none !important;
}
.borderTop {
  border-top: 2px solid #1582dc !important;
}
.innerContainer {
  /*max-width: 1400px;*/
  position: relative;
  width: 100%;
  margin: 0 auto;
  box-sizing: border-box;
  padding: 0;
}
.innerContainer12 {
  max-width: 1170px;
  width: 100%;
  margin: 0 auto;
  box-sizing: border-box;
}
.innerContainer10 {
  max-width: 1000px;
  width: 100%;
  margin: 0 auto;
  box-sizing: border-box;
}
.innerContainer08 {
  max-width: 800px;
  width: 100%;
  margin: 0 auto;
  box-sizing: border-box;
}
.footerContainer {
  background: #1583dc;
}
[class^='PrivateTabIndicator-colorPrimary'] {
  background-color: #0078db !important;
}

.rightbody {
  height: 731px;
  display: flex;
  align-items: center;
  padding: 20px 0;
  /* max-width: 360px; */
}

.loginReg {
  max-width: 385px;
}
.bEmail {
  width: 100%;
}
.bEmail .fa-envelope,
.bEmail .fa-address-card,
.bEmail .fa-building,
.bEmail .fa-phone-alt {
  font-size: 1.8rem;
  color: #1583dc;
}
.bEmail input {
  padding: 15px 14px;
  font-size: 1.3rem;
  letter-spacing: inherit;
  font-family: 'Open Sans', sans-serif !important;
}
.bEmail label {
  top: -4px !important;
  font-size: 1.4rem;
}
.bEmail .MuiInputLabel-shrink {
  top: -2px;
}
.bPassword {
  width: 100%;
}
.bPassword .fa-lock,
.bPassword .fa-unlock {
  font-size: 1.8rem;
  cursor: pointer;
  color: #1583dc;
}
.bPassword input {
  padding: 15px 14px;
  font-size: 1.3rem;
  letter-spacing: inherit;
}
.bPassword label {
  top: -4px !important;
  font-size: 1.4rem;
}
.bPassword .MuiInputLabel-shrink {
  top: -2px;
}
.registrationLoginBtn {
  text-align: center;
}
.registrationLoginBtn button {
  background: #1583dc;
  color: #fff;
  padding: 6px 25px;
  cursor: pointer;
}
.registrationLoginBtn input[type='submit'] {
  width: 524px;
  height: 46px;
  border-radius: 10px;
  background: linear-gradient(90deg, #3d008c 0%, #00d2bf 100%);
  /* background: #1583dc; */
  color: #fff;
  padding: 7px 25px;
  border: 0px solid rgb(121, 109, 109);
  /* border-radius: 2px; */
  margin-top: 30px;
  cursor: pointer;
  font-family: 'Open Sans', sans-serif !important;
  font-size: 1.5rem;
}

.registrationLoginBtn input[type='submit']:hover {
  background: linear-gradient(90deg, #23014e 0%, #0b9488 100%);
  transition: 0.2s ease-in-out;
}
.registrationLoginBtn input[type='submit']:disabled {
  cursor: default;
}
.tncText {
  font-size: 1.4rem;
  position: relative;
  top: 2px;
}
.tncText span {
  font-size: 1.4rem;
}
.formLoginBtn {
  margin-top: 20px;
}
.displayNone {
  display: none;
}
.successMessage {
  background: #4caf50;
  padding: 10px 15px;
  color: #fff;
  width: 100%;
  max-width: 350px;
  border-radius: 5px;
  margin-bottom: 10px;
}

.errorMessage {
  background: red;
  padding: 10px 15px;
  color: #fff;
  width: 100%;
  max-width: 350px;
  border-radius: 5px;
  margin-bottom: 10px;
}
.errorMessage-URLMonitoring {
  background: red;
  padding: 5px 15px;
  color: #fff;
  width: 100%;
  max-width: 500px;
  border-radius: 5px;
}
.warningMessage {
  padding: 2px 0px;
  color: red;
  width: 100%;
  max-width: 350px;
  border-radius: 5px;
  font-size: 1.2rem;
}
.show_error {
  display: block !important;
  padding: 2px 0px;
  color: red;
  width: 100%;
  max-width: 350px;
  border-radius: 5px;
  font-size: 1.2rem;
}
.MuiInputLabel-outlined.MuiInputLabel-shrink {
  font-size: 1.7rem;
  top: 0px !important;
}
.inputLegend {
  background: #fff;
  padding: 0 3px !important;
  font-size: 1.6rem;
  position: relative;
  z-index: 9 !important;
}
.outlineInputArea {
  position: relative;
  z-index: 8;
  /* background: #fff; */
  font-size: 1.3rem !important;
  display: flex !important;
  align-items: center !important;
}
.outlineInputArea .MuiSelect-icon {
  top: calc(50% - 9px);
}
.regMsg {
  display: block;
  font-size: 1.2rem;
  line-height: 2rem;
  color: #721c24;
  background-color: #f8d7da;
  box-shadow: 0 0 1px #721c24 inset;
  padding: 0rem 0.5rem 0;
  border-radius: 3px;
  margin-top: 1px;
}
.tagRegMsg {
  font-size: 1.2rem;
  line-height: 2rem;
  color: #721c24;
  background-color: #f8d7da;
  box-shadow: 0 0 1px #721c24 inset;
  padding: 0.2rem 0.5rem 0;
  border-radius: 3px;
  margin-left: 2.5%;
}
.duplicateMsg {
  font-size: 1.4rem;
  line-height: 2.7rem;
  color: #721c24;
  background-color: #f8d7da;
  box-shadow: 0 0 1px #721c24 inset;
  padding: 0.2rem 0.5rem 0;
  border-radius: 3px;
  margin-left: 1.5%;
  width: 98%;
}
.row > div {
  width: 100%;
  margin-right: 2%;
}

.tagErrorDiv {
  display: grid;
  width: 95%;
  margin-left: 1%;
  grid-gap: 3.4%;
  grid-template-columns: 50% 50%;
}

.gridCenter {
  display: flex !important;
}
/* [role="tooltip"] *{font-size: 1rem !important; font-weight: 300;} */
.successMsg {
  display: block;
  font-size: 1rem;
  line-height: 2.4rem;
  color: #335a4c;
  background-color: #8eecc98c;
  box-shadow: 0 0 1px #721c24 inset;
  padding: 0.2rem 0.5rem 0;
  border-radius: 3px;
  margin-top: -3px;
}
[role='tooltip'] * {
  font-size: 1.3rem !important;
  font-weight: 400;
  font-family: 'Open Sans', sans-serif !important;
  top: -10px;
  z-index: 6;
}
input:focus {
  outline: none !important;
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}
.fa-edit,
.fa-trash-alt,
.fa-times-circle {
  width: auto !important;
}

.fadeOut {
  /*transition: opacity 0.5s ease-out;*/
  animation: fadeIn 3s;
  animation-delay: 5s;
  animation-fill-mode: forwards;
}

.secondaryNav header {
  z-index: 4;
}

@keyframes fadeIn {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.accountBtn {
  display: flex;
  align-items: center;
  white-space: nowrap;
}
.accountBtn .addIcon {
  font-size: 20px;
  line-height: 11px;
}

/* navigation */
@import url('https://fonts.googleapis.com/css2?family=Rajdhani:wght@300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;1,100;1,300;1,400;1,500&display=swap');
/* @import url('https://fonts.googleapis.com/icon?family=Material+Icons');
@import url('https://fonts.googleapis.com/icon?family=Material+Icons+Outlined'); */

.homeImage {
  background: url('assets/images/landing-page.jpg');
  background-repeat: no-repeat;
  background-position: center left;
  background-size: cover;
  min-height: calc(100vh - 10px);
}

.cf-Logo {
  margin: 20px;
  position: relative;
  width: 130px;
  display: flex;
}
.cf-Logo a {
  text-decoration: none;
}
.cf-Logo span.cloud {
  font-family: 'Roboto', sans-serif !important;
  color: #fff;
  display: block;
  font-size: 18px;
  line-height: 14px;
  padding: 1px 2px;
  border-radius: 1px;
  font-weight: 300;
}
.cf-Logo span.forest {
  font-family: 'Roboto', sans-serif !important;
  color: #fff;
  background: transparent;
  display: block;
  margin-top: 1px;
  font-size: 20px;
  line-height: 19px;
  padding: 0px 2px;
  border-radius: 1px;
  font-weight: 300;
}
.cLogo .cf-Logo {
  margin: 5px 0 0;
}
.cLogo .cf-Logo span.cloud {
  padding: 0px 0px 0px;
  font-size: 15px;
  line-height: 15px;
}
.cLogo .cf-Logo span.forest {
  font-size: 17px;
  line-height: 10px;
  padding: 0px;
  border-radius: 1px;
  margin-top: 2px;
}
.cLogo {
  flex-grow: 1;
  padding: 0 1rem;
  height: 100%;
}
.cLogo a {
  text-decoration: none;
  display: inline-block;
  outline: none;
}
.cLogo .fa-cloud {
  color: #fff;
}
.logoFont {
  font-family: 'Roboto', sans-serif !important;
  color: #132b8f;
  font-weight: 400;
}
.cf-Logo .cfx {
  position: relative;
  top: 20.5px;
  right: 0px;
  height: 19px;
}
.cf-Logo .cfx img {
  width: 15px;
}
.cLogo .cf-Logo .cfx {
  width: 1.5rem;
  top: 15px;
  margin-left: 1px;
}
.cLogo .cf-Logo .cfx img {
  width: 15px;
}
.cf-Logo .cfTree {
  height: 40px;
}
.cLogo .cf-Logo .cfTree {
  height: 34px;
  margin-right: 2px;
}
.cf-Logo.blue {
  display: none;
}
.cf-Logo.blue * {
  color: #0077db !important;
  font-weight: 300 !important;
}
.cf-Logo.blue .cfTree {
  height: 44px;
}
.cf-Logo.blue span.cloud {
  padding: 2px;
  font-size: 19px;
}
.cf-Logo.blue span.forest {
  font-size: 21px;
}
.cf-Logo.blue .cfx img {
  width: 19px;
}

.headerName {
  font-size: 2.3rem;
  font-weight: 500;
  margin-left: 2rem;
}

.breadcrumb {
  padding: 0 5px;
}
.breadcrumb span {
  padding: 1px 5px;
  margin: 0 5px 0 0;
  background: #f8f8f8;
  border: 1px solid #c9c2c2;
  border-radius: 3px;
  color: #888;
  font-size: 11px;
  text-transform: uppercase;
}

.svgLogo {
  border: 1px solid #0058a0;
  display: inline-block;
  padding: 3px;
  border-radius: 4px;
  line-height: 10px;
}

.disableSvgLogo {
  border: 1px solid #919ea9;
  display: inline-block;
  padding: 3px;
  border-radius: 4px;
  line-height: 10px;
}
.disableSvgLogo svg {
  fill: #999 !important;
}
.borderNone {
  border: none !important;
}

.leftBorder {
  border-left: 1px solid #cfcbcb !important;
}
.rightBorder {
  border-right: 1px solid #cfcbcb !important;
}

.secondaryNav {
  display: none !important;
}
.splitLayout {
  display: flex;
  align-items: flex-start;
  min-height: calc(100vh - 52px);
  background: #f1f2f5;
  width: 100%;
}
.leftNav {
  text-align: center;
  max-height: calc(100vh - 52px);
  min-height: calc(100vh - 52px);
  background: #fff;
  overflow: auto;
  position: relative;
  z-index: 999;
  max-width: 220px;
  /* padding-top: 10px; */
  transition: max-width 0.3s ease, width 0.3s ease;
  width: 260px; /* Explicit width for transition */
}

.leftNav.collapsed {
  max-width: 60px;
  width: 60px;
}

.leftNav .menu-name {
  white-space: wrap;
  overflow: hidden;
  opacity: 1;
  transition: opacity 0.2s ease, max-width 0.3s ease;
  max-width: 160px; /* Approximate width of text */
}

.leftNav.collapsed .menu-name {
  opacity: 0;
  max-width: 0;
  display: none; /* Changed from 'display: none' to allow for transition */
}

.leftNav::-webkit-scrollbar {
  display: none;
}
.leftNav .platformIcon {
  padding: 15px 10px;
  position: sticky;
  top: 0;
  z-index: 1000;
  background: #f8f8f8;
}
.leftNav .platformIcon:hover {
  background: transparent;
}
.leftNav ul li {
  position: relative;
}
.leftNav ul li .iconLabel {
  display: none;
  position: absolute;
  left: 65px;
  background: #0078db;
  padding: 8px 15px;
  z-index: 99999 !important;
  white-space: nowrap;
  top: 6px;
  font-size: 14px;
  text-transform: uppercase;
  border-radius: 3px;
  color: #fff;
}
.leftNav ul li .iconLabel span {
  text-transform: lowercase;
}
.leftNav ul li .iconLabel::before {
  content: '';
  display: block;
  width: 0px;
  height: 0px;
  border-left: 8px solid #0078db;
  border-top: 8px solid #0078db;
  position: absolute;
  left: -4px;
  top: 39%;
  transform: rotate(45deg);
}
.leftNav ul li:hover a {
  background: #dcdcdc;
}
.leftNavDeactive li:hover a {
  background: #e4e4e4 !important;
}
.leftNav ul li.activeTab a {
  background: #dcdcdc;
}
/* .leftNav ul li:hover svg {
  fill: #fff;
  filter: brightness(0) invert(1);
}

.leftNav ul li.activeTab svg {
  fill: #fff;
  filter: brightness(0) invert(1);
}
.leftNav ul li:hover .iconLabel {
  display: block;
  transition: 1s;
} */
.leftNav ul li a {
  display: flex;
  padding: 12px 10px;
  color: black;
  min-height: 48px;
  align-items: center;
  justify-content: flex-start;
  text-decoration: none;
  font-size: 14px;
  font-weight: 600;
  text-align: left;
  transition: padding 0.3s ease;
}

.leftNav ul li a .icon {
  position: relative;
  width: 24px;
  height: 24px;
  margin-right: 5px;
  margin-left: 6px;
  /* transition: margin 0.3s ease; */
}
/* .leftNav.collapsed .menu-name {
  display: none;
} */
.toggle-btn {
  /* position: fixed; */
  top: 10px;
  left: 110px;
  z-index: 11;
  cursor: pointer;
  height: 30px;
  margin: 0 24.5px;
}
.toggle-btn.reverse {
  transform: rotate(180deg);
}

.cube1,
.cube2,
.cube3 {
  position: absolute;
  margin: 0 auto;
  display: block;
  text-align: center;
}
.cube1 {
  top: 0px;
  left: calc(50% - 5px);
}
.cube2 {
  top: 11px;
  left: calc(50% - 11px);
}
.cube3 {
  top: 11px;
  left: calc(50% - 1px);
}
.rightContent {
  /* width: calc(100% - 62px);
  min-height: calc(99vh - 50px);
  background: #f1f2f5;
  overflow: scroll;
  height: 1vh;
  border-left: 1px solid rgba(236, 236, 236, 1); */

  /* width: calc(100% - 62px); */
  /* width: 100%; */
  /* min-height: calc(99vh - 50px); */
  /* min-height: 76%; */
  /* padding: 20px; */
  /* background: rgba(241, 242, 245, 1); */
  /* background: #fff; */
  /* overflow: scroll; */
  /* height: 1vh; */
  /* border-left: 1px solid rgba(236, 236, 236, 1); */
  min-height: calc(99vh - 50px);
  background: #f1f2f5;
  overflow: scroll;
  height: 1vh;
  width: 100%;
}

.innerRightContent {
  background: #fff;
  /* background: rgba(241, 242, 245, 1); */
  padding: 20px;
  width: 97%;
  height: 86vh;
  /* margin: auto auto; */
  margin: 2rem;
  border-radius: 8px;
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.06);
  overflow: auto;
}

.tabs {
  position: relative !important;
  top: 0px;
}
.tabs .tabItem {
  display: flex;
  align-items: center;
}
.tabs .tabItem span {
  font-size: 1.3rem;
  font-weight: 600;
  margin-left: 0.7rem;
}
.appBar {
  box-shadow: none !important;
  background: linear-gradient(90.09deg, #3d008c 0%, #00d2bf 100%) !important;
}
.appBar-secondary {
  box-shadow: 0 1px 1px #ccc !important;
}
.appBar:first-child {
  z-index: 9;
}
.appBar .toolBar {
  min-height: auto;
  margin: 0;
  padding: 0;
}
.appBar .userDisplayName {
  text-transform: uppercase;
  padding: 1.5rem 2rem;
  font-size: 1.3rem;
}
.appBar .userDropDown {
  padding: 1.5rem 1.7rem;
  height: 52px;
  box-sizing: border-box;
  /* background: #1986e0; */
  cursor: pointer;
  /* border-left: 1px solid #1e75be; */
  font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.appBar .userDropDown:hover {
  background: #058a87;
  transition: 0.2s ease-in-out;
}
.userDropDown .userDisplayDD {
  display: none;
  background: #fff;
  width: 250px;
  position: fixed;
  right: 0;
  top: 52px;
  color: #000;
  box-shadow: 0 5px 10px #ccc;
  cursor: default;
}
.userDropDown .userDisplayDD ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
.userDropDown .userDisplayDD ul li {
  border-bottom: 1px solid #ddd;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
}
.userDropDown .userDisplayDD ul li a {
  text-decoration: none;
  color: #222;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  box-sizing: border-box;
  width: 100%;
  padding: 15px;
}
.userDropDown .userDisplayDD ul li:hover {
  background: #fbfbfb;
}
.userDropDown .userDisplayDD ul li span {
  font-size: 1.3rem;
}
.userDropDown .userDisplayDD ul li span:nth-child(2) {
  width: 30px;
  text-align: center;
  font-size: 1.4rem;
  color: #0078db;
}
.userDropDown .userDisplayDD .planPeriod {
  background: #fbfbfb;
  display: none;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #bbb;
  padding-left: 5px;
}
.userDropDown .userDisplayDD .planPeriod .daysCount {
  width: 70px;
  text-align: center;
  color: #666;
  padding: 10px;
}
.userDropDown .userDisplayDD .accountDisplayName {
  padding: 15px;
  display: none;
  justify-content: space-between;
  align-items: center;
  position: relative;
  border-bottom: 1px solid #bbb;
  background-color: #e6f4ff;
}
.userDropDown .userDisplayDD .accountDisplayName .fa-exchange-alt {
  color: #0078db;
}
.userDropDown .userDisplayDD .accountDisplayName .selectedAccountName {
  position: absolute;
  top: 0px;
  font-size: 1rem;
  background: #ddd;
  padding: 1px 5px;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  color: #222;
  display: none;
}
.userDropDown:hover .userDisplayDD {
  display: block;
}
.azureImage {
  text-align: right;
  padding-right: 2rem;
}
.azureImage img {
  margin: 1.5rem 1rem 0;
  width: 65px;
}
.landingTopMenu {
  display: flex;
  justify-content: space-between;
  max-width: 360px;
}
/* .userLogin{} */
.loginMenu .topMenuDropDown {
  right: auto !important;
  left: 0;
}

/* Modal listing css */
.modal-list {
  display: block;
  position: relative;
  border-bottom: 1px solid #ddd;
  font-size: 1.2rem;
  max-height: 100px;
  overflow: auto;
}

/* Start of css for arrow in phone number field */
.MuiPhoneNumber-flagButton .MuiButton-label {
  width: 50% !important;
}
.MuiPhoneNumber-flagButton .MuiTouchRipple-root {
  position: relative !important;
  top: 5% !important;
  margin-top: -2px;
  left: 5px !important;
  border-left: 3px solid transparent;
  border-right: 3px solid transparent;
  border-top: 4px solid #555;
}
/* End of css for arrow in phone number field */

.pKeyPointsCont {
  display: flex;
  justify-content: space-between;
  padding: 50px 20px;
}
.pKeyPointsCont .pKeyPoints {
  border: 1px solid #1583dc;
  max-width: 350px;
  width: 32%;
  border-radius: 5px;
  padding: 20px;
  text-align: center;
}
.pKeyPointsCont .pKeyPoints .kpIcon {
  margin: 10px 0 0;
}
.pKeyPointsCont .pKeyPoints .kpTitle {
  margin: 20px 0;
}

.aboutBenefitsCont {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 20px 20px 50px 20px;
}
.aboutBenefitsCont .abPoints {
  border: 1px solid #1583dc;
  max-width: 420px;
  width: calc(50% - 30px);
  border-radius: 5px;
  padding: 20px;
  text-align: center;
}
.aboutBenefitsCont .abPoints .abIcon {
  margin: 10px 0 0;
}
.aboutBenefitsCont .abPoints .abTitle {
  margin: 20px 0;
}
.aboutBenefitsCont .abMCPoints {
  max-width: 300px;
  width: 32%;
}

.sectionTitle {
  padding: 10px;
  text-align: center;
}
.sectionTitle span {
  display: inline-block;
  padding: 15px 10%;
  border-bottom: 1px solid #aaa;
  position: relative;
}
.sectionTitle span::before {
  content: '';
  display: block;
  width: 30%;
  height: 2px;
  border-bottom: 4px solid #1583dc;
  position: absolute;
  top: calc(100% - 4px);
  left: 0;
  right: 0;
  margin: auto;
}

.statusIcon {
  position: relative;
  top: 5px;
}

/* Custom Tooltip */
.custom-tooltip {
  background: #fff;
  border: 1px solid #b5cce8;
  border-radius: 5px;
  box-shadow: none;
  overflow: hidden;
  /* position: absolute; */
  z-index: 9999999999;
}
.custom-tooltip .ttTitle {
  display: flex;
  font-size: 12px;
  background: #ebf4ff;
  padding: 7px;
  font-weight: 500;
}
.custom-tooltip .ttLabel {
  display: flex;
  color: #666;
}
.custom-tooltip .ttKey {
  font-weight: 400;
  font-size: 12px;
  width: 80px;
  display: block;
  padding: 8px;
  text-transform: capitalize;
}
.custom-tooltip .ttValue {
  font-weight: 700;
  font-size: 11px;
  width: 75px;
  display: block;
  padding: 8px;
}
.addAccountBtns {
  display: flex;
  width: 45%;
}

.recharts-legend-wrapper {
  max-height: 190px !important;
  width: 300px !important;
  overflow: auto !important;
  overflow-x: hidden;
  padding: 0rem 0 !important;
  margin: 0.7rem 0;
}
.recharts-legend-wrapper::-webkit-scrollbar {
  width: 1rem;
}
.recharts-legend-wrapper::-webkit-scrollbar-track {
  background: #f1f1f1;
}
.recharts-legend-wrapper::-webkit-scrollbar-thumb {
  background: #888;
}
.recharts-legend-wrapper::-webkit-scrollbar-thumb:hover {
  background: #555;
}
.recharts-legend-item-text {
  width: 260px;
  display: inline-flex;
  justify-content: space-between;
}
.defaultPieData .recharts-default-legend .recharts-legend-item-text {
  width: auto !important;
}
.defaultPieData .recharts-legend-item {
  display: flex !important;
  justify-content: center;
}

.monthlyBillingContainer .recharts-legend-wrapper {
  position: relative !important;
  margin: 0 auto;
  bottom: 20px !important;
}
.monthlyBillingContainer .recharts-legend-item-text {
  display: inline;
}

.sContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.sContainerRight {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.sContainerRight .setlimit {
  margin-right: 10px;
}

.innerContainer ::-webkit-scrollbar {
  width: 0.6rem;
  height: 0.8rem;
}
.innerContainer ::-webkit-scrollbar-track {
  background: #f1f1f1;
}
.innerContainer ::-webkit-scrollbar-thumb {
  background: #666;
}
.innerContainer ::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/* notification */
.notification {
  position: relative;
}
.noNotificationFound {
  padding: 10px;
  background: #fff;
  color: #333;
  width: 300px;
}
.notification > span {
  cursor: pointer;
  padding: 1.7rem;
  display: flex;
  align-items: center;
}
.notificationCount {
  width: 18px;
  height: 18px;
  background: #ffc967;
  color: brown;
  border-radius: 50%;
  position: absolute;
  top: 5px;
  right: 5px;
  text-align: center;
  font-size: 10px;
  font-weight: 600;
  padding: 2px 1px;
}
.notification > span:hover,
.nOpen {
  background: #058a87;
  transition: 0.2s ease-in-out;
}
.notification .notificationBubble {
  position: absolute;
  right: -2px;
  top: 52px;
}
.notification .notificationBubble .notificationList {
  border: 1px solid #b0d2ff;
  border-radius: 3px;
}
.notification
  .notificationBubble
  .notificationList
  .MuiAccordion-root.Mui-expanded {
  margin: 0;
}
.notification .notificationBubble .notificationList .MuiAccordion-root:before {
  display: none;
}
.notification .notificationBubble .notificationList .notificationHead {
  display: flex;
  justify-content: space-between;
  background: #e6f4ff;
}
.notification .notificationBubble .notificationList .notificationHead h6 {
  font-weight: 400;
  color: #0058a0;
  padding: 10px;
}
.notification .notificationBubble .notificationList .notificationHead .nClose {
  font-weight: 400;
  color: #0058a0;
  padding: 8px 6px 4px;
  cursor: pointer;
}
.notification .notificationBubble .notificationList .notificationBody {
  max-height: 300px;
  overflow: auto;
  background-color: #fff;
}
.notification
  .notificationBubble
  .notificationList
  .notificationBody::-webkit-scrollbar {
  width: 0.6rem;
}
.notification
  .notificationBubble
  .notificationList
  .notificationBody::-webkit-scrollbar-track {
  background: #f1f1f1;
}
.notification
  .notificationBubble
  .notificationList
  .notificationBody::-webkit-scrollbar-thumb {
  background: #666;
}
.notification
  .notificationBubble
  .notificationList
  .notificationBody::-webkit-scrollbar-thumb:hover {
  background: #555;
}
.notification .notificationBubble ul li .updatedBy_nTime {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row-reverse;
  margin-top: 5px;
}
/* .updatedBy_nTime{ display: flex; justify-content: space-between; flex-direction: row-reverse; margin-top: 5px;} */
.notification .notificationBubble .notificationList .notificationBody .nTime {
  color: #666;
  text-align: right;
  font-style: italic;
  font-size: 10px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.notification
  .notificationBubble
  .notificationList
  .notificationBody
  .nUpdatedBy {
  color: #333;
  font-style: italic;
  font-size: 10px;
  display: flex;
  align-items: center;
  max-width: 125px;
}
.notification
  .notificationBubble
  .notificationList
  .notificationBody
  .nUpdatedBy
  i {
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 100%;
  overflow: hidden;
  text-align: left;
}
.notification .notificationBubble .notificationList .MuiAccordionSummary-root {
  min-height: auto;
}
.notification
  .notificationBubble
  .notificationList
  .MuiAccordionSummary-content {
  margin: 5px 0;
}
.notification
  .notificationBubble
  .notificationList
  .MuiAccordionSummary-content
  + div {
  padding: 5px 0;
}
.notification .notificationBubble .notificationList .MuiAccordionDetails-root {
  padding: 0px;
}
.notification .notificationBubble ul {
  list-style: none;
  width: 100%;
}
.notification .notificationBubble ul li {
  list-style: none;
  display: flex;
  align-items: flex-start;
  padding: 5px;
  border-top: 1px solid #eee;
}
.notification .notificationBubble ul li > span {
  list-style: none;
  padding: 2px 5px;
  display: flex;
  margin-top: 2px;
}
.notification .notificationBubble ul li > div {
  width: calc(100% - 30px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}
.notification .notificationBubble ul li > div span {
  display: block;
  padding: 2px 5px;
  font-size: 1.2rem;
  word-break: break-all;
}
.notification .notificationBubble ul li.Failed {
  color: #a30000;
  background: #fff7f7;
}
.notification .notificationBubble ul li.Success {
  color: #006954;
  background: #f0fffb;
}
.notification .notificationBubble ul li.InProgress {
  color: #b87100;
  background: #fffcf6;
}
.notification .notificationBubble ul li.Info {
  color: #0c5460;
  background: #ebfcff;
}
.notificationAlert {
  width: 30%;
  position: absolute;
  z-index: 10;
  right: 0;
  top: 10;
  font-size: 13px;
}

.createAccountContainer {
  max-width: 1000px;
  margin: 0 auto;
}
/* configureAccountForm */
.configureAccountForm {
  padding: 10px;
}
.configureAccountForm li,
.configureAccountForm p {
  font-size: 15px;
  font-weight: 300;
  line-height: 28px;
}
.configureAccountForm input {
  font-size: 15px;
  font-weight: 300;
  line-height: initial;
}
.configureAccountForm .configureFormElement {
  max-width: 470px;
  min-width: 300px;
  width: 100%;
}
.configureAccountForm .configureFormElement > div {
  line-height: 15px !important;
}
.configureAccountForm .configureFormElement .MuiOutlinedInput-input {
  padding: 12px 25px 12px 15px;
  font-size: 12px;
}
.configureAccountForm .MuiInputLabel-formControl {
  top: -9px;
  background: #fff;
  font-size: 14px;
  padding: 2px 0;
}
.configureAccountBtns {
  display: flex;
  justify-content: space-evenly;
  max-width: 350px;
  min-width: 300px;
  margin: 0 auto;
}
.configureAccountBtns .btn {
  padding: 10px 50px;
}
.accountsMsg {
  display: flex;
  justify-content: center;
  max-width: 350px;
  min-width: 300px;
}
.successMessage {
  font-size: 12px;
  background: none;
  color: inherit;
  text-align: center;
  max-width: 450px;
}

div[role='presentation'] {
  z-index: 9999 !important;
}
.billingType {
  display: flex;
  flex-direction: row;
}
.billingType label {
  display: inline-flex;
}
.billingType label span {
  font-size: 13px;
}

.MuiTab-textColorPrimary.Mui-selected {
  font-weight: 500 !important;
}
.mUserName {
  display: none !important;
}

@media (max-width: 1024px) {
  .sContainerRight .setlimit {
    margin-right: 100px;
    transition: 1s;
    margin-bottom: 3px;
  }
  .subscriptionDD {
    position: absolute;
    right: -170px;
    top: 71px;
    min-width: auto;
    width: 320px;
    transition: 1s;
  }
  .subscriptionDD:hover,
  .subscriptionDD:active,
  .subscriptionDD:focus {
    position: fixed;
    right: 20px;
    transition: 1s;
  }
  .inventoryFlexContent,
  .invoiceBtn,
  .CustomBtn {
    padding-right: 90px;
  }
}

@media (max-width: 960px) {
  .landingTopMenu {
    margin: 0 auto;
  }
  .rightImage {
    padding: 5%;
  }
  .rightbody {
    margin: 0 auto;
  }
  .homeImage,
  .registrationImage {
    display: none;
  }
  .cf-Logo {
    margin: 10px auto;
  }
  .cf-Logo span.cloud,
  .cf-Logo span.forest {
    font-weight: 300 !important;
  }
  .cf-Logo.blue span.cloud,
  .cf-Logo.blue span.forest {
    font-weight: 400 !important;
  }
  .cf-Logo.blue {
    display: flex;
    margin-bottom: 30px;
  }
  .cardSummary > div .catImage {
    width: 80px;
  }
  .cardSummary .details {
    width: calc(100% - 80px);
  }
}

@media (max-device-width: 767px) {
  .topSearchRefBar {
    flex-wrap: wrap;
  }
  .searchBar {
    width: 100%;
    margin-top: 10px;
    margin-left: 0 !important;
  }
  .addAccountBtns {
    width: 100%;
    justify-content: space-between;
  }
  .appBar .toolBar .planPeriod,
  .appBar .toolBar .accountDisplayName {
    display: none;
  }
  .appBar .toolBar .userDropDown .userDisplayDD .planPeriod {
    display: flex;
  }
  .appBar .toolBar .userDropDown .userDisplayDD .accountDisplayName {
    display: flex;
  }
  .accSearch {
    width: 100% !important;
  }
  /* .inventoryHeader, .sContainer{ margin-top: 50px;} */
  .inventoryFlexContent,
  .invoiceBtn,
  .CustomBtn {
    padding-right: 0;
  }
  .inventoryFlexContent button:last-child,
  .invoiceBtn button:last-child,
  .CustomBtn button:last-child,
  inventoryFlexContent {
    margin-right: 0 !important;
  }
  .subscriptionDD {
    right: 20px;
  }
  .tabRightsizingHeading button span,
  .tabResourceHeading button span,
  .inventoryHeading,
  .tabHeading button span,
  .tabInvoiceHeading button span {
    font-size: 20px !important;
  }
  .splitLayout .leftNav {
    position: fixed;
    bottom: 0;
    width: 100%;
    min-height: 48px;
    max-height: 60px;
  }
  .splitLayout .leftNav ul {
    display: flex;
    max-width: 100%;
    justify-content: center;
  }
  .splitLayout .leftNav ul li {
    display: inline-flex;
    max-width: 100%;
    min-width: 60px;
    justify-content: center;
  }
  .rightContent {
    width: 100%;
    padding-bottom: 80px;
  }
  .inventoryIcon .cube1 {
    top: 16px;
  }
  .inventoryIcon .cube2,
  .inventoryIcon .cube3 {
    top: 26px;
  }
  .sContainerRight .setlimit {
    margin-right: 0;
  }
  .tabRightsizingHeading .MuiTabs-flexContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  .rightContent .tabRightsizingHeading button {
    padding: 5px 35px !important;
    border: 1px solid #ccc;
    border-radius: 3px;
    margin: 5px;
    font-size: 18px;
  }
  /* .tabRightsizingHeading .notificationIcon{ margin-left: -30px; margin-top: 18px; position: relative;} */
  .tabRightsizingHeading button.MuiTab-textColorPrimary.Mui-selected {
    color: #fff;
    background-color: #0062cc;
    border-color: #005cbf;
    font-weight: 300 !important;
  }
  /* .leftNav ul li .iconLabel{display: none;} */
}

@media (min-device-width: 768px) and (max-device-width: 1024px) {
  .tabRightsizingHeading button span,
  .tabResourceHeading button span,
  .inventoryHeading,
  .tabHeading button span,
  .tabInvoiceHeading button span {
    font-size: 20px !important;
  }
}

@media (max-device-width: 600px) {
  .supportHeader > div {
    display: block;
  }
  .supportHeader .cf-Logo.blue {
    width: 100%;
    justify-content: center;
    margin: 20px 0;
  }
  .supportHeader .topMenu {
    width: 100%;
    text-align: center;
  }
  .footer .fAddress > div {
    margin-top: 25px;
  }
}

@media (max-device-width: 480px) {
  .outerContainer .rightImage {
    padding: 20px;
  }
  .supportHeader .topMenu li {
    padding: 20px 13px;
  }
  .dUserName {
    display: none !important;
  }
  .mUserName {
    display: flex !important;
  }
  .splitLayout .leftNav ul {
    display: flex;
    max-width: 100%;
    justify-content: flex-start;
    overflow: auto;
  }
}

@media (max-device-width: 360px) {
  .supportHeader .topMenu li {
    padding: 20px 8px;
  }
  .rightContent {
    padding: 10px 10px 80px;
  }
  .rightContent .subscriptionDD {
    width: calc(100% - 20px);
    max-width: none;
    right: 10px;
  }
  .sContainer {
    flex-wrap: wrap;
    flex-direction: row-reverse;
  }
  .mtdSummary {
    margin-bottom: 10px;
    width: 100%;
  }
  .rightContent .chartsContainer {
    margin-top: 1rem !important;
  }
}
.neutralColor {
  color: #aaaaaa;
}
.successColor {
  color: #00b300;
}
.errorColor {
  color: #ff0000;
}

.changeWhite:active {
  color: white;
}

.inventoryReportsHeading {
  color: #0058a0;
  font-weight: 300;
  font-size: 2.6rem;
  line-height: 50px;
}

.align-center {
  align-items: center !important;
}

.centerItem {
  display: flex; /* or display: inline-flex; */
  align-items: center !important;
  justify-content: center !important;
}

.fixModal {
  z-index: 10000;
}

.justify-Center {
  margin-top: -50px;
  display: flex;
  justify-content: center;
  padding: 0px;
}

.confirmText {
  font-size: 20px;
  font-weight: 300;
  line-height: 27px;
  letter-spacing: 0em;
  text-align: left;
}

.confirmBtnWelcome {
  width: 128px;
  height: 39px;
  border-radius: 5px;
  gap: 10px;
  background: #0078db;
}

.flex-end {
  display: flex;
  justify-content: flex-end;
}

.flex-justifySpaceBetween {
  display: flex;
  justify-content: space-between;
}

.customizePagination {
  width: 100%;
  display: flex;
  justify-content: center;
}

.customizePagination .MuiPagination-ul li button {
  border: none;
}
.customizePagination .MuiPagination-ul li button:hover {
  background-color: transparent !important;
  border: 1px solid rgb(66, 132, 245);
}

.customizePagination .MuiPagination-ul li button:active {
  background-color: transparent !important;
}

.customizePagination .MuiPagination-ul .Mui-selected {
  background-color: transparent !important;
  border: 1px solid rgb(66, 132, 245);
}

.customizePagination .MuiPagination-ul > li {
  font-size: 20px !important;
}

.MuiTouchRipple-root .css-8je8zh-MuiTouchRipple-root {
  border: none !important;
}

.customizePagination .MuiPagination-ul li:nth-last-child(1) button {
  background: rgb(66, 132, 245) !important;
  border: 1px solid rgb(66, 132, 245);
  border-radius: 4px;
  box-shadow: 1px 1px 2px 1px rgba(0, 0, 0, 0.2);
}

.customizePagination .MuiPagination-ul li:nth-child(1) button {
  background-color: rgb(66, 132, 245) !important;
  border: 1px solid rgb(66, 132, 245);
  border-radius: 4px;
  box-shadow: 1px 1px 2px 1px rgba(0, 0, 0, 0.2);
}

.customizePagination .MuiPagination-ul li button {
  width: 45px !important;
  height: 45px !important;
  font-size: 15px !important;
}

.customizePagination .MuiPagination-ul li button svg {
  width: 25px !important;
  height: 25px !important;
  color: white !important;
}

.SingleDatePicker_picker {
  z-index: 1000 !important;
}

.text-overflow-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
