.multipleBox {
  justify-content: center;
}

.multipleBox > div:nth-child(1) {
  margin-left: 0px;
}

.multipleBox > div {
  /* min-width: 19%; */
  height: fit-content;
  margin-left: 15px;
}

.multipleBox .MuiFormControl-marginNormal {
  margin-top: 0;
  margin-bottom: 0;
}

.multipleBox .MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root'] {
  padding: 0px 9px;
}

.multipleBox
  .MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root']
  .MuiAutocomplete-input {
  padding: 8.5px 4px;
}

.multipleBox .MuiAutocomplete-endAdornment {
  top: auto;
}

.table-container {
  width: 100%;
  max-height: 68vh;
  overflow: auto;
}

.sticky-cell {
  position: sticky;
  left: 0;
  background-color: #f2f2f2;
  z-index: 1;
  max-width: fit-content;
}

.sticky-cell:nth-child(2) {
  left: 170px;
}

/* DatePicker css */
.DateRangePicker_picker {
  z-index: 700000;
  top: 42px !important;
  left: 0px !important;
}

.CalendarDay__selected_span {
  background: #ebf4ff;
  border: 1px solid #0078db;
  color: black;
}

.CalendarDay__selected {
  background: #0078db;
  border: 1px solid #0d66af;
  color: #fff;
}

.CalendarDay__selected:hover {
  background-color: #03589f;
}

.CalendarDay__selected_span:hover {
  background: #68b3f0;
  border: 1px solid #034f8e;
  color: #fff;
}

.CalendarDay__hovered_span,
.CalendarDay__hovered_span:hover {
  background: #ebf4ff;
  border: 1px solid #41a3f2;
  color: #03589f;
}

.DateRangePickerInput__withBorder {
  border-radius: 4px;
  border: 1px solid #dbdbdb;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.DateInput {
  width: 98px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px;
}

.DateRangePickerInput_arrow_svg {
  width: 19px;
  vertical-align: baseline;
}

.SingleDatePickerInput__withBorder {
  border: none;
}

.DateInput_input {
  font-size: 14px;
  line-height: 15px;
  color: #333;
  background-color: #fff;
  width: 100%;
  padding: 0px;
  border: 0;
  border-top: 0;
  border-right: 0;
  border-bottom: 2px solid transparent;
  border-left: 0;
  border-radius: 0;
  /* display: flex; */
  text-align: center;
}

.SingleDatePicker {
  border: 1px solid rgb(207, 229, 255);
  border-radius: 1.5px;
  font-weight: 300;
  width: 150px;
  text-align: center;
}

.DayPickerKeyboardShortcuts_show__bottomRight {
  visibility: hidden;
}

.echarts-tooltip {
  padding: 8px;
  /* background-color: rgba(50, 50, 50, 0.7); */
  border-radius: 4px;
  /* color: #fff; */
  max-height: 350px;
  width: max-content;
  overflow-y: auto;
  font-size: 14px;
  line-height: 1.5;
}

.echarts-tooltip-title {
  font-weight: bold;
  margin-bottom: 4px;
  border-bottom: 4px double #ccc;
}

.echarts-tooltip-color {
  display: inline-block;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  margin-right: 6px;
}

.echarts-tooltip-name {
  margin-right: 25px;
  font-size: 14px;
  letter-spacing: 0.5px;
}

.echarts-tooltip-value {
  float: right;
  font-size: 14px;
  font-weight: 700;
}

.btn-with-icon {
  display: inline-flex;
  align-items: center;
}

.leftIconButton,
.rightIconButton {
  display: inline-flex;
  align-items: center;
}

.leftIconButton {
  padding-right: 10px;
}

.rightIconButton {
  padding-left: 10px;
}

.serviceReportTable .MuiSvgIcon-root {
  font-size: large !important;
}

/* Status section */

.statusLegend {
  display: flex;
  justify-content: flex-end;
  grid-gap: 8px;
  gap: 15px;
  padding: 12px 0 12px 12px;
  /* background-color: white; */
  /* box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1); */
  border-radius: 4px;
  font-size: 12px;
}

.statusItem {
  display: flex;
  align-items: center;
}

.statusDot {
  display: inline-block;
  width: 12px;
  height: 12px;
  border-radius: 2px;
  margin-right: 8px;
}

.decreasedDot {
  background-color: rgba(92, 184, 92, 1);
}

.noChangeDot {
  /* background-color: gray; */
  border: 1px solid gray;
}

.increasedLowDot {
  background-color: rgba(250, 144, 0, 1);
}

.increasedHighDot {
  background-color: rgba(217, 83, 79, 1);
}

.statusLabel {
  line-height: 1;
}
